import React, { Component, FunctionComponent, ReactNode } from "react";

type IconColor = "red" | "blue" | "green" | "yellow";

interface ModalContentProps {
  heading: ReactNode;
  content: ReactNode;
  buttons: ReactNode;
  Icon: typeof Component | FunctionComponent;
  iconColor: IconColor;
}

export const ModalContent = ({
  heading,
  content,
  buttons,
  Icon,
  iconColor = "blue",
}: ModalContentProps) => {
  const buttonsContainer = `flex flex-col space-y-4 md:space-y-0 md:space-x-2 md:flex-row mt-6 
  items-center justify-end -mb-8 -mx-8 px-8 py-4 pb-8 md:py-4 bg-gray-50 border-t border-gray-200`;

  const iconsBg = getIconBgColor(iconColor);
  const iconsColor = getIconColor(iconColor);

  return (
    <div>
      {/* CONTENT */}
      <div className="flex text-gray-800">
        <div>
          <div className={`p-3  mr-4 rounded-full ${iconsBg}`}>
            <Icon className={`w-6 ${iconsColor}`} />
          </div>
        </div>
        <div>
          <h2 className="text-xl text-gray-700 font-semibold">{heading}</h2>
          <div className="mt-2 text-sm leading-7 text-gray-600 space-y-2">
            {content}
          </div>
        </div>
      </div>

      {/* BUTTONS */}
      <div className={buttonsContainer}>{buttons}</div>
    </div>
  );
};

function getIconBgColor(ic: IconColor): string {
  switch (ic) {
    case "blue":
      return "bg-blue-50";
    case "green":
      return "bg-green-50";
    case "red":
      return "bg-red-50";
    case "yellow":
      return "bg-yellow-50";
  }
}

function getIconColor(ic: IconColor): string {
  switch (ic) {
    case "blue":
      return "text-blue-500";
    case "green":
      return "text-green-500";
    case "red":
      return "text-red-500";
    case "yellow":
      return "text-yellow-500";
  }
}
