import { useEffect, useState } from "react";
import useSWR from "swr";
import { get } from "../components/requests/requests";
import { useParams } from "react-router-dom";

const KEY = "historic_completions";

const fetcher = async (_: string, companyId: string, year?: number) =>
  year ? get(KEY + `/${year}`, { companyId }) : get(KEY, { companyId });

export const useHistoricCompletions = (year?: number) => {
  const { companyId } = useParams();
  const [lastUpdated, setLastUpdated] = useState<Date | null>(null);
  const { data, error } = useSWR(["api/" + KEY, companyId, year], fetcher);

  useEffect(() => {
    if (data !== undefined) {
      setLastUpdated(new Date());
    }
  }, [data]);

  const loading = !data && !error;
  const err = !data && error;

  return {
    loading,
    lastUpdated,
    historicCompletions: data as HistoricCompletion[],
    error: err && "Kunne ikke hente opgaveudførsler.",
  };
};

export interface HistoricCompletion {
  id: string;
  deadline: Date;
  finishedOn: Date;
  wasOverdue: boolean;
  responsible: { name?: string; id?: string };
  task: { title: string; id: string; type: "AD_HOC" | "CYCLICAL" };
  equipment: { name: string; equipmentId: string; id: string };
}
