import {
  Button,
  CheckCircleIcon,
  DashboardPages,
  EquipmentIcon,
  Form,
  FormHeading,
  FormSection,
  TextFieldset,
  TextOrSelectFieldset,
} from "@onlion/components";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FormActionBar } from "../../../../components/formActionBar";
import useEquipment, { Equipment } from "../../../../data/useEquipment";
import { useBreadcrumbs } from "../../../../state/useBreadcrumbs";

export const EquipmentUpdatePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [model, setModel] = useState<string>("");
  const [make, setMake] = useState<string>("");
  const [equipmentId, setEquipmentId] = useState<string>("");
  const [category, setCategory] = useState("");
  const [location, setLocation] = useState("");
  const { replaceBreadcrumbs } = useBreadcrumbs();
  const { id, companyId } = useParams();
  const [toEdit, setToEdit] = useState<Equipment | null>(null);

  const { updateEquipment, equipment } = useEquipment();

  // Set initial values
  useEffect(() => {
    if (equipment) {
      const focused = equipment.find((e) => e.id === id);

      if (focused) {
        setModel(focused.model);
        setMake(focused.make);
        setEquipmentId(focused.equipmentId);
        setCategory(focused.category);
        setLocation(focused.location);

        setToEdit(focused);
      }
    }
  }, [equipment, id]);

  useEffect(() => {
    const focused = equipment.find((e) => e.id === id);
    if (focused) {
      replaceBreadcrumbs([
        {
          text: "Udstyr",
          link: `/dashboard/${companyId}/equipment`,
        },
        {
          text: `${focused.name} ${focused.equipmentId}`,
          link: `/dashboard/${companyId}/equipment/${id}`,
        },
        {
          text: "Ret",
        },
      ]);
    } else {
      replaceBreadcrumbs([
        {
          text: "Udstyr",
          link: `/dashboard/${companyId}/equipment`,
        },
        {
          text: "Ret",
        },
      ]);
    }
  }, [replaceBreadcrumbs, id, companyId]);

  if (!id || !toEdit) return null;

  // Submit
  const handleSubmit = async () => {
    setLoading(true);
    const success = await updateEquipment({
      make,
      model,
      equipmentId,
      category,
      location,
      id: id,
    });
    setLoading(false);
    if (success) navigate(`/dashboard/${companyId}/equipment/${id}`);
  };

  const categories = [
    ...equipment.map((e) => e.category),
    "Elektronik",
    "Håndværktøj",
    "Maskine",
    "Strukturel",
  ].filter((e, index, self) => self.indexOf(e) === index);

  const locations = equipment
    .map((e) => e.location)
    .filter((e, index, self) => self.indexOf(e) === index);

  const makes = equipment
    .map((e) => e.make)
    .filter((e, index, self) => self.indexOf(e) === index);

  const models = equipment
    .map((e) => e.model)
    .filter((e, index, self) => self.indexOf(e) === index);

  return (
    <div className="p-2 sm:p-8 bg-gray-50 min-h-screen">
      <DashboardPages.Form
        title={`Ret ${toEdit?.name}`}
        className="mb-20 md:mb-40"
      >
        <Form onSubmit={() => {}}>
          <FormHeading>
            <EquipmentIcon className="w-8 h-8 mr-4" /> Ret {toEdit?.name}
          </FormHeading>

          <FormSection
            heading="Information"
            info={
              <>
                <p className="mb-2">Basal information om udstyret.</p>
                <p>
                  Hvis udstyret ikke allerede har et unikt id-nummer fra
                  producenten, anbefaler vi at du opretter et, og sørger for at
                  det fremgår tydeligt på udstyret.
                </p>
              </>
            }
          >
            <TextOrSelectFieldset
              id={"make"}
              label="Fabrikat"
              placeholder="Fabrikat"
              required
              valid={make.length > 0}
              help="Eksempel: 'Makita' eller 'Mercedes-Benz'"
              value={make}
              initialValue={{ value: make, label: make }}
              options={makes.map((m) => ({ value: m, label: m }))}
              onChange={setMake}
              entity="fabrikat"
              preferSelect
              autoFocus
            />
            <TextOrSelectFieldset
              id={"model"}
              label="Model"
              required
              valid={model.length > 0}
              options={models.map((m) => ({ value: m, label: m }))}
              placeholder="Model"
              help="Eksempel: Slibemaskine, eller 'Vito'"
              value={model}
              initialValue={{ value: model, label: model }}
              onChange={setModel}
              entity="model"
            />
            <TextFieldset
              id={"equipmentId"}
              label="Identifikationsnummer"
              placeholder="Identifikationsnummer"
              help="Eksempel: '01' eller 'A1'"
              value={equipmentId}
              onChange={setEquipmentId}
            />
          </FormSection>

          <FormSection
            heading="Kategori"
            info="Kategorien gør det nemmere at finde udstyr du leder efter."
          >
            <TextOrSelectFieldset
              id="category"
              options={categories.map((c) => ({ value: c, label: c }))}
              label="Kategori"
              onChange={setCategory}
              placeholder="Kategori"
              value={category}
              preferSelect
              initialValue={{
                label: category,
                value: category,
              }}
            />
          </FormSection>

          <FormSection
            heading="Lokation"
            info="Hvor kan en ansvarlig finde udstyret når en opgave skal udføres?"
          >
            <TextOrSelectFieldset
              id="location"
              options={locations.map((c) => ({ value: c, label: c }))}
              label="Lokation"
              onChange={setLocation}
              placeholder="Lokation"
              value={location}
              preferSelect
              initialValue={{
                label: location,
                value: location,
              }}
            />
          </FormSection>

          <FormActionBar>
            <Button
              className="mr-4"
              onClick={() =>
                navigate(`/dashboard/${companyId}/equipment/${id}`)
              }
            >
              Fortryd
            </Button>

            <Button
              disabled={
                model.length === 0 ||
                make.length === 0 ||
                equipmentId.length === 0 ||
                category.length === 0 ||
                location.length === 0
              }
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={handleSubmit}
            >
              <CheckCircleIcon className="w-4 h-4 mr-2" /> Gem rettelser
            </Button>
          </FormActionBar>
        </Form>
      </DashboardPages.Form>
    </div>
  );
};
