import { ReactNode } from "react";
import cl from "classnames";

export type BadgeProps = {
  children: ReactNode;
  className?: string;
  type?: "green" | "blue" | "red" | "yellow" | "gray";
  inverse?: boolean;
};

export const Badge = ({
  children,
  className = "",
  type = "blue",
  inverse = false,
}: BadgeProps) => {
  const badgeStyles = cl([
    `py-1 px-2 text-white text-center w-max tracking-widest leading-none
    font-bold uppercase text-xs rounded-sm`,
    {
      [`bg-blue-500`]: type === "blue" && !inverse,
      [`text-blue-500`]: type === "blue" && inverse,
      [`bg-green-500`]: type === "green" && !inverse,
      [`text-green-500`]: type === "green" && inverse,
      [`bg-red-500`]: type === "red" && !inverse,
      [`text-red-500`]: type === "red" && inverse,
      [`bg-yellow-500`]: type === "yellow" && !inverse,
      [`text-yellow-500`]: type === "yellow" && inverse,
      [`bg-gray-400`]: type === "gray" && !inverse,
      [`text-gray-400`]: type === "gray" && inverse,
      [`bg-white`]: inverse,
    },
    className,
  ]);
  return <div className={badgeStyles}>{children}</div>;
};

export default Badge;
