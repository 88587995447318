import { v4 as uuid } from "uuid";
import { Notification } from "@onlion/components";
import { insert } from "../../../utils/transient";

export const newNotification = (key: string) => (n: Notification) => {
  insert<Notification>(
    {
      ...n,
      id: uuid(),
    },
    n.duration ? n.duration + 500 : 7500,
    key
  );
};

export const notify = newNotification("dashboard/notifications");
export const notifyAuth = newNotification("auth/notifications");
