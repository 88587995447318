import { UserType } from "./users/types";
import { get } from "../components/requests/requests";
import useSWR from "swr";
import { Mutator } from "swr/dist/types";

export type Invitation = {
  token: string;
  date: Date;
  companyId: string;
  companyName: string;
  type: UserType;
  name: string;
  emailAddress: string;
  userId?: string;
};

export const invitationsKey = "api/invitations";

export const useInvitations = ({
  companyId,
}: {
  companyId?: string;
}): {
  loading: boolean;
  invitations: Invitation[];
  mutate: Mutator;
} => {
  const { data, error, mutate } = useSWR(invitationsKey, () =>
    get("invitation", { companyId })
  );

  const loading = !data && !error;

  return {
    loading,
    invitations: data ?? [],
    mutate,
  };
};
