import React from "react";
import { Button } from "@onlion/components";
import { useNavigate, useParams } from "react-router-dom";

interface EmptyProps {
  title: string;
  description: string;
  action?: { text: string; link: string };
  icon: (className: string) => any;
}

export const Empty = ({ title, description, action, icon }: EmptyProps) => {
  const { companyId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="w-full h-full p-4 md:p-40">
      <h2 className="text-4xl md:text-5xl text-gray-700 font-extrabold flex flex-row items-center mb-6">
        {title}
      </h2>
      <p className="mb-6 md:w-8/12 text-lg font-semibold text-gray-600">
        {description}
      </p>
      {action && (
        <div className="w-60">
          <Button
            type="primary"
            onClick={() => navigate(`/dashboard/${companyId}/${action.link}`)}
          >
            {icon("w-4 h-4 mr-2")} {action.text}
          </Button>
        </div>
      )}
      {icon(
        "w-full md:w-6/12 md:h-6/12 fixed md:-right-40 md:-bottom-20 text-blue-50"
      )}
    </div>
  );
};
