import { User } from "../../../../data/users/types";

export const getUserMeta = (
  u: User,
  companyId: string
): { color: "blue" | "gray" | "green"; role: string } => {
  const roleType = u.roles?.[companyId]?.type;
  const isSuperAdmin = ["SUPER_ADMIN"].includes(roleType);

  const status = isSuperAdmin
    ? "Superadministrator"
    : roleType === "USER"
    ? "Bruger"
    : "Administrator";

  const color = isSuperAdmin ? "blue" : roleType === "USER" ? "gray" : "green";
  return { role: status, color };
};
