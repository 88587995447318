import useSWR from "swr";
import { get } from "../components/requests/requests";
import { useParams } from "react-router-dom";

const KEY = "events";

const fetcher = async ({
  year,
  companyId,
}: {
  year?: number;
  companyId: string;
}) => get(KEY, { year, companyId });

export default function useEvents(year?: number) {
  const { companyId } = useParams();

  if (!companyId) throw new Error("Company id not defined");

  const { data, error } = useSWR([`api/${KEY}`, year, companyId], () =>
    fetcher({ year, companyId })
  );

  const loading = !data && !error;
  const err = !data && error;

  return {
    loading,
    events: data as Event[],
    error: err && "Kunne ikke hente aktivitet.",
  };
}

export interface Event {
  datetime: Date;
  action: EventType;
  person: { name: string; id: string } | null;
  subjectId: string;
  companyId: string;
  subjectType: SubjectType;
  subject: string;
  id: string;
}

export type EventType =
  | "CREATED"
  | "ARCHIVED"
  | "UNARCHIVED"
  | "EDITED"
  | "UPDATED"
  | "DELETED"
  | "INVITED"
  | "JOINED"
  | "COMPLETED"
  | "COMPLETED_LATE";

export type SubjectType =
  | "TASK"
  | "NOTE"
  | "EQUIPMENT"
  | "RESPONSIBLE"
  | "QUALIFICATION"
  | "COMPANY"
  | "USER";
