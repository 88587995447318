import useSWR from "swr";
import {
  deleteRequest,
  get,
  patch,
  post,
} from "../components/requests/requests";
import { notify } from "../dashboard/components/notifications/newNotification";
import { useCompanyIdParam } from "../components/hooks";

const tasksFetcher = async (companyId: string) => get("task", { companyId });

export default function useTasks() {
  const companyId = useCompanyIdParam();

  const { data, mutate, error } = useSWR<Task[]>("api/tasks", () =>
    tasksFetcher(companyId as string)
  ) as any;

  const loading = !data && !error;

  const createTask = async (task: TaskCreateSet): Promise<boolean> => {
    const response = await post("task", task, { companyId });
    if (response) {
      mutate("api/equipment");
      notify({
        heading: "Opgaven blev tilføjet",
        text: "Du ser nu den nye opgave.",
      });

      return response.taskId;
    } else {
      notify({
        heading: "Opgaven blev ikke tilføjet",
        text: "Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  const updateTask = async (task: TaskChangeSet): Promise<boolean> => {
    const response = await patch("task", task, { companyId });
    if (response) {
      mutate("api/equipment");
      notify({
        heading: "Ændringerne blev gemt",
        text: "Opgaven blev rettet.",
      });

      return true;
    } else {
      notify({
        heading: "Ændringerne blev ikke gemt",
        text: "Opgaven kunne ikke rettets.",
        type: "alert",
      });
      return false;
    }
  };

  const deleteTask = async (id: TaskId): Promise<boolean> => {
    const success = await deleteRequest("task", { id }, { companyId });
    if (success) {
      mutate("api/task");
      mutate("api/equipment");
      mutate("api/responsibles");
      notify({
        heading: "Opgaven blev slettet",
        text: "Opgaven er nu slettet permanent.",
      });

      return true;
    } else {
      notify({
        heading: "Opgaven kunne ikke slettes",
        text: "Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  return {
    loading,
    tasks: data ? (data.tasks as Task[]) : [],
    createTask,
    updateTask,
    deleteTask,
    mutate,
  };
}

export interface Task {
  title: string;
  description: string;
  type: TaskType;
  files?: string[];

  deadline: Date;
  interval?: TaskInterval;
  notificationPeriod: number;
  startDate?: Date;
  latestMailSent?: Date;

  standby: boolean;
  status: TaskStatus;

  id: TaskId;

  lastCompleted: Date | null;
  // timesCompleted: number;
  // percentCompletedOnTime: number;

  equipment: TaskEquipment;
  responsible: TaskResponsible;
  token: string;

  companyId: string;
}

export interface TaskResponsible {
  id: string;
  name: string;
}

export interface TaskEquipment {
  id: string;
  name: string;
  equipmentId: string;
}

export interface TaskCreateSet {
  title: string;
  description: string;
  type: TaskType;
  files?: string[];

  deadline: Date;
  interval?: TaskInterval;
  notificationPeriod: number;

  equipmentId: string;
  responsibleId: string;
}

export interface TaskChangeSet {
  title?: string;
  description?: string;
  type?: TaskType;
  files?: string[];

  deadline?: Date;
  interval?: TaskInterval;
  responsibleId?: string;
  equipmentId?: string;
  notificationPeriod?: number;
  standby?: boolean;

  id: TaskId;
}

export type TaskId = string;

export type TaskType = "CYCLICAL" | "AD_HOC";

export type TaskStatus =
  | "ARCHIVED"
  | "STANDBY"
  | "ACTIVE"
  | "CURRENT"
  | "OVERDUE";

export type TaskInterval =
  | "DAILY"
  | "DAILY_WEEKDAYS"
  | "WEEKLY"
  | "BI_WEEKLY"
  | "MONTHLY"
  | "BI_MONTHLY"
  | "QUARTERLY"
  | "TRIANNUAL"
  | "HALF_YEARLY"
  | "YEARLY"
  | "SEMI_YEARLY"
  | "EVERY_3_YEARS"
  | "EVERY_4_YEARS"
  | "EVERY_5_YEARS"
  | "EVERY_8_YEARS";

export interface Completion {
  responsible: { name: string; id?: string };
  datetime: Date;
  wasOverdue: boolean;
}
