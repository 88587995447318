import { useState } from "react";
import { is_email } from "@onlion/utils";
import { useNavigate } from "react-router-dom";
import {
  TextFieldset,
  Button,
  DashboardPages,
  Form,
  FormHeading,
  FormSection,
  ChevronUpIcon,
  ChevronRightIcon,
  UnlockIcon,
  MailIcon,
} from "@onlion/components";

import requestPasswordResetEmail from "./newPasswordRequest.actions";
import { Link } from "react-router-dom";
import { FormActionBar } from "../../../components/formActionBar";
import { notifyAuth } from "../../../dashboard/components/notifications/newNotification";

const NewPasswordRequestPage = () => {
  const navigate = useNavigate();

  const [email, set_email] = useState("");
  const [loading, set_loading] = useState(false);

  const handleForgot = async () => {
    set_loading(true);
    const success = await requestPasswordResetEmail(email);
    set_loading(false);

    if (success) {
      navigate("/auth/new-password-request-receipt");
    } else {
      notifyAuth({
        heading: "Nulstillings email kunne ikke sendes",
        text: "Noget gik galt. Prøv igen, eller kontakt os.",
        type: "alert",
        duration: 10000,
      });
    }
  };

  return (
    <DashboardPages.Form title="Vælg et nyt password">
      <Form onSubmit={() => {}}>
        <FormHeading>
          <UnlockIcon className="w-8 h-8 mr-2" /> Vælg et nyt password
        </FormHeading>

        <p className="my-4 text-sm">
          Vi sender dig en email med instruktioner til at vælge et nyt password.
        </p>

        <FormSection
          heading="Emailadresse"
          info={
            <>
              <p>
                Skriv den emailaddresse som du har modtaget emails fra Tela
                Vedligehold på.
              </p>
              <details className="mt-3">
                <summary className="flex text-sm items-center w-max text-blue-900">
                  Problemer med at logge ind?{" "}
                  <ChevronUpIcon className="w-3 h-3 ml-2 summary-icon" />
                </summary>
                <Link
                  className="mt-3 flex items-center text-blue-900 font-semibold"
                  to="/auth"
                >
                  Log ind <ChevronRightIcon className="w-4 h-4 ml-1" />
                </Link>
              </details>
            </>
          }
        >
          <TextFieldset
            id="email"
            placeholder="Email"
            label="Din emailaddresse"
            autoFocus
            autoComplete="email"
            Icon={MailIcon}
            value={email}
            onChange={(v) => set_email(v.toLowerCase())}
          />
        </FormSection>

        <FormActionBar>
          <Button onClick={() => navigate("/auth")} className="mr-4">
            Fortryd
          </Button>
          <Button
            onClick={handleForgot}
            disabled={!is_email(email)}
            loading={loading}
            htmlType="submit"
            type="primary"
          >
            Send email
          </Button>
        </FormActionBar>
      </Form>
    </DashboardPages.Form>
  );
};

export default NewPasswordRequestPage;
