import {
  BuildingIcon,
  Checkbox,
  Container,
  EquipmentIcon,
  InfoIcon,
  MessageCircleIcon,
  Page,
  UserIcon,
  UsersIcon,
  VerifiedIcon,
  ZapIcon,
} from "@onlion/components";
import { format_date } from "@onlion/utils";
import getYear from "date-fns/getYear";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { formatName } from "../../../components/formatName";
import useEvents, {
  Event,
  EventType,
  SubjectType,
} from "../../../data/useEvents";
import { useBreadcrumbs } from "../../../state/useBreadcrumbs";
import SelectFieldset from "../../../components/form/select";
import { SelectOption } from "../../../components/form/select/input";

const currentYear = getYear(new Date());

interface TimelineEvent {
  id: string;
  content: ReactNode;
  date: Date;
  icon: any;
  iconBackground: string;
}

export const ActivityPage = () => {
  const { companyId } = useParams();
  const [showCompletionsOnly, setShowCompletionsOnly] = useState(false);

  const [year, setYear] = useState<SelectOption>({
    label: currentYear,
    value: currentYear,
  });

  const { loading, events } = useEvents(
    year ? Number(year.value) : currentYear
  );

  const { replaceBreadcrumbs } = useBreadcrumbs();
  useEffect(() => replaceBreadcrumbs([{ text: "Aktivitet" }]), []);

  if (!companyId) throw new Error("Company id not set in URL");

  const timelineEvents: TimelineEvent[] = useMemo(
    () =>
      !events
        ? []
        : events
            .sort((a, b) =>
              new Date(a.datetime) > new Date(b.datetime) ? -1 : 1
            )
            .filter((e) => {
              const isCorrectTimePeriod =
                getYear(new Date(e.datetime)) >= getYear(new Date()) - 1;
              if (!isCorrectTimePeriod) return false; // remove if not from this or last year

              if (showCompletionsOnly === true) {
                return ["COMPLETED", "COMPLETED_LATE"].includes(e.action);
              }

              return true;
            })
            .map((e) => ({
              id: e.id,
              content: formatContent(e, companyId),
              date: e.datetime,
              icon: getIcon(e),
              iconBackground: getColor(e.action),
            })),
    [events, showCompletionsOnly]
  );

  return (
    <Page
      className="bg-white"
      headingProps={{
        title: "Aktivitet",
        renderActions: (
          <div className="flex flex-col space-y-4 items-end">
            <SelectFieldset
              id="year"
              label="Vælg år"
              onChange={(v) => setYear(v)}
              options={getYearOptions(2018, currentYear)}
              selected={year}
            />
            <Checkbox
              label="Vis kun opgaveudførsler"
              value={showCompletionsOnly}
              onChange={setShowCompletionsOnly}
            />
          </div>
        ),
      }}
      loading={{
        title: "Henter aktivitet",
        text: "Dette bør kun tage få sekunder",
        isLoading: loading,
      }}
    >
      <Container className="">
        <div className="flow-root w-full">
          <ul className="-mb-8">
            {timelineEvents.map((event) => (
              <li key={event.id}>
                <div className="relative pb-8">
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-100"
                    aria-hidden="true"
                  />
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          event.iconBackground,
                          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                        )}
                      >
                        <event.icon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">{event.content}</p>
                      </div>
                      <div className="text-right text-sm whitespace-nowrap text-gray-500">
                        <time dateTime={event.date.toString()}>
                          {format_date(event.date)}
                        </time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </Page>
  );
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

function getColor(type: EventType): string {
  switch (type) {
    case "COMPLETED":
      return "bg-green-400";
    case "COMPLETED_LATE":
      return "bg-green-600";
  }

  return "bg-blue-500";
}

function getIcon({ subjectType }: Event): any {
  switch (subjectType) {
    case "COMPANY":
      return BuildingIcon;
    case "EQUIPMENT":
      return EquipmentIcon;
    case "TASK":
      return ZapIcon;
    case "NOTE":
      return MessageCircleIcon;
    case "QUALIFICATION":
      return VerifiedIcon;
    case "RESPONSIBLE":
      return UsersIcon;
    case "USER":
      return UserIcon;
    default:
      return InfoIcon;
  }
}

function formatContent(event: Event, companyId: string) {
  const { subjectType, action, person } = event;
  // subjectType - {subject} - action (af {person})
  return (
    <span>
      {getReadableSubjectType(subjectType)} {getSubjectLink(event, companyId)}{" "}
      {getAction(action)} (af{" "}
      {person && person.name !== "DELETED"
        ? formatName(person.name, { lastName: true })
        : "(nu slettet)"}
      )
    </span>
  );
}

function getReadableSubjectType(subjectType: SubjectType) {
  switch (subjectType) {
    case "COMPANY":
      return "Virksomheden";
    case "EQUIPMENT":
      return "Udstyret";
    case "RESPONSIBLE":
      return "Den ansvarlige";
    case "NOTE":
      return "En note på udstyret";
    case "QUALIFICATION":
      return "Kvalifikationen";
    case "USER":
      return "Brugeren";
    case "TASK":
      return "Opgaven";
  }
}

function getSubjectLink(event: Event, companyId: string) {
  const { subjectType, subjectId, action } = event;
  const readableSubject = getReadableSubject(event);
  const excluded = ["DELETED"];
  if (!excluded.includes(action)) {
    const urlPart = getURLPart(subjectType);
    if (!urlPart) return readableSubject;
    return (
      <Link
        className="font-semibold underline"
        to={`/dashboard/${companyId}/${urlPart}/${subjectId}`}
      >
        {readableSubject}
      </Link>
    );
  } else {
    return readableSubject;
  }
}

function getReadableSubject({ subject, subjectType }: Event) {
  if (!subject || subject === "DELETED") {
    return "(nu slettet)";
  } else if (["RESPONSIBLE", "EMPLOYEE", "USER"].includes(subjectType)) {
    return formatName(subject, { lastName: true });
  } else {
    return subject;
  }
}

function getAction(type: EventType): string {
  switch (type) {
    case "COMPLETED":
      return "blev udført";
    case "COMPLETED_LATE":
      return "blev udført med forsinkelse";
    case "CREATED":
      return "blev tilføjet";
    case "EDITED":
      return "blev rettet";
    case "DELETED":
      return "blev slettet";
    case "JOINED":
      return "takkede ja til at blive administrator";
    case "ARCHIVED":
      return "blev arkiveret";
    case "UNARCHIVED":
      return "blev gendannet";
    case "UPDATED":
      return "blev gendannet";
  }

  return "";
}

function getURLPart(type: SubjectType): string | null {
  switch (type) {
    case "EQUIPMENT":
      return "equipment";
    case "TASK":
      return "tasks";
  }

  return null;
}

const getYearOptions = (start: number, stop: number): SelectOption[] => {
  const numberOfOptions = stop - start;
  const options = [];
  for (let i = 0; i < numberOfOptions + 1; i++) {
    const year = start + i;
    options.push({ label: year, value: year });
  }

  return options.reverse();
};
