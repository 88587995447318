import { mutate } from "swr";
import { post } from "../../components/requests/requests";
import { notify } from "../../dashboard/components/notifications/newNotification";
import { useState } from "react";
import { uploadFiles } from "../../utils/uploadFiles";
import { NoteCreateSet } from "../useEquipment";
import { useParams } from "react-router-dom";

export const useCreateNote = () => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);

  const createNote = async (note: {
    content: string;
    files: File[];
    recipientIds: string[];
    equipmentId: string;
    core: boolean;
  }): Promise<boolean> => {
    const { content, files, recipientIds, equipmentId, core } = note;
    setLoading(true);

    const uploadedFiles = await uploadFiles(files);

    const noteCreateSet: NoteCreateSet = {
      equipmentId,
      content,
      files: uploadedFiles,
      recipientIds,
      core: core ? true : false,
    };

    const success = await post(`equipment/note`, noteCreateSet, { companyId });

    setLoading(false);

    if (success) {
      mutate("api/equipment");
      notify({
        heading: "Noten blev tilføjet",
        text: "Du kan se den nye note under 'noter'",
      });

      return true;
    } else {
      notify({
        heading: "Noten kunne ikke tilføjes",
        text: "Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  return { createNote, loading };
};
