import React from "react";
import { NotificationCard } from "@onlion/components";
import { Notification } from "@onlion/components";
import useTransient from "../../../utils/transient";

const Notifications = React.memo(
  () => {
    const notifications = useTransient<Notification>("auth/notifications");

    return (
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        {notifications.map((n) => (
          <NotificationCard
            key={n.id}
            heading={n.heading}
            duration={n.duration}
            text={n.text}
            type={n.type}
          />
        ))}
      </div>
    );
  },
  () => {
    return true;
  }
);

export default Notifications;
