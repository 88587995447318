import { useEffect, useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { User, UserType } from "../data/users/types";

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}

export const useCompanyIdParam = (): string => {
  const { companyId } = useParams();
  if (!companyId) throw new Error("CompanyId not said in parameters");
  return companyId;
};

export const useUserRoleType = (
  user: User
): { type: UserType; isAdmin: boolean; isSuperAdmin: boolean } => {
  const companyId = useCompanyIdParam();
  const type = user.roles[companyId].type;
  return {
    type,
    isAdmin: ["ADMIN", "SUPER_ADMIN"].includes(type),
    isSuperAdmin: ["SUPER_ADMIN"].includes(type),
  };
};
