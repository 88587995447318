import { Page } from "@onlion/components";
import { useParams } from "react-router-dom";
import Link from "../../../components/Link/Link";

export const MFAStepOnePage = () => {
  const { emailAddress } = useParams();

  return (
    <Page
      headingProps={{
        title: "Tjek din email-indbakke",
      }}
      className="m-auto max-w-xl shadow-md rounded-sm"
    >
      <div className="px-4 pb-4 md:px-8 md:pb-8 max-w-md">
        <p className="border-l-4 pl-4 border-blue-500">
          <strong>Vi har sendt en email til {emailAddress}</strong>
        </p>
        <p className="mt-6">
          Bemærk at der kan gå op til 2 minutter før du modtager emailen.
        </p>
        <p className="mt-6">
          Du kan lukke denne fane, da linket i emailen vil åbne en ny.
        </p>

        <h2 className="mt-6 font-bold text-lg">
          Har du problemer med at logge ind?
        </h2>
        <ul className="list-disc">
          <li className="mt-2">
            Har du ikke modtaget en email? <br /> Tjek din spam-mappe, eller
            <br />
            <a
              className="text-blue-500 hover:text-blue-700 hover:underline"
              href="mailto:kontakt@tela-vedligehold.dk"
            >
              kontakt os på email kontakt@tela-vedligehold.dk
            </a>
          </li>
          <li className="mt-2">
            Har du indtastet en forkert emailadresse? <br />{" "}
            <Link to="/auth">Gå tilbage til Log ind-siden</Link>
          </li>
        </ul>
      </div>
    </Page>
  );
};
