import { InfoIcon, Page } from "@onlion/components";
import { DownloadBackup } from "./sections/downloadBackup";
import { DownloadHistoricCompletions } from "./sections/downloadHistoricCompletions";

export const DataDownloadPage = () => {
  return (
    <Page
      headingProps={{
        title: "Download data",
        subtitle:
          "Download en kopi af din data, til udprint eller manuel back-up",
      }}
    >
      <div className="px-8 max-w-md">
        <section className="flex items-center">
          <InfoIcon className="flex-shrink-0 w-6 h-6 text-gray-400" />
          <p className="ml-3 text-gray-600 text-sm space-y-1">
            Tela Vedligehold laver automatisk en backup af din data dagligt,
            helt gratis. Hvis du også vil have en kopi af din data selv, kan du
            også downloade den nedenfor.
          </p>
        </section>
        <section className="flex items-center mt-4">
          <InfoIcon className="flex-shrink-0 w-6 h-6 text-gray-400" />
          <p className="ml-3 text-gray-600 text-sm space-y-1">
            De filer du downloader er regneark (.csv), som kan åbnes i
            programmer såsom Microsoft Excel.
          </p>
        </section>
        <hr className="my-8 border-blue-900 border-opacity-10" />
        <DownloadHistoricCompletions />
        <hr className="my-8 border-blue-900 border-opacity-10" />
        <DownloadBackup />
      </div>
    </Page>
  );
};
