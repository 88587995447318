import { NavLink, useParams } from "react-router-dom";
import { OriginalNote } from "../../../data/notes/types";
import { AdHocTaskIcon } from "../../icons/adHocTaskIcon";
import { CyclicalTaskIcon } from "../../icons/cyclicalTaskIcon";

export const TaskLink = ({ task }: { task: OriginalNote["task"] }) => {
  const { companyId } = useParams();

  if (!task || !task.title) return null;

  return (
    <p className="text-sm text-gray-500 flex items-center">
      {task.title ? (
        <>
          Skrevet i forbindelse med opgaven{" "}
          <NavLink
            className={
              "font-medium text-blue-800 inline-flex items-center hover:underline ml-1"
            }
            to={`/dashboard/${companyId}/tasks/${task.id}`}
          >
            {task.type === "CYCLICAL" ? (
              <CyclicalTaskIcon className="w-3 h-3 mr-1" />
            ) : (
              <AdHocTaskIcon className="w-3 h-3 mr-1" />
            )}{" "}
            {task.title}
          </NavLink>
        </>
      ) : (
        "Skrevet i forbindelse med en nu slettet opgave"
      )}
    </p>
  );
};
