import { useState } from "react";
import { mutate } from "swr";
import { deleteRequest } from "../../components/requests/requests";
import { notify } from "../../dashboard/components/notifications/newNotification";
import { key } from "./key";
import { UserId } from "./types";
import { useParams } from "react-router-dom";

export const useDeleteUser = () => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);
  const deleteUser = async (id: UserId): Promise<boolean> => {
    setLoading(true);
    const success = await deleteRequest("user", { id }, { companyId });
    setLoading(false);
    if (success) {
      mutate(key);
      notify({
        heading: "Success",
        text: "Brugeren er nu slettet permanent.",
      });
      return true;
    } else {
      notify({
        heading: "Fejl",
        text: "Brugeren kunne ikke slettes. Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  return {
    deleteUser,
    loading,
  };
};
