import { format, subMonths } from "date-fns";
import da from "date-fns/locale/da";
import { Stats } from "../../../../../data/useCompany";
import {
  AlertCircleIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircleIcon,
  MultiEventTimeline,
  Page,
  ZapIcon,
} from "@onlion/components";
import { NoData } from "../../../../components/noData";
import { Box, BoxHeader } from "./components/box";
import { splitTasksPerType } from "./splitTasksPerType";
import { useTaskQuery } from "../../../../../data/useTaskQuery";
import { useParams } from "react-router-dom";

export const OverviewSection = ({ stats }: { stats: Stats }) => {
  const { companyId } = useParams();
  const { tasks } = useTaskQuery({});

  if (!stats || !tasks || !companyId) return null;

  const month = stats.month;

  const split = splitTasksPerType(tasks, companyId);
  const overdueCount = split.overdue.reduce(
    (sum, d) => sum + d.events.length,
    0
  );
  const dueCount = split.due.reduce((sum, d) => sum + d.events.length, 0);

  return (
    <Page
      className="bg-gray-100 h-screen"
      headingProps={{
        title: `Oversigt for ${format(new Date(), "MMMM, yyyy", {
          locale: da,
        })}`,
      }}
    >
      <div className="grid gap-6 md:gap-4 md:grid-cols-2 p-4 pt-0">
        <div className="flex flex-col space-y-6">
          {/* STATS */}
          <Box className="flex items-center justify-between">
            <div>
              <h3 className="text-xl flex justify-center items-center text-gray-600">
                <span className="font-medium text-gray-800">
                  {month.tasksCompleted}
                </span>
                <span className="text-xs mx-1">/</span>
                {month.tasksDue}
              </h3>
              <p className="text-xs md:text-xs text-gray-600">Opgaver udført</p>
            </div>
            <div className="h-12 bg-gray-100 w-0.5"></div>
            <div>
              <h3 className="text-xl flex justify-center items-baseline font-medium text-gray-800">
                {(month.percentCompletedInTime * 100).toFixed(0)}
                <span className="text-xs ml-1 text-gray-600">%</span>
              </h3>
              <p className="text-xs md:text-xs text-gray-600">
                Udført uden forsinkelse
              </p>
            </div>

            {/* NOT ON MOBILE */}
            <div className="h-12 bg-gray-100 w-0.5 hidden md:block"></div>
            <div className="hidden md:block">
              <h3 className="text-xl flex justify-center items-baseline font-medium text-gray-800">
                {stats.month.changeOnMonth < 0 && (
                  <ArrowDownIcon className="w-4 text-red-500" />
                )}
                {stats.month.changeOnMonth > 0 && (
                  <ArrowUpIcon className="w-4 text-green-500" />
                )}
                {(stats.month.changeOnMonth * 100).toFixed(0)}
                <span className="text-xs ml-1 text-gray-600">%</span>
              </h3>
              <p className="text-xs md:text-xs text-gray-600">
                i forhold til{" "}
                {format(subMonths(new Date(), 1), "MMMM", { locale: da })}
              </p>
            </div>
          </Box>

          {/* OVERDUE */}
          <Box>
            <BoxHeader
              heading={`${
                overdueCount === 0 ? "Ingen forsinkede" : "Forsinkede"
              } opgaver`}
              count={overdueCount}
              text="Opgaver som skulle være udført, men som endnu ikke er kvitteret for."
              Icon={overdueCount > 0 ? AlertCircleIcon : CheckCircleIcon}
              iconColor={overdueCount > 0 ? "yellow" : "green"}
            />

            <hr className="mt-4" />

            {overdueCount > 0 && (
              <MultiEventTimeline
                className="pt-6 pb-12 px-1.5"
                distanceToNow
                days={split.overdue}
              />
            )}

            <NoData color="green" className="mt-3" show={overdueCount === 0}>
              <CheckCircleIcon className="w-6 mr-4" /> Alle opgaver er udført
            </NoData>
          </Box>
        </div>
        <div className="flex flex-col space-y-6">
          <Box>
            <BoxHeader
              heading="Planlagte opgaver"
              count={dueCount}
              text={`Opgaver som udføres eller afventer udførsel i denne måned.`}
              Icon={ZapIcon}
              iconColor={dueCount > 0 ? "blue" : "gray"}
            />

            <hr className="mt-6" />

            {dueCount > 0 && (
              <MultiEventTimeline
                className="pt-6 pb-12 px-1.5"
                distanceToNow
                days={split.due}
              />
            )}

            <NoData className="mt-3" show={dueCount === 0}>
              <CheckCircleIcon className="w-6 mr-4" /> Ingen{" "}
              {overdueCount > 0 ? "(rettidige)" : ""} udestående opgaver tilbage
              i {format(new Date(), "MMMM", { locale: da })}
            </NoData>
          </Box>
        </div>
      </div>
    </Page>
  );
};
