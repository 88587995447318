import React, { useEffect, useState } from "react";
import { isAfter, formatDistanceToNow } from "date-fns";
import da from "date-fns/locale/da";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextFieldset,
  Button,
  AlertCircleIcon,
  HistoryIcon,
  DashboardPages,
  Form,
  FormHeading,
  UnlockIcon,
  FormSection,
  ChevronUpIcon,
  ChevronRightIcon,
  LockIcon,
} from "@onlion/components";

import setNewPassword from "./newPassword.actions";
import Link from "../../../components/Link/Link";
import { useInterval } from "../../../components/hooks";
import { FormActionBar } from "../../../components/formActionBar";
import { notifyAuth } from "../../../dashboard/components/notifications/newNotification";

const NewPasswordPage = () => {
  const navigate = useNavigate();
  const { token, expiry } = useParams<{ token?: string; expiry?: string }>();

  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [expiryString, setExpiryString] = useState("");

  const [password, setPassword] = useState("");
  const [loading, set_loading] = useState(false);

  // Initial checks
  useEffect(() => {
    if (!token || token.length < 36) {
      setIsInvalid(true);
    }

    if (expiry !== undefined && isAfter(new Date(), new Date(Number(expiry)))) {
      setIsExpired(true);
    }
  }, []);

  // Expiry
  useInterval(
    () => {
      if (expiry) {
        const newExpiryString = formatDistanceToNow(new Date(Number(expiry)), {
          locale: da,
        });
        setExpiryString(newExpiryString);
      }

      if (
        expiry !== undefined &&
        isAfter(new Date(), new Date(Number(expiry)))
      ) {
        setIsExpired(true);
      }
    },
    isExpired ? null : 300
  );

  const handleSetPassword = async () => {
    set_loading(true);
    const success = await setNewPassword(token as string, password);
    set_loading(false);

    if (success) {
      navigate("/auth/new-password-receipt");
    } else {
      notifyAuth({
        heading: "Nyt password kunne ikke gemmes",
        text: "Noget gik galt. Prøv igen, eller kontakt os.",
        type: "alert",
        duration: 10000,
      });
    }
  };

  return (
    <DashboardPages.Form
      title="Vælg et nyt password"
      className="w-full md:w-1/2 md:min-w-max"
    >
      <Form onSubmit={() => {}}>
        <FormHeading>
          <UnlockIcon className="w-8 h-8 mr-2" /> Vælg et nyt password
        </FormHeading>

        {isExpired && (
          <>
            <div
              className={`w-full md:w-96 my-8 bg-yellow-50 rounded-lg px-6 py-4 text-yellow-700 
              border border-yellow-200 font-semibold text-sm flex flex-row items-center`}
            >
              <AlertCircleIcon className="w-6 h-6 mr-4 inline-block" />
              <span>Dit link er udløbet</span>
            </div>

            <p>
              <Link
                to="/auth/new-password-request"
                className="text-blue-900 flex items-center no-underline font-semibold"
              >
                Anmod om at vælge en nyt password igen{" "}
                <ChevronRightIcon className="w-4 h-4 ml-2" />
              </Link>{" "}
            </p>
          </>
        )}

        {!isExpired && !isInvalid && expiry && (
          <p className="my-4 text-sm flex items-center">
            <HistoryIcon className="w-4 h-4 mr-2 text-gray-600" /> Vælg et nyt
            password inden {expiryString}
          </p>
        )}

        {!isExpired && !isInvalid && (
          <>
            <FormSection
              heading="Password"
              info={
                <>
                  <p>
                    Vælg et godt password, som er mindst 10 tegn langt, og svært
                    at gætte.
                  </p>
                  <details className="mt-3">
                    <summary className="flex text-sm items-center w-max text-blue-900">
                      Problemer med at logge ind?{" "}
                      <ChevronUpIcon className="w-3 h-3 ml-2 summary-icon" />
                    </summary>
                    <Link
                      className="mt-3 flex items-center text-blue-900 font-semibold no-underline"
                      to="/auth"
                    >
                      Log ind
                      <ChevronRightIcon className="w-4 h-4 ml-1" />
                    </Link>
                  </details>
                </>
              }
            >
              <TextFieldset
                id="password"
                placeholder="Password"
                type="password"
                label="Password"
                autoFocus
                autoComplete="new-password"
                Icon={LockIcon}
                value={password}
                help="Bemærk at der skelnes mellem store og små bogstaver."
                onChange={setPassword}
              />
            </FormSection>

            <FormActionBar>
              <Button onClick={() => navigate("/auth")} className="mr-4">
                Fortryd
              </Button>
              <Button
                onClick={handleSetPassword}
                disabled={password.length < 10}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Gem nyt password
              </Button>
            </FormActionBar>
          </>
        )}
      </Form>
    </DashboardPages.Form>
  );
};

export default NewPasswordPage;
