import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import login from "./login.action";
import {
  Button,
  CheckCircleIcon,
  DashboardPages,
  Form,
  FormHeading,
  FormSection,
  LockIcon,
  TextFieldset,
} from "@onlion/components";

import { is_email } from "@onlion/utils";
import { Link } from "react-router-dom";
import { FormActionBar } from "../../../components/formActionBar";

interface LoginPageProps {}

const LoginPage: FunctionComponent<LoginPageProps> = () => {
  const navigate = useNavigate();
  const [emailAddress, setEmailAddress] = useState(""); // company code
  const [password, setPassword] = useState(""); // personal code
  const [loading, set_loading] = useState(false);

  const [hasError, setHasError] = useState(false);

  const handleLogin = async () => {
    set_loading(true);
    const { success, mfa } = await login(emailAddress, password);
    set_loading(false);

    if (mfa) navigate(`/auth/mfa/step_one/${emailAddress}`);

    if (!success) {
      setHasError(true);
    } else {
      setHasError(false);
    }
  };

  return (
    <DashboardPages.Form title="Log ind">
      <Form onSubmit={() => {}}>
        <FormHeading>
          <LockIcon className="w-8 h-8 mr-2" /> Log ind
        </FormHeading>

        {hasError && (
          <div className="border-l-4 border-red-500 px-4 py-2">
            <h2 className="text-red-500 text-lg font-bold">
              Forkert email eller password
            </h2>
            <p className="text-red-500">Prøv igen</p>
          </div>
        )}

        <FormSection
          heading="Log ind"
          info={
            <>
              <p>Indtast din emailadresse og dit password.</p>
              <hr />
              <div className="text-xs">
                Har du ikke en konto?
                <br />
                <Link
                  className="inline text-blue-700 underline"
                  to="/auth/sign-up"
                >
                  Opret en ny gratis konto
                </Link>
              </div>
              <hr />
              <div className="text-xs">
                Glemt dit password?
                <br />
                <Link
                  className="inline text-blue-700 underline"
                  to="/auth/new-password-request"
                >
                  Vælg nyt password
                </Link>
              </div>
            </>
          }
        >
          <TextFieldset
            id="email"
            placeholder="Emailadresse"
            autoComplete="email"
            autoFocus
            label="Emailadresse"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.toLowerCase())}
            valid={is_email(emailAddress)}
            required
          />
          <TextFieldset
            id="password"
            autoComplete="current-password"
            placeholder="Password"
            label="Password"
            value={password}
            type="password"
            onChange={setPassword}
            valid={password.length > 0}
            help="Bemærk at der skelnes mellem store og små bogstaver."
            required
          />
        </FormSection>

        <FormActionBar>
          <Button
            htmlType="submit"
            type="primary"
            onClick={handleLogin}
            loading={loading}
          >
            <CheckCircleIcon className="w-4 h-4 mr-2" /> Log ind
          </Button>
        </FormActionBar>
      </Form>
    </DashboardPages.Form>
  );
};

export default LoginPage;
