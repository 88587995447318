import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  RefreshcwIcon,
  ZapIcon,
  EquipmentIcon,
  Input,
  SearchIcon,
  Popover,
  FilterIcon,
  ZapOffIcon,
  Page,
} from "@onlion/components";
import { useBreadcrumbs } from "../../../../../state/useBreadcrumbs";
import useTasks, { TaskType } from "../../../../../data/useTasks";
import { intervalToString } from "../../../../../components/intervalToString";
import { Empty } from "../../../../../components/empty/empty";
import useEquipment from "../../../../../data/useEquipment";
import { format_date } from "@onlion/utils";
import { formatDistanceToNow, endOfDay } from "date-fns";
import da from "date-fns/locale/da";
import { mapStatus } from "../../../../../components/mapStatus";
import { useTable } from "../../../../../components/useTable";
import { highlightMatched } from "../../../../../components/highlightMatched";
import { Filters } from "./components/filters";
import { NoData } from "../../../../components/noData";
import { Table } from "../../../../../components/table";
import Badge from "../../../../../components/badge";
import { useTaskQuery } from "../../../../../data/useTaskQuery";

const TasksTablePage = () => {
  const { replaceBreadcrumbs } = useBreadcrumbs();
  useEffect(
    () => replaceBreadcrumbs([{ text: "Opgaver" }]),
    [replaceBreadcrumbs]
  );
  const navigate = useNavigate();
  const { tasks } = useTaskQuery({});
  const { equipment } = useEquipment();

  const {
    data,
    query,
    closestQueryMatch,
    setQuery,
    sorting,
    sort,
    getFilterValue,
    setFilterValue,
    activeFilterCount,
    clearFilters,
  } = useTable(tasks, "tasks", {
    searchKeys: "title",
    filters: {
      status: { func: (task, v) => task.status === v || v === null },
      responsible: {
        func: (task, v) => task.responsible.id === v || v === null,
      },
      equipment: {
        func: (task, v) => task.equipment.id === v || v === null,
      },
    },
  });

  if (!data || !equipment || !tasks) return null;

  if (equipment.length === 0) {
    return (
      <Empty
        title="Tilføj et udstyr"
        description="Du kan ikke tilføje en opgave før du har tilføjet et udstyr"
        action={{ text: "Tilføj et udstyr", link: "equipment/create" }}
        icon={(className: string) => <EquipmentIcon className={className} />}
      />
    );
  } else if (tasks.length === 0) {
    return (
      <Empty
        title="Tilføj en opgave"
        description="Kom i gang med automatiseringen af dine vedligehold"
        action={{ text: "Tilføj en opgave", link: "tasks/create" }}
        icon={(className: string) => <ZapIcon className={className} />}
      />
    );
  }

  return (
    <Page
      headingProps={{
        title: "Opgaver",
        renderActions: (
          <div className="flex mt-4 md:mt-0 items-center space-x-2 md:space-x-4">
            <Popover
              buttonProps={{
                count: activeFilterCount,
              }}
              panelZIndex={10}
              offsetY={6}
              className="space-y-4"
              panelChildren={
                <Filters
                  tasks={tasks}
                  get={getFilterValue}
                  set={setFilterValue}
                  clear={clearFilters}
                />
              }
            >
              <FilterIcon className="w-4 h-4 mr-2" /> Filtre
            </Popover>
            <Input
              value={query !== "undefined" ? query : ""}
              autoFocus={true}
              onChange={setQuery}
              id="tasks/search"
              placeholder="Søg i opgaver"
              short
              Icon={SearchIcon}
              autoComplete="off"
              clearable
            />
          </div>
        ),
      }}
      loading={{
        isLoading: false,
        title: "Henter opgaver",
        text: "Dette bør kun tage få sekunder",
      }}
    >
      <Table
        className="mx-4"
        sorting={sorting ?? { key: "", ascending: false }}
        head={[
          { content: "Opgave", key: "title", onClick: () => sort("title") },
          { content: "Udføres på" },
          { content: "Udføres af" },
          { content: "Type" },
          {
            content: "Deadline",
            key: "deadline",
            onClick: () => sort("deadline"),
          },
          {
            content: "Status",
            hideMobile: false,
            right: true,
          },
        ]}
        row={{
          onClick: ({ id }) => navigate(id),
          fields: [
            {
              renderData: ({ title }) => {
                return (
                  <span className="w-48 block overflow-ellipsis overflow-hidden">
                    {highlightMatched({
                      str: title,
                      toMatch: query,
                    })}
                  </span>
                );
              },
            },
            {
              renderData: ({ equipment }) => (
                <>
                  {equipment.name}{" "}
                  <p className="text-xs">{equipment.equipmentId}</p>
                </>
              ),
            },
            {
              renderData: ({ responsible }) => responsible.name,
            },
            {
              renderData: ({ type, interval }) => (
                <>
                  {getType(type)}
                  {interval && (
                    <p className="text-xs">
                      Udføres {intervalToString(interval)}
                    </p>
                  )}
                </>
              ),
            },
            {
              renderData: ({ deadline }) => (
                <>
                  {format_date(deadline)}
                  <p className="text-xs">
                    {formatDistanceToNow(endOfDay(new Date(deadline)), {
                      addSuffix: true,
                      locale: da,
                    })}
                  </p>
                </>
              ),
            },
            {
              renderData: ({ status }) => (
                <Badge className="ml-auto" type={mapStatus(status).color}>
                  {mapStatus(status).label}
                </Badge>
              ),
              hideMobile: false,
              right: true,
            },
          ],
        }}
        data={data}
      />
      <NoData show={data.length === 0} className="text-lg">
        <ZapOffIcon className="w-4 h-4 mr-2" /> Vi kunne desværre ikke finde
        nogen opgaver som matcher{" "}
        {activeFilterCount && activeFilterCount > 0 && "dine filtre"}{" "}
        {query.length > 0 && activeFilterCount && "eller"}{" "}
        {query.length > 0 && (
          <>
            {" "}
            '{query}'.{" "}
            {closestQueryMatch ? (
              <p className="ml-2">
                Mente du{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => setQuery(closestQueryMatch)}
                >
                  '{closestQueryMatch}'
                </span>
                ?
              </p>
            ) : null}
          </>
        )}
      </NoData>
    </Page>
  );
};

function getType(type: TaskType) {
  switch (type) {
    case "CYCLICAL":
      return (
        <p className="flex items-center">
          <RefreshcwIcon className="w-3 h-3 mr-2" /> Rutine
        </p>
      );
    case "AD_HOC":
      return (
        <p className="flex items-center">
          <ZapIcon className="w-3 h-3 mr-2" /> Enkelt
        </p>
      );
  }
}

export default TasksTablePage;
