import {
  Button,
  CheckCircleIcon,
  DashboardPages,
  EquipmentIcon,
  Form,
  FormHeading,
  FormSection,
  TextFieldset,
  TextOrSelectFieldset,
} from "@onlion/components";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormActionBar } from "../../../../components/formActionBar";
import useEquipment from "../../../../data/useEquipment";

export const EquipmentCreatePage = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [model, setModel] = useState<string>("");
  const [make, setMake] = useState<string>("");
  const [equipmentId, setEquipmentId] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [location, setLocation] = useState<string>("");

  const { createEquipment, equipment } = useEquipment();

  const handleSubmit = async () => {
    setLoading(true);

    const id = await createEquipment({
      make,
      model,
      equipmentId,
      category,
      location,
    });
    setLoading(false);
    if (id) navigate(`/dashboard/${companyId}/equipment/${id}`);
  };

  if (!equipment) return null;

  const categories = [
    ...equipment.map((e) => e.category),
    "Elektronik",
    "Håndværktøj",
    "Maskine",
    "Strukturel",
  ].filter((e, index, self) => self.indexOf(e) === index);

  const locations = equipment
    .map((e) => e.location)
    .filter((e, index, self) => self.indexOf(e) === index);

  const makes = equipment
    .map((e) => e.make)
    .filter((e, index, self) => self.indexOf(e) === index);

  const models = equipment
    .map((e) => e.model)
    .filter((e, index, self) => self.indexOf(e) === index);

  return (
    <div className="p-2 sm:p-8 bg-gray-200 min-w-screen">
      <DashboardPages.Form title="Tilføj nyt udstyr" className="mb-20 md:mb-40">
        <Form onSubmit={() => {}}>
          <FormHeading>
            <EquipmentIcon className="w-8 h-8 mr-4" /> Tilføj nyt udstyr
          </FormHeading>

          <FormSection
            heading="Information"
            info={
              <>
                <p className="mb-2">Basal information om udstyret.</p>
                <p>
                  Hvis udstyret ikke allerede har et unikt id-nummer, anbefaler
                  vi at du opretter et, og sørger for at det fremgår tydeligt på
                  udstyret.
                </p>
              </>
            }
          >
            <TextOrSelectFieldset
              id={"make"}
              label="Fabrikat"
              placeholder="Fabrikat"
              required
              valid={make.length > 0}
              help="Eksempel: 'Makita' eller 'Specialfremstillet'"
              value={make}
              options={makes.map((m) => ({ value: m, label: m }))}
              onChange={setMake}
              entity="fabrikat"
              preferSelect
              autoFocus
            />
            <TextOrSelectFieldset
              id={"model"}
              label="Model"
              required
              valid={model.length > 0}
              options={models.map((m) => ({ value: m, label: m }))}
              placeholder="Model"
              help="Eksempel: Slibemaskine, eller 'Vito'"
              value={model}
              onChange={setModel}
              entity="model"
            />
            <TextFieldset
              id={"equipmentId"}
              label="Identifikationsnummer"
              placeholder="Identifikationsnummer"
              help="Eksempel: '01' eller 'A1'"
              value={equipmentId}
              onChange={setEquipmentId}
            />
          </FormSection>

          <FormSection
            heading="Kategori"
            info="Kategorien gør det nemmere at finde udstyr du leder efter."
          >
            <TextOrSelectFieldset
              id="category"
              options={categories.map((c) => ({ value: c, label: c }))}
              label="Kategori"
              onChange={setCategory}
              placeholder="Kategori"
              value={category}
              entity="kategori"
              preferSelect
            />
          </FormSection>

          <FormSection
            heading="Lokation"
            info="Hvor kan en ansvarlig finde udstyret når en opgave skal udføres?"
          >
            <TextOrSelectFieldset
              id="location"
              options={locations.map((c) => ({ value: c, label: c }))}
              label="Lokation"
              onChange={setLocation}
              placeholder="Lokation"
              value={location}
              entity="lokation"
              preferSelect
            />
          </FormSection>

          <FormActionBar>
            <Button
              className="mr-4"
              onClick={() => navigate(`/dashboard/${companyId}/equipment`)}
            >
              Fortryd
            </Button>

            <Button
              disabled={
                model.length === 0 ||
                make.length === 0 ||
                equipmentId.length === 0 ||
                category.length === 0 ||
                location.length === 0
              }
              type="primary"
              htmlType="submit"
              loading={loading}
              onClick={handleSubmit}
            >
              <CheckCircleIcon className="w-4 h-4 mr-2" /> Tilføj nyt udstyr
            </Button>
          </FormActionBar>
        </Form>
      </DashboardPages.Form>
    </div>
  );
};
