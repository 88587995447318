export const extractFileName = (fileId: string): string => {
  // fileId is expected to look as follows:
  // 1234-5678-9012---filename.extension
  // e.g. 1234-5678-9012---manual.pdf

  const fileIdArr = fileId.split("---");
  if (fileIdArr.length === 1 || fileIdArr.length > 2) {
    return fileId;
  } else if (fileIdArr[0].includes(".")) {
    return fileIdArr[0];
  } else {
    return fileIdArr[1];
  }
};
