import { useState } from "react";
import { mutate } from "swr";
import { useParams } from "react-router-dom";
import { post } from "../components/requests/requests";
import { notify } from "../dashboard/components/notifications/newNotification";
import { companiesKey } from "./useCompanies";
import { invitationsKey } from "./useInvitations";

export const useAcceptInvitation = () => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);

  const acceptInvitation = async ({
    token,
    companyName,
    password,
    name,
  }: {
    token: string;
    companyName: string;
    password?: string;
    name?: string;
  }): Promise<boolean> => {
    setLoading(true);
    const result = await post(
      "invitation",
      { token, name, password },
      { companyId }
    );

    mutate(companiesKey);
    mutate(invitationsKey);

    setLoading(false);
    if (result === "OK") {
      notify({
        heading: "Success",
        text: `Du har nu adgang til ${companyName}`,
      });
      return true;
    } else {
      notify({
        heading: "Fejl",
        text: "Invitationen kunne ikke accepteres. Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  return {
    acceptInvitation,
    loading,
  };
};
