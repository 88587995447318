import { useState } from "react";
import { mutate } from "swr";
import { useParams } from "react-router-dom";
import { deleteRequest } from "../components/requests/requests";
import { notify } from "../dashboard/components/notifications/newNotification";

export const useDeleteInvitation = () => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);

  const deleteInvitation = async (token: string): Promise<boolean> => {
    setLoading(true);
    const success = await deleteRequest("invitation", { token }, { companyId });
    setLoading(false);
    if (success) {
      mutate("api/invitations");
      notify({
        heading: "Success",
        text: "Invitationen er nu slettet",
      });
      return true;
    } else {
      notify({
        heading: "Fejl",
        text: "Invitationen kunne ikke slettes. Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  return {
    deleteInvitation,
    loading,
  };
};
