import { useState, useMemo } from "react";
import {
  useParams,
  useNavigate,
  Routes,
  Route,
  NavLink,
  Link,
} from "react-router-dom";
import {
  Button,
  Edit2Icon,
  Modal,
  File,
  FileX2Icon,
  Trash2Icon,
  AlertTriangleIcon,
  ChevronRightIcon,
  InfoIcon,
} from "@onlion/components";
import useTasks from "../../../../data/useTasks";
import { NoData } from "../../../components/noData";
import { ModalContent } from "../../../../components/modalContent";
import { intervalToString } from "../../../../components/intervalToString";
import { format_date } from "@onlion/utils";
import { SingleTaskHistory } from "./history/singleTaskHistory";
import useUser from "../../../../data/useUser";
import { mapStatus } from "../../../../components/mapStatus";
import Badge from "../../../../components/badge";
import { useUserRoleType } from "../../../../components/hooks";
import { NotesSection } from "../../../components/notesSection";

export const TaskFocusPage = () => {
  const { id, companyId } = useParams();
  const navigate = useNavigate();
  const { tasks, deleteTask } = useTasks();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { user } = useUser();
  const { isAdmin } = useUserRoleType(user);

  const focusedTask = useMemo(
    () => tasks?.find((t) => t.id === id),
    [tasks, id]
  );

  if (!focusedTask) return null;

  return (
    <>
      <div className="w-full bg-white bg-gray-50 min-h-screen">
        <div className="bg-white border-b border-gray-300 p-6 py-4">
          <div className="flex justify-between items-center flex-col gap-4 md:flex-row">
            <div className="flex flex-col text-sm gap-1 align-center">
              <div className="flex">
                <Link
                  to={`/dashboard/${companyId}/tasks`}
                  className="text-blue-800 hover:underline"
                >
                  Opgaver
                </Link>{" "}
                <ChevronRightIcon className="w-4 text-gray-400" />
              </div>
              <h1 className="text-2xl font-bold text-blue-900">
                {focusedTask.title}
              </h1>
            </div>
            <div>
              {isAdmin && (
                <div className={"flex gap-1"}>
                  <Button
                    icon={{ leading: Edit2Icon }}
                    onClick={() =>
                      navigate(`/dashboard/${companyId}/tasks/update/${id}`)
                    }
                  >
                    Ret
                  </Button>
                  <Button
                    icon={{ leading: Trash2Icon }}
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Slet
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <main className="md:grid grid-cols-12 w-full py-2 px-2 md:py-4 md:px-4 sm:gap-8">
          <aside className="col-span-3 mb-4">
            <h2 className="px-4 py-1 border-b-2 border-transparent inline-block font-medium text-gray-800">
              Info
            </h2>

            <section className="px-2 py-3 border-t border-gray-300">
              <ul className="flex flex-col gap-2">
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Status
                  </label>
                  <span className="block break-words text-sm">
                    <Badge type={mapStatus(focusedTask.status).color}>
                      {mapStatus(focusedTask.status).label}
                    </Badge>
                  </span>
                </li>
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Type
                  </label>
                  <span className="block break-words text-sm">
                    {focusedTask.type === "CYCLICAL" ? (
                      <>
                        <span className="capitalize block">Rutineopgave </span>
                        <span className="text-xs">
                          Udføres {intervalToString(focusedTask.interval, true)}
                        </span>
                      </>
                    ) : (
                      "Enkeltopgave"
                    )}
                  </span>
                </li>
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    {focusedTask.type === "CYCLICAL"
                      ? "Næste deadline"
                      : "Deadline"}
                  </label>
                  <span className="block break-words text-sm">
                    {format_date(focusedTask.deadline)}
                  </span>
                </li>
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    {focusedTask.type === "AD_HOC" ? "Udført" : "Senest udført"}
                  </label>
                  <span className="block break-words text-sm">
                    {focusedTask.lastCompleted
                      ? format_date(focusedTask.lastCompleted)
                      : "Endnu ikke udført"}
                  </span>
                </li>
                <hr className="mt-1.5" />
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Udstyr
                  </label>
                  <span className="block break-words text-sm">
                    <Link
                      className="underline text-blue-800"
                      to={`/dashboard/${companyId}/equipment/${focusedTask.equipment.id}`}
                    >
                      {focusedTask.equipment.name} &rarr;
                    </Link>
                  </span>
                </li>
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Ansvarlig
                  </label>
                  <span className="block break-words text-sm">
                    <Link
                      className="underline text-blue-800"
                      to={`/dashboard/${companyId}/users/${focusedTask.responsible.id}`}
                    >
                      {focusedTask.responsible.name} &rarr;
                    </Link>
                  </span>
                </li>
              </ul>
            </section>
          </aside>

          <section className="col-span-9">
            <div className="flex">
              <NavLink className={tabClass} end to="">
                Noter
              </NavLink>
              <NavLink className={tabClass} to="info">
                Beskrivelse & Filer
              </NavLink>
              <NavLink className={tabClass} to="history">
                Historik
              </NavLink>
            </div>
            <div className="px-2 py-3 border-t border-gray-300">
              <Routes>
                <Route
                  element={
                    <div>
                      <p className="text-sm italic flex gap-2 bg-blue-50 p-1 text-blue-900">
                        <InfoIcon className="w-4" /> Viser kun noter skrevet i
                        forbindelse med udførsel af denne opgave
                      </p>
                      <NotesSection
                        taskId={id ?? ""}
                        key={id ?? ""}
                        equipmentId={focusedTask.equipment.id}
                        noDataMessage="Ingen noter skrevet i forbindelse med denne opgave"
                        allowCreate={false}
                        showTaskLink={false}
                      />
                    </div>
                  }
                  path=""
                />
                <Route
                  element={
                    <div className="flex w-full flex-col lg:items-start md:flex-row md:justify-between md:space-x-12 my-2">
                      <div>
                        <h3 className="font-medium mb-2">Beskrivelse</h3>
                        <p className="mb-4 text-sm leading-7">
                          {focusedTask.description}
                        </p>
                      </div>
                      <div className="mt-4 md:mt-0">
                        <p className="font-medium flex items-center w-full md:w-96">
                          Filer
                        </p>
                        {Array.isArray(focusedTask.files) && (
                          <div>
                            {focusedTask.files.map((f, i) => (
                              <File key={i} fileId={f} />
                            ))}
                          </div>
                        )}
                        <NoData
                          show={
                            !Array.isArray(focusedTask.files) ||
                            (Array.isArray(focusedTask.files) &&
                              focusedTask.files.length === 0)
                          }
                        >
                          <FileX2Icon className="w-4 h-4 mr-2" /> Ingen filer
                          vedlagt
                        </NoData>
                      </div>
                    </div>
                  }
                  path="info"
                />
                <Route element={<SingleTaskHistory />} path="history" />
              </Routes>
            </div>
          </section>
        </main>
      </div>

      <Modal
        isOpen={showDeleteModal}
        setIsOpen={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalContent
          Icon={AlertTriangleIcon}
          iconColor="yellow"
          heading={`Slet ${focusedTask.title}?`}
          content={
            <>
              <p>Dette kan ikke fortrydes.</p>
              <p>Opgavens statistik vil også blive slettet permanent.</p>
            </>
          }
          buttons={
            <>
              <Button onClick={() => setShowDeleteModal(!showDeleteModal)}>
                Fortryd
              </Button>
              <Button
                type="danger"
                onClick={async () => {
                  const success = await deleteTask(focusedTask.id);
                  if (success)
                    navigate(
                      `/dashboard/${companyId}/equipment/${focusedTask.equipment.id}`
                    );
                }}
              >
                <Trash2Icon className={"w-4 h-4 mr-2"} /> Slet permanent
              </Button>
            </>
          }
        />
      </Modal>
    </>
  );
};

const tabClass = ({ isActive }: { isActive: boolean }) =>
  `px-4 py-1 border-b-2 border-transparent inline-block ${
    isActive
      ? "font-medium border-blue-500 text-gray-800"
      : "font-base text-gray-600"
  }`;
