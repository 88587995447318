import { useTaskQuery } from "../../../../data/useTaskQuery";
import useUser from "../../../../data/useUser";

export const UserOverview = () => {
  const { user } = useUser();

  const { tasks, isLoading, hasFailed } = useTaskQuery({
    userId: user.id,
  });

  if (!user) return null;

  if (hasFailed)
    return <div>Kunne ikke hente opgaver lige nu, prøv igen senere...</div>;

  if (isLoading) return <div>Henter opgaver, vent venligst</div>;

  return (
    <div className="m-8 font-bold">
      <h1 className="text-3xl text-gray-800">Din oversigt</h1>
      {tasks.map((t) => t.title)}
    </div>
  );
};
