import { useState, useMemo } from "react";
import {
  Routes,
  useParams,
  Route,
  useNavigate,
  NavLink,
} from "react-router-dom";
import {
  ArrowLeftRightIcon,
  ChevronRightIcon,
  Edit2Icon,
  Trash2Icon,
} from "@onlion/components";
import Tasks from "./sections/tasks";
import { DeleteModal } from "./sections/deleteModal";
import { UserHistory } from "./sections/history";
import useUsers from "../../../../../data/users/useUsers";
import useUser from "../../../../../data/useUser";
import { getUserMeta } from "../../utils/getUserMeta";
import { Button } from "../../../../../components/button";
import { Link } from "react-router-dom";
import { useUserRoleType } from "../../../../../components/hooks";
import { useTaskQuery } from "../../../../../data/useTaskQuery";

export const UserFocusPage = () => {
  const { id, companyId } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { user } = useUser();
  const { tasks } = useTaskQuery({ userId: id });
  const { isSuperAdmin } = useUserRoleType(user);
  const { users } = useUsers();

  const focusedUser = useMemo(
    () => (users ? users.find((u) => u.id === id) : null),
    [users, id]
  );

  if (!focusedUser || !id || !user || !companyId) return null;

  const isSelfOrNonSuperAdmin = user.id === focusedUser.id || !isSuperAdmin;

  const actionsClass = isSelfOrNonSuperAdmin ? "hidden" : "flex gap-1";

  return (
    <>
      <div className="w-full bg-white bg-gray-50 min-h-screen">
        <div className="bg-white border-b border-gray-200 p-6 py-6 shadow-sm">
          <div className="flex justify-between items-center flex-col gap-4 md:gap-0 md:flex-row">
            <div className="flex flex-col text-sm gap-1 align-center">
              <div className="flex">
                <Link
                  to={`/dashboard/${companyId}/users`}
                  className="underline text-blue-800"
                >
                  Brugere
                </Link>{" "}
                <ChevronRightIcon className="w-4 text-gray-400" />
              </div>
              <h1 className="text-2xl font-bold text-blue-900">
                {focusedUser.name}
              </h1>
            </div>

            <div className={actionsClass}>
              <Button
                icon={{ leading: ArrowLeftRightIcon }}
                onClick={() =>
                  navigate(
                    `/dashboard/${companyId}/users/transfer/${focusedUser.id}`
                  )
                }
              >
                Overdrag ansvar
              </Button>
              {isSuperAdmin && (
                <Button
                  icon={{ leading: Edit2Icon }}
                  onClick={() =>
                    navigate(`/dashboard/${companyId}/users/update/${id}`)
                  }
                >
                  Ret rolle
                </Button>
              )}
              <Button
                icon={{ leading: Trash2Icon }}
                onClick={() => setShowDeleteModal(true)}
              >
                Slet
              </Button>
            </div>
          </div>
        </div>

        <main className="md:grid grid-cols-12 w-full py-2 px-2 md:py-4 md:px-4 sm:gap-8">
          <aside className="col-span-3 mb-4">
            <h2 className="px-4 py-1 border-b-2 border-transparent inline-block font-medium text-gray-800">
              Info
            </h2>

            <section className="px-2 py-3 border-t border-gray-300">
              <ul className="flex flex-col gap-2">
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Rolle
                  </label>
                  <span className="block break-words text-sm">
                    {getUserMeta(focusedUser, companyId).role}
                  </span>
                </li>
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Emailadresse
                  </label>
                  <span className="block break-words text-sm">
                    {focusedUser.emailAddress}
                  </span>
                </li>
              </ul>
            </section>
          </aside>

          <section className="col-span-9">
            <div className="flex">
              <NavLink className={tabClass} end to="">
                Opgaver
              </NavLink>
              <NavLink className={tabClass} to="history">
                Historik
              </NavLink>
            </div>
            <div className="px-2 py-3 border-t border-gray-300">
              <Routes>
                {tasks && (
                  <Route element={<Tasks tasks={tasks} id={id} />} path="" />
                )}
                <Route path="history" element={<UserHistory />} />
              </Routes>
            </div>
          </section>
        </main>
      </div>

      <DeleteModal
        user={focusedUser}
        isOpen={showDeleteModal}
        setIsOpen={() => setShowDeleteModal(false)}
      />
    </>
  );
};

const tabClass = ({ isActive }: { isActive: boolean }) =>
  `px-4 py-1 border-b-2 border-transparent inline-block ${
    isActive
      ? "font-medium border-blue-500 text-gray-800"
      : "font-base text-gray-600"
  }`;
