import { isSameDay } from "date-fns";
import { HistoricCompletion } from "../data/useHistoricCompletions";

export const wasLate = (completedTask: HistoricCompletion) => {
  const sameDay = isSameDay(completedTask.finishedOn, completedTask.deadline);
  if (sameDay) return false;

  const before =
    new Date(completedTask.finishedOn) < new Date(completedTask.deadline);
  if (before) return false;

  return true;
};
