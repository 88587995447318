import useUser from "../../../data/useUser";
import { useCompanies } from "../../../data/useCompanies";
import Link from "../../../components/Link/Link";
import { Table } from "../../../components/table";

export const CompaniesSection = () => {
  const { user, loading } = useUser();
  const { companies } = useCompanies({ userId: user?.id });

  if (loading) return <div>Henter bruger...</div>;

  if (!user || !companies) return null;

  const today = new Date();
  const monthInDanish = today.toLocaleString("da-DK", { month: "long" });

  const companiesWhereUserIsAdmin = companies.filter((c) => {
    const type = user.roles[c.id]?.type;
    if (!type) return false;
    if (["SUPER_ADMIN", "ADMIN"].includes(type)) return true;
    return false;
  });

  const isAdmin = companiesWhereUserIsAdmin.length > 0;

  const companiesWhereUserIsNotAdmin = companies.filter((c) => {
    const type = user.roles[c.id]?.type;
    if (!type) return false;
    if (["USER"].includes(type)) return true;
    return false;
  });

  const isNormalUser = companiesWhereUserIsNotAdmin.length > 0;
  return (
    <div id="companies">
      {companies.length === 0 && (
        <h3 className="mt-4 font-medium">
          Du er ikke en tilknyttet nogen konto, endnu
        </h3>
      )}

      {isAdmin && isNormalUser && (
        <h3 className="mt-6 font-medium">Konti du administrerer</h3>
      )}

      {isAdmin && (
        <Table
          className="mt-3"
          errorMessage="Du administrer ingen konti, endnu"
          head={[
            { content: "Kontonavn" },
            { content: "Addresse", hideMobile: true },
            {
              content: `Opgaver (${monthInDanish})`,
              hideMobile: true,
              right: true,
            },
            {
              content: `Rettidighed (${monthInDanish})`,
              right: true,
              hideMobile: false,
            },
            { content: "", hideMobile: false },
          ]}
          row={{
            fields: [
              { renderData: ({ name }) => name },
              {
                renderData: ({ city }) => city ?? "(mangler)",
                hideMobile: true,
              },
              {
                renderData: (company) =>
                  `${company.stats.month.tasksCompleted}/${company.stats.month.tasksDue} opgaver udført`,
                hideMobile: true,
                right: true,
              },
              {
                renderData: (company) =>
                  (company.stats.month.percentCompletedInTime * 100).toFixed(
                    1
                  ) + "%",
                hideMobile: true,
                right: true,
              },
              {
                renderData: ({ id }) => (
                  <Link to={`/dashboard/${id}`}>Gå til konto &rarr;</Link>
                ),
                hideMobile: false,
              },
            ],
          }}
          data={companiesWhereUserIsAdmin}
        />
      )}

      {isAdmin && isNormalUser && (
        <h3 className="mt-6 font-medium">Andre konti</h3>
      )}

      {isNormalUser && (
        <Table
          className="mt-3"
          errorMessage="Du er ikke alm. bruger i nogen konti, endnu"
          head={[
            { content: "Kontonavn" },
            { content: "Addresse", hideMobile: true },
            { content: "", hideMobile: false },
          ]}
          row={{
            fields: [
              { renderData: ({ name }) => name },
              {
                renderData: ({ city }) => city ?? "(mangler)",
                hideMobile: true,
              },
              {
                renderData: ({ id }) => (
                  <Link to={`/dashboard/${id}/user-dashboard`}>
                    Gå til konto &rarr;
                  </Link>
                ),
                hideMobile: false,
              },
            ],
          }}
          data={companiesWhereUserIsNotAdmin}
        />
      )}
    </div>
  );
};
