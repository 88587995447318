import { FunctionComponent as FC, ReactNode } from "react";
import { TrashIcon } from "../icons/trashIcon";
import { DownloadIcon } from "@onlion/components";
import { extractFileName } from "../../utils/extractFileName";

export interface IFileProps {
  fileId: string;
  deleteFunc?: () => void;
  className?: string;
}

export const File: FC<IFileProps> = ({ fileId, className, deleteFunc }) => {
  const name = extractFileName(fileId);

  const outerStyles = `group mt-3 flex transform select-none flex-row 
  items-center truncate rounded-sm border border-gray-200 bg-gray-100 p-3
 py-3 px-4 text-sm transition active:translate-y-0.5 md:w-80 ${className}`;

  const isDelete = deleteFunc !== undefined;

  if (isDelete) {
    return (
      <div className={outerStyles}>
        <span className={`mr-3 block text-gray-400`}>{renderIcon(name)}</span>
        <p className="mr-3 truncate overflow-ellipsis text-sm">{name}</p>
        <span
          onClick={deleteFunc}
          className="ml-auto flex cursor-pointer flex-row items-center pl-4 text-xs
    font-medium text-gray-500 hover:text-gray-800 group-hover:text-gray-800"
        >
          <span className="mr-2">Slet</span>
          <TrashIcon className="h-3 w-3" />
        </span>
      </div>
    );
  }

  return (
    <a
      href={`https://s3.eu-central-1.amazonaws.com/onlion-upload/${fileId}`}
      download={true}
      className={outerStyles}
    >
      <span className={`mr-3 block text-gray-400`}>{renderIcon(name)}</span>
      <p className="mr-3 truncate overflow-ellipsis text-sm">{name}</p>
      <span
        className="ml-auto flex cursor-pointer flex-row items-center pl-4 text-xs
    font-medium text-gray-500 hover:text-gray-800 group-hover:text-gray-800"
      >
        <span className="mr-2">Hent</span>
        <DownloadIcon className="h-3 w-3" />
      </span>
    </a>
  );
};

export default File;

const SVG: FC<{ children: ReactNode }> = ({ children }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={`h-4 w-4 text-gray-600`}
  >
    {children}
  </svg>
);

function renderIcon(fileName: string) {
  const extension = fileName.split(".")[fileName.split(".").length - 1];
  switch (extension) {
    case "pdf":
      return (
        <SVG>
          <path d="M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z"></path>
          <polyline points="14 2 14 8 20 8"></polyline>
          <line x1="16" y1="13" x2="8" y2="13"></line>
          <line x1="16" y1="17" x2="8" y2="17"></line>
          <line x1="10" y1="9" x2="8" y2="9"></line>
        </SVG>
      );
    case "txt":
      return (
        <SVG>
          <path d="M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z"></path>
          <polyline points="14 2 14 8 20 8"></polyline>
          <line x1="16" y1="13" x2="8" y2="13"></line>
          <line x1="16" y1="17" x2="8" y2="17"></line>
          <line x1="10" y1="9" x2="8" y2="9"></line>
        </SVG>
      );
    case "png":
      return get_img_svg();
    case "jpg":
      return get_img_svg();
    case "jpeg":
      return get_img_svg();
    case "bmp":
      return get_img_svg();
    default:
      return (
        <SVG>
          <path d="M14.5 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7.5L14.5 2z"></path>
          <polyline points="14 2 14 8 20 8"></polyline>
        </SVG>
      );
  }
}

function get_img_svg() {
  return (
    <SVG>
      <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
      <circle cx="8.5" cy="8.5" r="1.5"></circle>
      <polyline points="21 15 16 10 5 21"></polyline>
    </SVG>
  );
}
