import axios from "axios";
import { BASE_URL } from "../../../constants";

const setNewPassword = async (token: string, password: string) => {
  try {
    const newPasswordResult = await axios.post(
      `${BASE_URL}/user/new-password`,
      {
        token,
        newPassword: password,
      }
    );

    if (newPasswordResult.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export default setNewPassword;
