import { FunctionComponent, useEffect } from "react";
import EquipmentTablePage from "./pages/table/table";
import { EquipmentFocusPage } from "./pages/focus/focus";
import { Route, Routes } from "react-router-dom";
import { useBreadcrumbs } from "../../../state/useBreadcrumbs";
import { EquipmentCreatePage } from "./pages/create";
import { EquipmentUpdatePage } from "./pages/update";
import { EquipmentCreateTaskPage } from "./pages/createTask";

const EquipmentPage: FunctionComponent = () => {
  const { replaceBreadcrumbs } = useBreadcrumbs();
  useEffect(
    () => replaceBreadcrumbs([{ text: "Udstyr" }]),
    [replaceBreadcrumbs]
  );

  return (
    <div className="equipment">
      <Routes>
        <Route index element={<EquipmentTablePage />} />
        <Route path="list" element={<EquipmentTablePage />} />
        {[":id/*", "list/:id/*"].map((p) => (
          <Route key={p} path={p} element={<EquipmentFocusPage />} />
        ))}
        <Route path="create" element={<EquipmentCreatePage />} />
        {["update/:id", "list/update/:id"].map((p) => (
          <Route path={p} key={p} element={<EquipmentUpdatePage />} />
        ))}
        <Route path=":id/new-task" element={<EquipmentCreateTaskPage />} />
        {[":id/new-task", "list/:id/new-task"].map((p) => (
          <Route path={p} key={p} element={<EquipmentCreateTaskPage />} />
        ))}
      </Routes>
    </div>
  );
};

export default EquipmentPage;
