import { ReactElement, useMemo, useState } from "react";
import { useGetNotes } from "../../../data/notes/useGetNotes";
import useUsers from "../../../data/users/useUsers";
import useUser from "../../../data/useUser";
import { useTable } from "../../../components/useTable";
import { useCreateNote } from "../../../data/notes/useCreateNote";
import { FieldLabel } from "../../../components/form/label";
import Input from "../../../components/form/text/input";
import { Checkbox, SearchIcon } from "@onlion/components";
import { NoteOrNoteResponseForm } from "../../../components/note/noteOrNoteResponseForm";
import { Note } from "../../../components/note";
import { NoData } from "../noData";
import { LoadingComponent } from "../../../components/loading/loadingComponent";

type NotesSectionProps = {
  key: string;
  equipmentId: string;
  taskId?: string;
  noDataMessage?: string;
  allowCreate?: boolean;
  showTaskLink?: boolean;
};

export const NotesSection = ({
  key,
  equipmentId,
  taskId,
  noDataMessage = "Ingen noter at vise",
  allowCreate = true,
  showTaskLink = true,
}: NotesSectionProps): ReactElement | null => {
  const { notes, isLoading } = useGetNotes(
    taskId ? { taskId } : { equipmentId }
  );

  const [createMode, setCreateMode] = useState<boolean>(false);
  const archivedCount = useMemo(
    () => notes.filter((n) => n.archived).length,
    [notes]
  );

  const { users } = useUsers();
  const { user } = useUser();

  const coreCount = useMemo(() => notes.filter((n) => n.core).length, [notes]);

  const { data, query, setQuery, getFilterValue, setFilterValue } = useTable(
    notes,
    key,
    {
      searchKeys: ["content"],
      filters: {
        showHidden: {
          func: (e, v) => (v === true ? true : !e.archived),
          initialValue: false,
        },
        onlyCore: {
          func: (e, v) => {
            if (v) return e.core === true;
            return true;
          },
          initialValue: false,
        },
      },
    }
  );

  const { createNote, loading } = useCreateNote();

  if (!equipmentId) return null;

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 text-center">
        <h1 className="text-3xl font-semibold text-blue-900">Henter noter</h1>
        <h2 className="text-lg">Dette bør kun tage få sekunder</h2>
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex flex-col mt-2 items-end space-y-4 sm:space-x-8 sm:space-y-0 sm:flex-row">
        <div>
          <FieldLabel htmlFor={"search-notes"}>Søg i noter</FieldLabel>
          <Input
            value={query !== "undefined" ? query : ""}
            onChange={setQuery}
            id={"search-notes"}
            placeholder={""}
            clearable={true}
            Icon={SearchIcon}
            short
          />
        </div>
        {coreCount > 0 ? (
          <div className="h-10 flex items-center">
            <Checkbox
              label={`Vis kun stamnoter`}
              value={getFilterValue("onlyCore")}
              onChange={() => {
                const cur = getFilterValue("onlyCore");
                setFilterValue("onlyCore", !cur);
              }}
            />
          </div>
        ) : null}
        {archivedCount > 0 ? (
          <div className="h-10 flex items-center">
            <Checkbox
              label={`Vis ${archivedCount} ${
                archivedCount === 1 ? "arkiveret note" : "arkiverede noter"
              }`}
              value={getFilterValue("showHidden")}
              onChange={() => {
                const cur = getFilterValue("showHidden");
                setFilterValue("showHidden", !cur);
              }}
            />
          </div>
        ) : null}
      </div>

      {/* NOTE FORM */}
      {allowCreate && (
        <>
          {createMode && (
            <NoteOrNoteResponseForm
              cancelFunc={() => setCreateMode(false)}
              submitFunc={({ content, files, recipientIds, core }) =>
                createNote({
                  content,
                  files,
                  recipientIds,
                  equipmentId,
                  core: core ?? false,
                })
              }
              loading={loading}
              users={users ?? []}
              usersId={user.id}
              copy={{
                contentLabel: "Notens indhold",
                recipientsLabel: "Modtagere (valgfrit)",
                recipientsHelp: "Valgte modtagere tilsendes en kopi af noten.",
                submitLabel: "Tilføj note",
              }}
              askForCore={true}
            />
          )}
          {!createMode && (
            <div
              onClick={() => setCreateMode(true)}
              className="overflow-hidden min-w-full w-full py-3"
            >
              <FieldLabel htmlFor="fake-note-button">Tilføj ny note</FieldLabel>
              <input
                placeholder="Notens indhold"
                id="fake-note-button"
                className="border text-sm border-gray-300 rounded py-2 px-4 bg-white w-full relative z-0 inline-block mt-0.5 focus:outline-none"
              ></input>
            </div>
          )}
        </>
      )}

      {/* NOTE LIST */}
      {data.map((note) => (
        <Note
          key={note.id}
          note={note}
          query={query}
          showTaskLink={showTaskLink}
        />
      ))}
      {/* NO DATA */}
      <NoData show={data.length === 0}>
        {noDataMessage}{" "}
        {archivedCount > 0 && `(${archivedCount} arkiverede noter)`}
      </NoData>
    </div>
  );
};
