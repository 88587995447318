import useSWR, { mutate } from "swr";
import { key } from "./key";
import { User } from "./types";
import { Mutator } from "swr/dist/types";
import { get } from "../../components/requests/requests";
import { useCompanyIdParam } from "../../components/hooks";

const usersFetcher = async (companyId: string) => {
  try {
    return (await get("user/index", { companyId })).users as User[];
  } catch (err) {
    let error = new Error("Not authorized!") as any;
    error.status = 403;
    throw error;
  }
};

export default function useUsers(): {
  loading: boolean;
  users: User[];
  mutate: Mutator;
} {
  const companyId = useCompanyIdParam();

  if (!companyId) throw new Error("Company id is not defined");

  const { data, error } = useSWR(key, () => usersFetcher(companyId));

  const loading = !data && !error;

  return {
    loading,
    users: data ?? [],
    mutate,
  };
}
