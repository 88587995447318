import React from "react";
import {
  ZapIcon,
  RefreshcwIcon,
  ZapOffIcon,
  Input,
  SearchIcon,
} from "@onlion/components";
import { useNavigate, useParams } from "react-router-dom";
import { intervalToString } from "../../../../../../components/intervalToString";
import { mapStatus } from "../../../../../../components/mapStatus";
import { NoData } from "../../../../../components/noData";
import { useTable } from "../../../../../../components/useTable";
import { highlightMatched } from "../../../../../../components/highlightMatched";
import { Task, TaskType } from "../../../../../../data/useTasks";
import { format_date } from "@onlion/utils";
import { Table } from "../../../../../../components/table";
import Badge from "../../../../../../components/badge";

const Tasks = ({ tasks, id }: { tasks: Task[]; id: string }) => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { data, sort, sorting, query, setQuery } = useTable(
    tasks,
    `users/tasks/${id}`
  );

  if (!tasks || tasks.length === 0 || !companyId)
    return (
      <NoData show>
        <ZapOffIcon className="w-4 h-4 mr-2" />{" "}
        <p className="text-lg">Brugeren har ingen opgaver endnu</p>
      </NoData>
    );

  return (
    <div className="">
      <div className="flex flex-col space-y-4 sm:space-x-8 sm:items-center justify-start sm:space-y-0 sm:flex-row">
        <fieldset>
          <label
            className="text-xs mb-0.5 text-gray-600 font-medium block"
            htmlFor={id}
          >
            Søg i opgaver
          </label>
          <Input
            value={query !== "undefined" ? query : ""}
            onChange={setQuery}
            id={id}
            clearable
            Icon={SearchIcon}
            short
          />
        </fieldset>
      </div>

      <Table
        className="mt-4 pb-2"
        rounded
        sorting={sorting ?? { key: "", ascending: false }}
        head={[
          { content: "Opgave", key: "title", onClick: () => sort("title") },
          { content: "Udstyr" },
          { content: "Type" },
          {
            content: "Deadline",
            key: "deadline",
            onClick: () => sort("deadline"),
          },
          {
            content: "Status",
            hideMobile: false,
            right: true,
          },
        ]}
        row={{
          onClick: ({ id }) => navigate(`/dashboard/${companyId}/tasks/${id}`),
          fields: [
            {
              renderData: ({ title }) => (
                <p className="md:w-36 break-words whitespace-normal block">
                  {highlightMatched({ str: title, toMatch: query })}
                </p>
              ),
            },
            {
              renderData: ({ equipment }) => (
                <>
                  {equipment.name}{" "}
                  <p className="text-xs">{equipment.equipmentId}</p>
                </>
              ),
            },
            {
              renderData: ({ type, interval }) => (
                <>
                  {getType(type)}
                  {interval && (
                    <span className="text-xs">
                      ({intervalToString(interval, true)})
                    </span>
                  )}
                </>
              ),
            },
            {
              renderData: ({ deadline }) => format_date(deadline),
            },
            {
              renderData: ({ status }) => (
                <Badge className="ml-auto" type={mapStatus(status).color}>
                  {mapStatus(status).label}
                </Badge>
              ),
              hideMobile: false,
              right: true,
            },
          ],
        }}
        data={data}
      />
    </div>
  );
};

function getType(type: TaskType) {
  switch (type) {
    case "CYCLICAL":
      return (
        <p className="flex items-center">
          <RefreshcwIcon className="w-3 h-3 mr-2" /> Rutine
        </p>
      );
    case "AD_HOC":
      return (
        <p className="flex items-center">
          <ZapIcon className="w-3 h-3 mr-2" /> Enkelt
        </p>
      );
  }
}

export default Tasks;
