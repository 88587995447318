import {
  ActivityIcon,
  DownloadCloudIcon,
  EquipmentIcon,
  HistoryIcon,
  LockIcon,
  PlusIcon,
  SettingsIcon,
  TrendingupIcon,
  UserIcon,
  UsersIcon,
  ZapIcon,
} from "@onlion/components";
import { User, UserType } from "../../../data/users/types";

export const getNavItems = ({
  user,
  canAddTask,
  userRoleType,
}: {
  user: User;
  canAddTask: boolean;
  userRoleType: UserType;
}) => {
  const isAdmin = ["ADMIN", "SUPER_ADMIN"].includes(userRoleType);
  const isSuperAdmin = userRoleType === "SUPER_ADMIN";

  return {
    primary: [
      {
        items: [
          isAdmin && {
            name: "Oversigt",
            end: true,
            href: "",
            icon: TrendingupIcon,
          },
          {
            name: "Din oversigt",
            end: true,
            href: "user-dashboard",
            icon: UserIcon,
          },
        ].filter(Boolean),
      },
      // Equipment
      {
        items: [
          {
            name: "Udstyr",
            end: true,
            href: "equipment",
            icon: EquipmentIcon,
          },
          isAdmin && {
            name: "Tilføj udstyr",
            href: "equipment/create",
            icon: PlusIcon,
          },
        ].filter(Boolean),
      },
      isAdmin && {
        items: [
          {
            name: "Opgaver",
            end: true,
            href: "tasks",
            icon: ZapIcon,
          },
          canAddTask && {
            name: "Tilføj opgave",
            href: "tasks/create",
            icon: PlusIcon,
          },
          {
            name: "Opgavehistorik",
            href: "tasks/history",
            icon: HistoryIcon,
          },
        ].filter(Boolean),
      },
      // Users
      isAdmin && {
        items: [
          {
            name: "Brugere",
            end: true,
            href: "users",
            icon: UsersIcon,
          },
          {
            name: "Invitér bruger",
            href: "users/create",
            icon: PlusIcon,
          },
        ],
      },
    ].filter(Boolean),
    secondary: [
      isAdmin && {
        name: "Aktivitet",
        href: "activity",
        icon: ActivityIcon,
      },
      isAdmin && {
        name: "Download data",
        href: "download",
        icon: DownloadCloudIcon,
      },
      isSuperAdmin && {
        name: "Abonnement",
        href: "settings",
        icon: SettingsIcon,
      },
      isSuperAdmin && {
        name: "Sikkerhed",
        href: "security",
        icon: LockIcon,
      },
    ].filter(Boolean),
  } as any;
};
