import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ChevronRightIcon,
  ChevronUpIcon,
  DashboardPages,
  InboxIcon,
} from "@onlion/components";
import { Link } from "react-router-dom";

const NewPasswordReceiptPage: FunctionComponent = () => {
  return (
    <DashboardPages.Standard
      title="Anmodning gennemført"
      className="mx-auto md:w-8/12 lg:w-1/2 xl:w-1/3 md:mr-auto shadow-xl"
    >
      <div
        className={`py-8 px-12 mb-8 bg-blue-700 rounded-lg text-white text-center flex flex-col items-center`}
      >
        <h2 className="font-semibold text-blue-200 text-sm uppercase tracking-wide mb-2">
          Anmodning gennemført
        </h2>
        <h1 className="font-bold text-xl text-center mx-auto flex items-center">
          <InboxIcon className="w-8 h-8 mr-4" strokeWidth={2} />{" "}
          <span>Check din email indbakke</span>
        </h1>
      </div>

      <div className="leading-8">
        <p className="my-4">
          Vi har sendt dig en email med instruktioner til at vælge et nyt
          password.
        </p>

        <p>
          Hvis du ikke modtager en email fra os, er det fordi der ikke findes en
          konto med den emailadresse du indtastede.
        </p>
      </div>

      <hr className="my-4" />
      <details className="mt-3 text-sm">
        <summary className="flex text-sm items-center w-max text-blue-900">
          Problemer med at logge ind?{" "}
          <ChevronUpIcon className="w-3 h-3 ml-2 summary-icon" />
        </summary>
        <Link
          className="mt-3 flex items-center text-blue-900 font-semibold"
          to="/auth"
        >
          Log ind <ChevronRightIcon className="w-4 h-4 ml-1" />
        </Link>
      </details>
    </DashboardPages.Standard>
  );
};

export default NewPasswordReceiptPage;
