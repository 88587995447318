import { useEffect } from "react";
import { useBreadcrumbs } from "../../../state/useBreadcrumbs";

const HelpPage = () => {
  const { replaceBreadcrumbs } = useBreadcrumbs();
  useEffect(() => replaceBreadcrumbs([{ text: "Hjælp og kontakt" }]), []);

  return (
    <div className="flex flex-col md:flex-row leading-7">
      Side mangler - BETA
    </div>
  );
};

export default HelpPage;
