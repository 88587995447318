import axios from "axios";
import { BASE_URL } from "../../../constants";
import { mutate } from "swr";
import { setAuthTokenKeyInLocalStorage } from "../../utils/setAuthTokenKeyInLocalStorage";
import { setAuthorizationHeader } from "../../utils/setAuthorizationHeader";

type LoginResponse =
  | {
      token: string;
      user: { name: string; companyName: string };
      mfa?: never;
    }
  | { user?: never; token?: never; mfa: true };

const login = async (
  emailAddress: string,
  password: string
): Promise<{ success: boolean; mfa?: boolean }> => {
  try {
    const result = await axios.post<LoginResponse>(`${BASE_URL}/user`, {
      emailAddress,
      password,
    });

    if (result.status === 200) {
      if (result.data.mfa) {
        return { success: true, mfa: true };
      }

      const { user, token } = result.data;

      setAuthorizationHeader(token);
      setAuthTokenKeyInLocalStorage(token);

      mutate("api/user", user, true);

      return { success: true, mfa: false };
    } else {
      return { success: false };
    }
  } catch (err) {
    return { success: false };
  }
};

export default login;
