import { get } from "../components/requests/requests";
import useSWR from "swr";
import { Company } from "./useCompany";
import { Mutator } from "swr/dist/types";

export const companiesKey = "api/companies";

export const useCompanies = ({
  userId,
}: {
  userId: string;
}): { companies: Company[]; isLoading: boolean; mutate: Mutator } => {
  const { data, error, mutate } = useSWR(companiesKey, () =>
    get("company", { userId })
  );

  const isLoading = !data && !error;

  return {
    isLoading,
    companies: data ?? [],
    mutate,
  };
};
