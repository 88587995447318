export function getUnique<T extends { [key: string]: any }, R>(
  arr: T[],
  key: string
): R[] {
  return arr.reduce((unique: R[], elem) => {
    if (unique.indexOf(elem[key]) === -1) {
      return [...unique, elem[key]];
    } else {
      return unique;
    }
  }, []);
}
