import useUser, { logOut } from "../../../data/useUser";
import { LogOutIcon } from "../../../components/icons/logOut";
import { Button } from "../../../components/button";

export const ProfilePage = () => {
  const { user } = useUser();
  return (
    <div className="w-full h-full bg-gray-50">
      <div className="rounded bg-white max-w-full sm:mt-12 sm:max-w-sm mx-auto p-8 shadow-sm">
        <h1 className="text-xl font-semibold text-blue-900 mt-2">
          {user.name}
        </h1>
        <h2 className="text-sm text-gray-500">{user.emailAddress}</h2>
        <h2 className="text-gray-500 mt-2">
          Du bruger Tela Vedligehold på vegne af virksomheden '
          {/* {user.companyName}' */}
        </h2>
        <hr className="my-4" />
        <section className="text-gray-500 text-xs mt-4">
          <p>
            Medmindre du er på en delt computer behøver du ikke at logge ud.
          </p>
          <p className="mt-2">
            Luk blot denne fane når du er færdig; hvis du ikke logger ud,
            behøver du ikke at logge ind igen, næste gang du tilgår Tela
            Vedligehold.
          </p>
        </section>
        <Button
          type="primary"
          onClick={logOut}
          className="mt-8"
          icon={{ trailing: LogOutIcon }}
        >
          Log ud
        </Button>
      </div>
    </div>
  );
};
