import { Button, DownloadCloudIcon } from "@onlion/components";
import { Link, useParams } from "react-router-dom";
import { useHistoricCompletions } from "../../../../data/useHistoricCompletions";
import FS from "file-saver";
import { useState } from "react";
import format from "date-fns/format";
import subYears from "date-fns/subYears";
import da from "date-fns/locale/da";
import { wasLate } from "../../../../utils/wasLate";

export const DownloadHistoricCompletions = () => {
  const { companyId } = useParams();
  const { historicCompletions, loading } = useHistoricCompletions();
  const [loadingDownload, setLoadingDownload] = useState(false);

  const downloadHistoricCompletions = async () => {
    try {
      setLoadingDownload(true);
      const content =
        `opgave,udstyr,udført dato,deadline dato,forsinket,bruger,opgavetype\n` +
        historicCompletions
          .map((hc) =>
            [
              hc.task.title,
              `${hc.equipment.name} ${hc.equipment.equipmentId}`,
              format(new Date(hc.finishedOn), "d/M/yyyy", {
                locale: da,
              }),
              format(new Date(hc.deadline), "d/M/yyyy", {
                locale: da,
              }),
              wasLate(hc) ? "ja" : "nej",
              hc.responsible.name ?? "en nu slettet bruger",
              hc.task.type === "AD_HOC" ? "enkeltopgave" : "rutineopgave",
            ].join(",")
          )
          .join("\n");
      const blob = new Blob([content]);
      FS.saveAs(
        blob,
        `tela-vedligehold-opgavehistorik-${format(new Date(), "d-MMMM-yyyy", {
          locale: da,
        })}.csv`
      );
      setLoadingDownload(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="mt-8 max-w-md">
      <h2 className="font-medium text-xl">Download opgavehistorik</h2>
      <p className="mt-2 text-sm text-gray-800">
        Download en liste over alle opgaveudførsler i år{" "}
        {format(subYears(new Date(), 1), "yyyy")} og{" "}
        {format(new Date(), "yyyy")} (samme data som fremgår på siden{" "}
        <Link
          className="text-blue-500"
          to={`/dashboard/${companyId}/tasks/history`}
        >
          opgavehistorik
        </Link>
        ).
      </p>
      <Button
        className="mt-4"
        loading={loading || loadingDownload}
        onClick={downloadHistoricCompletions}
        icon={{ leading: DownloadCloudIcon }}
        type="primary"
      >
        {loadingDownload ? "Downloader" : loading ? "Henter" : "Download"}{" "}
        opgavehistorik
      </Button>
    </section>
  );
};
