import { getInitials } from "./getInitials";

export const Avatar = ({
  name,
  className,
}: {
  name: string;
  className?: string;
}) => {
  if (!name) return null;
  const initials = getInitials(name);

  return (
    <div
      className={`bg-blue-800 flex items-center justify-center w-8 h-8 text-shadow text-blue-50 rounded-full mr-2 font-semibold ${
        className ?? ""
      }`}
    >
      {initials}
    </div>
  );
};
