import axios from "axios";
import { BASE_URL } from "../constants";

export const uploadFiles = async (files: File[]): Promise<string[]> => {
  if (files.length === 0) return [];

  try {
    // Get S3 upload links and file keys from OnLion API
    const uploadLinksAndKeys = await Promise.all(
      files.map(async (f: File) => getUploadData(f.name))
    );

    // create axios without auth header
    const s3Requester = axios.create();
    delete s3Requester.defaults.headers.common["authorization"];

    // Remove failed (null) upload attempts
    const cleanUploadLinksAndKeys = uploadLinksAndKeys.filter(
      (d) => d !== null
    ) as { url: string; key: string }[];

    // Create upload requests with with S3 upload links retrieved
    const uploads = cleanUploadLinksAndKeys.map((data, index) =>
      s3Requester.put(data.url, files[index], {
        withCredentials: true,
      })
    );

    // Execute all upload requests from above
    await Promise.all(uploads);

    // return list of file keys
    return cleanUploadLinksAndKeys.map((ulk) => ulk.key);
  } catch (err) {
    console.log("Failed to upload files");
    console.log(err);
    return [];
  }
};

async function getUploadData(
  fileName: string
): Promise<{ key: string; url: string } | null> {
  try {
    const res = await axios.post(`${BASE_URL}/upload`, {
      fileName,
    });

    return res.data;
  } catch (err) {
    return null;
  }
}
