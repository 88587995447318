import { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CheckCircleIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  DashboardPages,
  LogInIcon,
} from "@onlion/components";
import { Link } from "react-router-dom";

const NewPasswordReceiptPage: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <DashboardPages.Standard
      title="Anmodning gennemført"
      className="mx-auto md:p-8 md:w-8/12 lg:w-1/2 xl:w-1/3 md:mr-auto shadow-xl"
    >
      <div
        className={`py-8 px-10 mb-8 bg-green-600 rounded-xl text-white text-center flex flex-col items-center`}
      >
        <h2 className="font-semibold text-green-200 text-sm uppercase tracking-wide mb-2">
          Anmodning gennemført
        </h2>
        <h1 className="font-bold text-xl flex items-center">
          <CheckCircleIcon
            className="w-8 h-8 mr-4 text-green-100"
            strokeWidth={2}
          />{" "}
          <span>Dit nye password blev gemt</span>
        </h1>
      </div>

      <p className="my-4">Du kan nu logge ind med dit nye password.</p>

      <div className="w-full">
        <Button
          type="primary"
          className="flex min-w-full md:min-w-0"
          onClick={() => navigate("/auth")}
        >
          Log ind <LogInIcon className="w-4 h-4 ml-2" />
        </Button>
      </div>

      <hr className="my-4" />
      <details className="mt-3 text-sm">
        <summary className="flex text-sm items-center w-max text-blue-900">
          Problemer med at logge ind?{" "}
          <ChevronUpIcon className="w-3 h-3 ml-2 summary-icon" />
        </summary>
        <Link
          className="mt-3 flex items-center text-blue-900 font-semibold"
          to="/auth"
        >
          Log ind <ChevronRightIcon className="w-4 h-4 ml-1" />
        </Link>
      </details>
    </DashboardPages.Standard>
  );
};

export default NewPasswordReceiptPage;
