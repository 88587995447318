import useSWR from "swr";
import { get, patch } from "../components/requests/requests";
import { notify } from "../dashboard/components/notifications/newNotification";
import { useParams } from "react-router-dom";

const fetcher = async (companyId: string) => get(`company/${companyId}`);

export default function useCompany() {
  const { companyId } = useParams();

  if (!companyId) throw new Error("Company id not specified");

  const { data, error, mutate } = useSWR("api/company", () =>
    fetcher(companyId)
  );

  const update = async (changeSet: CompanyChangeSet) => {
    const success = await patch("company", changeSet, { companyId });
    if (success) {
      mutate({ ...data, ...changeSet });
      notify({
        heading: "Din virksomhed blev opdateret",
        text: "Ændringerne blev gemt.",
      });

      return true;
    } else {
      notify({
        heading: "Din virksomhed kunne ikke opdateres",
        text: "Ændringerne blev ikke gemt.",
        type: "alert",
      });

      return false;
    }
  };

  const loading = !data && !error;
  const err = !data && error;

  return {
    loading,
    company: data as Company | null,
    update,
    error: err,
  };
}

export interface Company {
  name: string;
  stats: Stats;
  onboarding: boolean;
  address?: string;
  city?: string;
  vatNumber?: string;
  subscription: Subscription;
  subscriptionEndDate: Date;
  subscriptionCancelled: boolean;
  mfa?: CompanyMFASettings;
  id: CompanyId;
}

export type CompanyMFASettings = { active: boolean; method: "email" };

export type Subscription = "FREE" | "UNLIMITED";

export type CompanyId = string;

export type CompanyChangeSet = Partial<Company> & {
  id: CompanyId;
};

export type Stats = {
  month: {
    tasksCompleted: number;
    tasksDue: number;
    percentCompletedInTime: number;
    changeOnMonth: number;
  };
  year: {
    tasksCompleted: number;
    tasksDue: number;
    percentCompletedInTime: number;
    changeOnYear: number;
  };
  total: { tasksCompleted: number };
};
