import { useState } from "react";
import { mutate } from "swr";
import { post } from "../../components/requests/requests";
import { notify } from "../../dashboard/components/notifications/newNotification";
import { key } from "./key";
import { UserType } from "./types";
import { useParams } from "react-router-dom";

export const useInviteUser = () => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);

  const inviteUser = async (user: {
    name: string;
    emailAddress: string;
    type: UserType;
  }): Promise<boolean> => {
    setLoading(true);
    const response = await post("user/invite", user, { companyId });
    setLoading(false);
    if (response) {
      mutate(key);
      notify({
        heading: "Brugeren blev inviteret",
        text: "Bed dem om at tjekke deres indbakke.",
        duration: 10000,
      });

      return true;
    } else {
      notify({
        heading: "Brugeren kunne ikke inviteres",
        text: "Noget gik galt. Er emailadressen i brug?",
        type: "alert",
        duration: 10000,
      });
      return false;
    }
  };

  return {
    inviteUser,
    loading,
  };
};
