import { mutate } from "swr";
import { post } from "../../components/requests/requests";
import { notify } from "../../dashboard/components/notifications/newNotification";
import { useState } from "react";
import { uploadFiles } from "../../utils/uploadFiles";
import { NoteResponseCreateSet } from "./types";
import { useParams } from "react-router-dom";

export const useCreateNoteResponse = () => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);

  const createNoteResponse = async (noteResponse: {
    noteId: string;
    content: string;
    files: File[];
    recipientIds: string[];
  }): Promise<boolean> => {
    const { noteId, content, files, recipientIds } = noteResponse;
    setLoading(true);

    const uploadedFiles = await uploadFiles(files);

    const noteResponseCreateSet: NoteResponseCreateSet = {
      content,
      files: uploadedFiles,
      recipientIds,
    };

    const success = await post(
      `equipment/note/${noteId}/response`,
      noteResponseCreateSet,
      { companyId }
    );

    setLoading(false);

    if (success) {
      mutate("api/equipment");
      notify({
        heading: "Svaret blev tilføjet",
        text: "Du kan se det nye svar under noten",
      });

      return true;
    } else {
      notify({
        heading: "Svaret kunne ikke tilføjes",
        text: "Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  return { createNoteResponse, loading };
};
