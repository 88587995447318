import { Fragment, ReactNode } from "react";
import SelectInput, { SelectProps } from "./input";
import { CheckCircleIcon } from "../../icons/checkCircleIcon";
import { FieldLabel } from "../label";

export interface SelectFieldsetProps extends SelectProps {
  label: string;
  help?: string | ReactNode;
  error?: string | ReactNode;
  inputClassName?: string;
  valid?: boolean;
  short?: boolean;
  showRequiredLabel?: boolean;
}

export const SelectFieldset = ({
  label,
  help = null,
  error = null,
  selected,
  onChange,
  className = "",
  inputClassName = "",
  id,
  disabled = false,
  invalid = false,
  placeholder,
  options,
  required,
  autoFocus,
  valid = false,
  short = false,
  showRequiredLabel = false,
}: SelectFieldsetProps) => {
  return (
    <div className={`w-full space-y-1.5 md:w-min ${className}`}>
      <FieldLabel
        htmlFor={id}
        className="flex w-full items-center justify-between"
      >
        <span className="flex items-center">
          {label}{" "}
          {valid && <CheckCircleIcon className="ml-1 h-3 w-3 text-blue-500" />}
        </span>{" "}
        {showRequiredLabel && required && (
          <Fragment>
            <span className=" hidden font-normal text-gray-400 sm:block">
              Påkrævet
            </span>
            <span className="text-gray-400 sm:hidden">*</span>
          </Fragment>
        )}
      </FieldLabel>
      <SelectInput
        id={id}
        placeholder={placeholder ? placeholder : label}
        disabled={disabled}
        invalid={invalid}
        selected={selected}
        options={options}
        onChange={onChange}
        className={inputClassName}
        required={required}
        autoFocus={autoFocus}
        short={short}
      />
      {help && <span className="block text-sm text-gray-500">{help}</span>}
      {error && (
        <span className="ml-2 block text-sm text-red-600">{error}</span>
      )}
    </div>
  );
};

export default SelectFieldset;
