import { BaseIconProps } from "./types";

type SortDescIconProps = BaseIconProps & {
  strokeWidth?: string;
};

export const SortDescIcon = ({
  className = "",
  strokeWidth = "1.75",
}: SortDescIconProps) => {
  return (
    <svg
      className={className}
      strokeWidth={strokeWidth}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11 5h10"></path>
      <path d="M11 9h7"></path>
      <path d="M11 13h4"></path>
      <path d="m3 17 3 3 3-3"></path>
      <path d="M6 18V4"></path>
    </svg>
  );
};
