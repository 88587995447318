import {
  Badge,
  Button,
  CheckIcon,
  ChevronRightIcon,
  TrendingupIcon,
} from "@onlion/components";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import useCompany from "../../../../data/useCompany";
import useEquipment from "../../../../data/useEquipment";
import useTasks from "../../../../data/useTasks";
import useUsers from "../../../../data/users/useUsers";

export const OnboardingSection = () => {
  const { companyId } = useParams();
  const { company, update } = useCompany();
  const { users } = useUsers();
  const { equipment } = useEquipment();
  const { tasks } = useTasks();

  const hasCreatedUser = users.length > 0;
  const hasCreatedEquipment = equipment && equipment.length > 0;
  const hasCreatedTask = tasks && tasks.length > 0;

  const canCreateTask = useMemo(
    () => equipment.length > 0 && users.length > 0,
    [equipment, users]
  );

  if (!company) return null;

  return (
    <>
      <TrendingupIcon className="w-full z-0 md:w-6/12 md:h-6/12 fixed md:right-10 md:-bottom-48 opacity-30 text-gray-400" />
      <div className="bg-white relative rounded-lg w-full md:w-1/2 m-auto mt-12 p-4 md:px-12 md:py-8 md:mb-12 shadow-lg">
        <h1 className="font-extrabold text-3xl text-blue-900">
          Velkommen til Tela Vedligehold
        </h1>
        <p className="mt-4 text-base text-gray-600">
          Programmet hjælper dig med at få dit vedligehold kommunikeret, udført
          og dokumenteret.
        </p>
        <p className="mt-4 text-base text-gray-600">
          Når du har tilføjet dine brugere, udstyr og opgaver sender systemet
          automatisk opgaverne til dem som skal udføre dem, når de skal udføres
          - og du får overblik, og bedre indsigt i dit udstyr.
        </p>
        <h2 className="font-medium text-xl mt-12 mb-4 text-gray-700">
          Kom i gang med 3 simple trin
        </h2>
        <hr className="my-2" />
        <div className="flex sm:flex-row justify-between items-center py-4">
          {!hasCreatedUser && (
            <>
              <Link
                to={`/dashboard/${companyId}/users/create`}
                className="font-medium text-md text-blue-800 flex items-center"
              >
                1. Tilføj en bruger{" "}
                <ChevronRightIcon className="w-4 h-4 ml-1" />
              </Link>
              <Badge type="gray" className="text-sm">
                Mangler
              </Badge>
            </>
          )}
          {hasCreatedUser && (
            <>
              <span className="font-medium text-md line-through">
                1. Tilføj en bruger
              </span>
              <Badge
                type="blue"
                className="text-sm flex flex-row items-center bg-blue-800"
              >
                <span>Udført</span> <CheckIcon className="w-4 h-4 ml-2" />
              </Badge>
            </>
          )}
        </div>
        <hr className="my-2" />
        <div className="flex sm:flex-row justify-between items-center py-4 z-10">
          {!hasCreatedEquipment && (
            <>
              <Link
                to={`/dashboard/${companyId}/equipment/create`}
                className="font-medium text-md text-blue-800 flex items-center"
              >
                2. Tilføj et udstyr{" "}
                <ChevronRightIcon className="w-4 h-4 ml-1" />
              </Link>
              <Badge type="gray" className="text-sm">
                Mangler
              </Badge>
            </>
          )}
          {hasCreatedEquipment && (
            <>
              <span className="font-medium text-md line-through">
                2. Tilføj et udstyr
              </span>
              <Badge
                type="blue"
                className="text-sm flex flex-row items-center bg-blue-800"
              >
                <span>Udført</span> <CheckIcon className="w-4 h-4 ml-2" />
              </Badge>
            </>
          )}
        </div>
        <hr className="my-2" />
        <div className="flex sm:flex-row justify-between items-center py-4 z-10">
          {!hasCreatedTask && canCreateTask && (
            <>
              <Link
                to={`/dashboard/${companyId}/tasks/create`}
                className="font-medium text-md text-blue-800 flex items-center"
              >
                3. Tilføj en opgave{" "}
                <ChevronRightIcon className="w-4 h-4 ml-1" />
              </Link>
              <Badge type="gray" className="text-sm">
                Mangler
              </Badge>
            </>
          )}
          {!hasCreatedTask && !canCreateTask && (
            <>
              <span className="font-medium text-md text-gray-500">
                3. Tilføj en opgave{" "}
                <span className="text-sm block ml-4">
                  (tilføj et udstyr og en bruger først)
                </span>
              </span>
              <Badge type="gray" className="text-sm">
                Afventer
              </Badge>
            </>
          )}
          {hasCreatedTask && (
            <>
              <span className="font-medium text-md line-through">
                3. Tilføj en opgave
              </span>
              <Badge
                type="blue"
                className="text-sm flex flex-row items-center bg-blue-800"
              >
                <span>Udført</span> <CheckIcon className="w-4 h-4 ml-2" />
              </Badge>
            </>
          )}
        </div>
        <div className="mt-6 md:w-4/12">
          <Button
            disabled={
              !hasCreatedEquipment || !hasCreatedUser || !hasCreatedTask
            }
            type={
              !hasCreatedEquipment || !hasCreatedUser || !hasCreatedTask
                ? undefined
                : "primary"
            }
            onClick={() => update({ id: company.id, onboarding: false })}
          >
            <CheckIcon className="w-4 h-4 mr-2" /> Vis oversigt
          </Button>
        </div>
      </div>
    </>
  );
};
