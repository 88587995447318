import React, { ReactNode } from "react";

export const FormActionBar = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className={`flex flex-col-reverse md:flex-row justify-end items-center border-t 
max-w-full md:max-w-none bg-gray-50 md:px-8 py-6 md:-mx-8 md:-mb-12 rounded-b-lg`}
    >
      {children}
    </div>
  );
};
