import React from "react";
import { Route, Routes } from "react-router-dom";
import "@onlion/components/dist/index.css";

import Auth from "./auth/auth";
import Dashboard from "./dashboard/dashboard";
import useUser from "./data/useUser";
import { ProfilePage } from "./profile/pages/index";

function App() {
  useUser();

  return (
    <div className="app">
      <Routes>
        <Route element={<Auth />} path="/" />
        <Route element={<Auth />} path="auth/*" />
        <Route element={<Dashboard />} path="dashboard/:companyId/*" />
        <Route element={<ProfilePage />} path="profile/*" />
      </Routes>
    </div>
  );
}

export default App;
