import axios from "axios";
import { BASE_URL } from "../../../constants";

const requestPasswordResetEmail = async (emailAddress: string) => {
  try {
    const newPasswordRequestResult = await axios.post(
      `${BASE_URL}/user/new-password-request`,
      {
        emailAddress: emailAddress,
      }
    );

    if (newPasswordRequestResult.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export default requestPasswordResetEmail;
