import create from "zustand";

export type Breadcrumb = {
  text: string;
  link?: string;
};

export type BreadcrumbState = {
  breadcrumbs: Breadcrumb[];
  replaceBreadcrumbs: (nextBreadcrumbs: Breadcrumb[]) => void;
};

const useBreadcrumbs = create<BreadcrumbState>((set) => ({
  breadcrumbs: [],
  replaceBreadcrumbs: (nextBreadcrumbs: Breadcrumb[]) =>
    set(() => ({ breadcrumbs: nextBreadcrumbs })),
}));

export { useBreadcrumbs };
