import {
  HistoryIcon,
  Loading,
  RefreshcwIcon,
  ZapIcon,
} from "@onlion/components";
import formatDistance from "date-fns/formatDistance";
import da from "date-fns/locale/da";
import { format_date } from "@onlion/utils";
import { useHistoricCompletions } from "../../../../../../data/useHistoricCompletions";
import { useTable } from "../../../../../../components/useTable";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { NoData } from "../../../../../components/noData";
import { Table } from "../../../../../../components/table";
import { wasLate } from "../../../../../../utils/wasLate";

export const UserHistory = () => {
  const { id, companyId } = useParams();
  const { historicCompletions, loading } = useHistoricCompletions();

  const { data } = useTable(historicCompletions, "historicCompletions");

  const onlyUserHistory = data.filter((hc) => hc.responsible.id === id);

  if (onlyUserHistory.length === 0) {
    return (
      <div className="p-2 pt-0">
        <NoData show>
          <HistoryIcon className="w-4 h-4 mr-2" />{" "}
          <p className="text-lg">
            Brugeren har ikke udført nogen opgaver endnu
          </p>
        </NoData>
      </div>
    );
  }

  if (loading)
    return (
      <div className="h-screen">
        <Loading
          title="Henter brugerens opgavehistorik"
          text="Dette bør kun tage få øjeblikke"
        />
      </div>
    );

  return (
    <div className="bg-gray-50 my-2">
      <Table
        head={[
          { content: "Opgave" },
          { content: "Udstyr" },
          { content: "Dato" },
          { content: "Type", hideMobile: false, right: true },
        ]}
        row={{
          fields: [
            {
              renderData: ({ task }) => (
                <Link
                  to={`/dashboard/${companyId}/tasks/${task.id}`}
                  className="hover:text-blue-600 flex"
                >
                  {task.title}
                </Link>
              ),
            },
            {
              renderData: ({ equipment }) => (
                <Link
                  to={`/dashboard/${companyId}/equipment/${equipment.id}`}
                  className="hover:text-blue-600"
                >
                  {equipment.name}{" "}
                  <p className="text-xs">{equipment.equipmentId}</p>
                </Link>
              ),
            },
            {
              renderData: (hc) => (
                <>
                  {format_date(hc.finishedOn)}{" "}
                  <p className="text-xs">
                    {wasLate(hc)
                      ? `forsinket ${formatDistance(
                          new Date(hc.finishedOn),
                          new Date(hc.deadline),
                          { locale: da }
                        )}`
                      : "uden forsinkelse"}
                  </p>
                </>
              ),
            },
            {
              renderData: ({ task: { type } }) => getType(type),
              hideMobile: false,
              right: true,
            },
          ],
        }}
        data={onlyUserHistory}
      />
    </div>
  );
};

function getType(type: "AD_HOC" | "CYCLICAL") {
  switch (type) {
    case "CYCLICAL":
      return (
        <p className="flex items-center justify-end">
          <RefreshcwIcon className="w-3 h-3 mr-2" /> Rutine
        </p>
      );
    case "AD_HOC":
      return (
        <p className="flex items-center justify-end">
          <ZapIcon className="w-3 h-3 mr-2" /> Enkelt
        </p>
      );
  }
}
