import { useEffect } from "react";
import { Route, useNavigate, Routes, useParams } from "react-router-dom";
import useUser from "../data/useUser";

import Layout from "./components/layout/layout";
import TasksPage from "./pages/tasks/tasks";
import EquipmentPage from "./pages/equipment/equipment";
import HelpPage from "./pages/help/help";
import Notifications from "./components/notifications/notifications";
import OverviewPage from "./pages/start";
import { SettingsPage } from "./pages/settings/settings";
import { UsersPage } from "./pages/users/users";
import { ActivityPage } from "./pages/activity";
import { ProfilePage } from "./pages/profile";
import { DataDownloadPage } from "./pages/dataDownload";
import { SecurityPage } from "./pages/security";
import { UserDashboardPage } from "./pages/userDashboard";

const Dashboard = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { user, loggedOut } = useUser();

  useEffect(() => {
    if (loggedOut) {
      const from = window.location.pathname;
      console.log(from);
      navigate(`/auth/?from=${from}`);
    }

    if (!companyId && user) {
      navigate(`/profile`);
    }
  }, [loggedOut, navigate, companyId, user]);

  if (user) {
    return (
      <Layout>
        <Notifications />
        <Routes>
          <Route path="" element={<OverviewPage />} />
          <Route path="user-dashboard" element={<UserDashboardPage />} />
          <Route path="tasks/*" element={<TasksPage />} />
          <Route path="equipment/*" element={<EquipmentPage />} />
          <Route path="users/*" element={<UsersPage />} />
          <Route path="activity/*" element={<ActivityPage />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="download" element={<DataDownloadPage />} />
          <Route path="security" element={<SecurityPage />} />
          <Route path="help" element={<HelpPage />} />
          <Route path="profile" element={<ProfilePage />} />
        </Routes>
      </Layout>
    );
  } else {
    return null;
  }
};

export default Dashboard;
