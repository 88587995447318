const dev_envs = ["development", "dev"];
export const BASE_URL = dev_envs.includes(process.env.NODE_ENV)
  ? "http://localhost:4001/admin"
  : "/api";

export const AUTH_TOKEN_KEY = "auth_token";

export const UPLOAD_BASE_URL =
  "https://s3.eu-central-1.amazonaws.com/onlion-upload/";

export const COMPLETE_BASE_URL = "https://www.complete.onlion.io";
