import { Button, DownloadCloudIcon } from "@onlion/components";
import FS from "file-saver";
import { useState } from "react";
import format from "date-fns/format";
import da from "date-fns/locale/da";
import useEquipment, { Equipment } from "../../../../data/useEquipment";
import JSZip from "jszip";
import useTasks, { Task } from "../../../../data/useTasks";
import { User } from "../../../../data/users/types";
import useUsers from "../../../../data/users/useUsers";

export const DownloadBackup = () => {
  const { equipment, loading: loadingEquipment } = useEquipment();
  const { tasks, loading: loadingTasks } = useTasks();
  const [loadingDownload, setLoadingDownload] = useState(false);
  const { users } = useUsers();

  const loading = loadingTasks || loadingEquipment;

  return (
    <section className="mt-8 max-w-md">
      <h2 className="font-medium text-xl">Download stamdata til backup</h2>
      <p className="mt-2 text-sm text-gray-800">
        Download en mappe med 4 filer, en for hver af: udstyr, brugere, opgaver,
        og noter.
      </p>
      <p className="mt-2 text-sm text-gray-800">
        Afhængig af antal opgaver, udstyr, mm., kan processen tage op til et
        minut.
      </p>
      <p className="mt-2 text-sm text-gray-800">
        Bemærk at den downloadede fil indeholder navne og emailadresser på
        brugere, og den bør derfor opbevares krypteret.
      </p>
      <Button
        className="mt-4"
        loading={loading || loadingDownload}
        onClick={() =>
          downloadBackup({
            setLoading: setLoadingDownload,
            data: { equipment, tasks, responsibles: users },
          })
        }
        icon={{ leading: DownloadCloudIcon }}
        type="primary"
      >
        {loadingDownload ? "Downloader" : loading ? "Henter" : "Download"}{" "}
        backup data
      </Button>
    </section>
  );
};

const downloadBackup = async ({
  setLoading,
  data,
}: {
  setLoading: (loading: boolean) => void;
  data: {
    equipment: Equipment[];
    tasks: Task[];
    responsibles: User[];
  };
}) => {
  try {
    setLoading(true);
    const zipper = new JSZip();
    const dateString = format(new Date(), "d-MMMM-yyyy", { locale: da });
    const folderName = `onlion-data-${dateString}`;

    [
      { name: "udstyr", content: formatEquipment(data.equipment) },
      { name: "opgaver", content: formatTasks(data.tasks) },
      { name: "brugere", content: formatResponsibles(data.responsibles) },
      { name: "noter", content: formatNotes(data.equipment) },
    ].forEach((file) =>
      zipper.file(`${folderName}/${file.name}-${dateString}.csv`, file.content)
    );

    const zippedFile = await zipper.generateAsync({ type: "blob" });

    FS.saveAs(
      zippedFile,
      `tela-vedligehold-backup-${format(new Date(), "d-MMMM-yyyy", {
        locale: da,
      })}`
    );
    setLoading(false);
  } catch (err) {
    console.log(err);
  }
};

const formatEquipment = (equipment: Equipment[]) =>
  equipment.reduce(
    (str, e) =>
      str +
      `${e.make},${e.model},${e.equipmentId},${e.category},${e.location}\n`,
    `fabrikat,type,id,kategori,lokation\n`
  );

const formatTasks = (tasks: Task[]) =>
  tasks.reduce(
    (str, t) =>
      str +
      `${t.title},${t.equipment.name} ${t.equipment.equipmentId},${
        t.responsible.name
      },${t.interval ?? "enkeltopgave"},${format(
        new Date(t.deadline),
        "d/MM/yyyy",
        { locale: da }
      )},${t.type}\n`,
    `opgave,udstyr,ansvarlig,interval,næste deadline, type\n`
  );

const formatResponsibles = (responsibles: User[]) =>
  responsibles.reduce(
    (str, r) => str + `${r.name},${r.emailAddress}\n`,
    `navn,email\n`
  );

const formatNotes = (equipment: Equipment[]) =>
  equipment.reduce((str, e) => {
    if (e.notes.length > 0) {
      return (
        str +
        "\n" +
        e.notes
          .map(
            (n) =>
              `${e.make} ${e.model} ${e.equipmentId},${format(
                new Date(n.datetime),
                "d/MM/yyyy",
                { locale: da }
              )},${n.author},"${n.content.replaceAll('"', "'")}",${
                n.files
                  ? n.files
                      .map(
                        (fileId) =>
                          `https://s3.eu-central-1.amazonaws.com/onlion-upload/${fileId}`
                      )
                      .join("")
                  : "ingen filer"
              }`
          )
          .join("\n")
      );
    }
    return str;
  }, `udstyr,dato,person,indhold,filer`);
