import { get } from "../components/requests/requests";
import useSWR from "swr";
import { Mutator } from "swr/dist/types";
import { useParams } from "react-router-dom";
import { Invitation } from "./useInvitations";

export const invitationsKey = "api/invitation";

export const useInvitation = (): {
  loading: boolean;
  invitation: Invitation | null;
  mutate: Mutator;
  error: boolean;
} => {
  const { token } = useParams();

  const { data, error, mutate } = useSWR(invitationsKey, () =>
    get(`invitation/${token}`)
  );

  const loading = data === undefined;

  return {
    loading,
    invitation: data ?? null,
    mutate,
    error: data === null,
  };
};
