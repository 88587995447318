import {
  Button,
  CheckCircleIcon,
  DashboardPages,
  DatePickerFieldset,
  Form,
  FormHeading,
  FormSection,
  Radio,
  SelectFieldset,
  SelectOption,
  TextFieldset,
  Upload,
  ZapIcon,
} from "@onlion/components";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  intervals,
  intervalToString,
} from "../../../../components/intervalToString";
import useEquipment from "../../../../data/useEquipment";
import useTasks, { TaskInterval, TaskType } from "../../../../data/useTasks";
import { useBreadcrumbs } from "../../../../state/useBreadcrumbs";
import { uploadFiles } from "../../../../utils/uploadFiles";
import useUsers from "../../../../data/users/useUsers";

export const TaskCreatePage = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<TaskType>("CYCLICAL");
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [equipmentId, setEquipmentId] = useState<SelectOption | null>(null);
  const [responsibleId, setResponsibleId] = useState<SelectOption | null>(null);
  const [deadline, setDeadline] = useState<Date>(new Date());
  const [interval, setInterval] = useState<SelectOption | null>(null);
  const [notificationPeriod, setNotificationPeriod] =
    useState<SelectOption | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const { replaceBreadcrumbs } = useBreadcrumbs();

  useEffect(
    () =>
      replaceBreadcrumbs([
        {
          text: "Opgaver",
          link: `/dashboard/${companyId}/tasks`,
        },
        {
          text: "Tilføj",
        },
      ]),
    [replaceBreadcrumbs]
  );

  const { createTask, tasks } = useTasks();
  const { equipment } = useEquipment();
  const { users } = useUsers();

  const handleSubmit = async () => {
    setLoading(true);
    const fileStrings = await uploadFiles(files);
    const eId = equipmentId ? (equipmentId.value as string) : "";
    const rId = responsibleId ? (responsibleId.value as string) : "";
    const typedInterval =
      type === "AD_HOC"
        ? undefined
        : interval
        ? (interval.value as TaskInterval)
        : undefined;
    const nPeriod = notificationPeriod
      ? (notificationPeriod.value as number)
      : 0;

    const id = await createTask({
      title,
      description,
      equipmentId: eId,
      responsibleId: rId,
      deadline,
      files: fileStrings,
      interval: typedInterval,
      notificationPeriod: nPeriod,
      type: type,
    });
    setLoading(false);
    if (id) navigate(`/dashboard/${companyId}/tasks/${id}`);
  };

  if (!equipment || !tasks || !users) return null;

  return (
    <div className="p-2 sm:p-8 bg-gray-200 min-h-screen">
      <DashboardPages.Form title="Tilføj ny opgave" className="mb-40">
        <Form onSubmit={() => {}}>
          <FormHeading>
            <ZapIcon className="w-8 h-8 mr-2" /> Tilføj ny opgave
          </FormHeading>

          <FormSection
            heading="Udstyr"
            info="Vælg det udstyr som opgaven skal udføres på"
          >
            <SelectFieldset
              required
              valid={equipmentId !== null}
              id="equipmentId"
              selected={equipmentId}
              label="Vælg udstyr"
              autoFocus
              onChange={setEquipmentId}
              options={equipment.map((e) => ({
                label: (
                  <>
                    {e.name}{" "}
                    <span className="text-sm text-gray-400 block">
                      {e.equipmentId}
                    </span>
                  </>
                ),
                value: e.id,
              }))}
            />
          </FormSection>

          <FormSection
            heading="Om opgaven"
            info={
              <>
                <p className="mb-2">
                  Skriv en titel, og en passende beskrivelse til opgaven.
                </p>
                <p className="mb-2">
                  En god beskrivelse øger chancerne for at opgaven bliver udført
                  rigtigt.
                </p>
                <p>
                  Upload en pdf-fil eller et billede hvis en tekstbeskrivelse
                  ikke er dækkende.
                </p>
              </>
            }
          >
            <TextFieldset
              id={"title"}
              required
              valid={title.length > 0}
              label="Titel"
              placeholder="Titel"
              value={title}
              onChange={setTitle}
            />
            <TextFieldset
              id={"description"}
              required
              valid={description.length > 0}
              label="Beskrivelse"
              placeholder="Beskrivelse"
              rows={4}
              value={description}
              onChange={setDescription}
            />
            <Upload
              label="Filer (valgfrit)"
              files={files}
              setFiles={setFiles}
            />
          </FormSection>

          <FormSection
            heading="Ansvarlig"
            info="Hvem er ansvarlig for at opgaven bliver udført, og kvitteret for?"
          >
            <SelectFieldset
              id="responsibleId"
              required
              valid={responsibleId !== null}
              selected={responsibleId}
              label="Vælg ansvarlig"
              onChange={setResponsibleId}
              options={users.map((r) => ({
                label: r.name,
                value: r.id,
              }))}
            />
          </FormSection>

          <FormSection
            heading={"Udførsel"}
            info={"Vælg hvor ofte og hvornår opgaven skal udføres."}
          >
            <Radio
              label="Type"
              onChange={(v) => {
                if (v === "AD_HOC") setInterval(null);
                setType(v);
              }}
              value={type}
              required
              options={[
                { label: "Skal udføres flere gange", value: "CYCLICAL" },
                { label: "Skal udføres en gang", value: "AD_HOC" },
              ]}
            />
            {type === "CYCLICAL" && (
              <SelectFieldset
                id="interval"
                required
                valid={interval !== null}
                help="Hvor ofte skal opgaven udføres?"
                selected={interval}
                label="Vælg interval"
                onChange={setInterval}
                options={intervals.map((i) => ({
                  label: intervalToString(i),
                  value: i,
                }))}
              />
            )}
            <DatePickerFieldset
              id="deadline"
              valid={true}
              label={type === "CYCLICAL" ? "Første deadline" : "Deadline"}
              help={
                type === "CYCLICAL"
                  ? "Hvornår skal opgaven udføres første gang?"
                  : "Hvornår skal opgaven udføres?"
              }
              value={deadline}
              onChange={setDeadline}
              options={{ onlyFuture: true }}
            />
            <SelectFieldset
              id="notificationPeriod"
              required
              valid={notificationPeriod !== null}
              selected={notificationPeriod}
              label="Påmindelses periode"
              help={
                type === "CYCLICAL"
                  ? "Hvor længe inden deadline skal påmindelser sendes?"
                  : "Hvor længe inden deadline skal en påmindelse sendes?"
              }
              onChange={setNotificationPeriod}
              options={mapIntervalToNotificationPeriods(interval?.value)}
            />
          </FormSection>

          <hr className="my-6" />

          <div className="flex flex-row justify-end items-center">
            <Button
              className="mr-4"
              onClick={() => navigate(`/dashboard/${companyId}/equipment`)}
            >
              Fortryd
            </Button>
            <Button
              disabled={
                title.length === 0 ||
                description.length === 0 ||
                responsibleId === null ||
                equipmentId === null ||
                notificationPeriod === null ||
                (type === "CYCLICAL" && interval === null) ||
                !type
              }
              type="primary"
              htmlType="submit"
              onClick={() => handleSubmit()}
              loading={loading}
            >
              <CheckCircleIcon className="w-4 h-4 mr-2" /> Tilføj opgave
            </Button>
          </div>
        </Form>
      </DashboardPages.Form>
    </div>
  );
};

function mapIntervalToNotificationPeriods(interval: any): SelectOption[] {
  const onDay = { label: "På dagen for deadline", value: 0 };
  const oneDay = { label: "Dagen før deadline", value: 1 };
  const twoDays = { label: "2 dage før deadline", value: 2 };
  const oneWeek = { label: "En uge før deadline", value: 7 };
  const twoWeeks = {
    label: "To uger før deadline",
    value: 14,
  };
  const oneMonth = {
    label: "En måned før deadline",
    value: 30,
  };

  if (interval === null) return [onDay];

  switch (interval) {
    case "DAILY":
      return [onDay];
    case "DAILY_WEEKDAYS":
      return [onDay];
    case "WEEKLY":
      return [onDay, oneDay, twoDays];
    case "BI_WEEKLY":
      return [onDay, oneDay, twoDays, oneWeek];
    case "MONTHLY":
      return [onDay, oneDay, twoDays, oneWeek];
    case "BI_MONTHLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "QUARTERLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "TRIANNUAL":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "HALF_YEARLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "YEARLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "SEMI_YEARLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "EVERY_3_YEARS":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "EVERY_4_YEARS":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "EVERY_5_YEARS":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "EVERY_8_YEARS":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    default:
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
  }
}
