import useSWR, { mutate } from "swr";
import {
  deleteRequest,
  get,
  patch,
  post,
} from "../components/requests/requests";
import { notify } from "../dashboard/components/notifications/newNotification";
import { OriginalNote } from "./notes/types";
import { useParams } from "react-router-dom";

const equipmentFetcher = async (companyId: string) =>
  get("equipment", { companyId });

export default function useEquipment() {
  const { companyId } = useParams();

  if (!companyId) throw new Error("Company id not provided");

  const { data, error } = useSWR("api/equipment", () =>
    equipmentFetcher(companyId)
  );

  const createEquipment = async (
    equipment: EquipmentCreateSet
  ): Promise<EquipmentId | false> => {
    const response = (await post("equipment", equipment, { companyId })) as {
      equipmentId: EquipmentId;
    } | null;
    if (response) {
      mutate("api/equipment");
      notify({
        heading: "Udstyret blev tilføjet",
        text: "Du kan nu se udstyret.",
      });

      return response.equipmentId;
    } else {
      notify({
        heading: "Udstyret kunne ikke tilføjes",
        text: "Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  const updateEquipment = async (
    equipment: EquipmentChangeSet
  ): Promise<boolean> => {
    const success = await patch("equipment", equipment, { companyId });
    if (success) {
      mutate("api/equipment");
      notify({
        heading: "Ændringerne blev gemt",
        text: "Udstyret er nu rettet.",
      });

      return true;
    } else {
      notify({
        heading: "Ændringerne kunne ikke gemmes",
        text: "Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  const deleteEquipment = async (id: EquipmentId): Promise<boolean> => {
    const success = await deleteRequest("equipment", { id }, { companyId });
    if (success) {
      mutate("api/equipment");
      notify({
        heading: "Udstyret blev slettet",
        text: "Udstyret er nu permanent slettet.",
      });

      return true;
    } else {
      notify({
        heading: "Udstyret kunne ikke slettes",
        text: "Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  const updateNote = async (note: NoteChangeSet): Promise<boolean> => {
    const success = await patch("equipment/note", note, { companyId });
    if (success) {
      mutate("api/equipment");
      notify({
        heading: `Noten blev opdateret`,
        text: `Noten vises nu ${note.archived ? "ikke" : ""}`,
      });

      return true;
    } else {
      notify({
        heading: `Noten kunne ikke opdateres`,
        text: "Noget gik galt. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  const equipment = !data ? [] : (data.equipment as Equipment[]);
  const loading = !data && !error;
  const err = !data && error;

  return {
    loading,
    equipment,
    createEquipment,
    updateEquipment,
    deleteEquipment,
    updateNote,
    error: err,
  };
}

export interface Equipment {
  name: string; // display only
  make: string;
  model: string;
  equipmentId: string;
  location: string;
  category: string;
  standby: boolean;
  archived: boolean;

  notes: OriginalNote[];
  tasks: TaskStub[];

  id: EquipmentId;
}

export type EquipmentId = string;

export interface EquipmentCreateSet {
  make: string;
  model: string;
  equipmentId: string;
  category: string;
  location: string;
}

export interface EquipmentChangeSet {
  make?: string;
  model?: string;
  equipmentId?: string;
  category?: string;
  location?: string;
  archived?: boolean;
  standby?: boolean;
  id: EquipmentId;
}

export type URL = string;

export interface NoteCreateSet {
  content: string;
  equipmentId: EquipmentId;
  core?: boolean;
  files?: URL[];
  recipientIds: string[];
}

export interface NoteChangeSet {
  content?: string;
  core?: boolean;
  archived?: boolean;
  files?: URL[];

  id: string;
}

export interface TaskStub {
  title: string;
  type: TaskType;
  interval: TaskInterval;
  responsibleName: string;
  status: TaskStatus;
  id: string;
}

export enum TaskType {
  CYCLICAL = "CYCLICAL",
  AD_HOC = "AD_HOC",
}

export enum TaskInterval {
  DAILY = "DAILY",
  DAILY_WEEKDAYS = "DAILY_WEEKDAYS",
  WEEKLY = "WEEKLY",
  BI_WEEKLY = "BI_WEEKLY",
  MONTHLY = "MONTHLY",
  BI_MONTHLY = "BI_MONTHLY",
  QUARTERLY = "QUARTERLY",
  TRIANNUAL = "TRIANNUAL",
  HALF_YEARLY = "HALF_YEARLY",
  YEARLY = "YEARLY",
  SEMI_YEARLY = "SEMI_YEARLY", // INCORRECT GRAMMAR; means once every 2 years; was an idiot in 2017
}

export enum TaskStatus {
  ACTIVE = "ACTIVE",
  OVERDUE = "OVERDUE",
  CURRENT = "CURRENT",
  STANDBY = "STANDBY",
  ARCHIVED = "ARCHIVED",
}
