import axios from "axios";
import { mutate } from "swr";
import { BASE_URL } from "../../../constants";
import { setAuthorizationHeader } from "../../utils/setAuthorizationHeader";
import { setAuthTokenKeyInLocalStorage } from "../../utils/setAuthTokenKeyInLocalStorage";

export const loginWithAuthCode = async (authCode: string) => {
  try {
    const result = await axios.post(`${BASE_URL}/user/mfa`, {
      authCode,
    });

    if (result.status === 200) {
      const { user, token } = result.data;

      setAuthorizationHeader(token);
      setAuthTokenKeyInLocalStorage(token);

      mutate("api/user", user, true);

      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};
