import { OriginalNote } from "../../../data/notes/types";
import { formatDate } from "../../../utils/formatters/formatDate";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import da from "date-fns/locale/da";
import Badge from "../../badge";
import { TaskLink } from "./taskLink";
import { FileList } from "./fileList";
import { highlightMatched } from "../../highlightMatched";
import { Avatar } from "../../avatar";

type HeaderAndContentProps = {
  data: {
    content: string;
    author: { name: string; id?: string };
    datetime: Date;
    files?: string[];
    task?: OriginalNote["task"];
    archived?: boolean;
    core?: boolean;
  };
  query?: string;
  showTaskLink?: boolean;
};

export const HeaderAndContent = ({
  data,
  query,
  showTaskLink = true,
}: HeaderAndContentProps) => {
  const { author, datetime, core, archived, content, task, files } = data;
  return (
    <div className="p-4 flex flex-col gap-2">
      {/* Header */}
      <div className="flex justify-between">
        <div className="flex items-center">
          <Avatar name={author.name} className="mr-3 flex-shrink-0" />
          <div>
            <p className="font-medium text-sm text-gray-900 truncate">
              {author.name}
            </p>
            <p className="text-xs tracking-wide text-gray-500 truncate whitespace-pre-wrap">
              {formatDate(datetime)} &middot; for{" "}
              {formatDistanceToNow(new Date(datetime), { locale: da })} siden
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {core ? <Badge type="gray">Stamnote</Badge> : null}
          {archived ? <Badge type="yellow">Arkiveret</Badge> : null}
        </div>
      </div>
      {/* Content */}
      <p className="">{highlightMatched({ str: content, toMatch: query })}</p>
      {showTaskLink && <TaskLink task={task} />}
      <FileList files={files} />
    </div>
  );
};
