import React, { ReactNode } from "react";
import { Link as RRLink } from "react-router-dom";

const Link = (props: {
  to: string;
  className?: string;
  children: ReactNode;
}) => {
  return (
    <RRLink
      to={props.to}
      className={`text-blue-700 underline ${props.className}`}
    >
      {props.children}
    </RRLink>
  );
};

export default Link;
