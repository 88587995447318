import {
  Button,
  DashboardPages,
  DatePickerFieldset,
  Form,
  FormHeading,
  FormSection,
  SelectFieldset,
  SelectOption,
  TextFieldset,
  Upload,
  ZapIcon,
  File,
  FieldLabel,
} from "@onlion/components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  intervals,
  intervalToString,
} from "../../../../components/intervalToString";
import useTasks, { Task, TaskInterval } from "../../../../data/useTasks";
import { useBreadcrumbs } from "../../../../state/useBreadcrumbs";
import { uploadFiles } from "../../../../utils/uploadFiles";
import endOfDay from "date-fns/endOfDay";
import useUsers from "../../../../data/users/useUsers";

export const TaskUpdatePage = () => {
  const { id, companyId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [responsibleId, setResponsibleId] = useState<SelectOption | null>(null);
  const [deadline, setDeadline] = useState<Date>(new Date());
  const [interval, setInterval] = useState<SelectOption | null>(null);
  const [notificationPeriod, setNotificationPeriod] =
    useState<SelectOption | null>(null);
  const [files, setFiles] = useState<File[]>([]);
  const [existingFiles, setExistingFiles] = useState<string[]>([]);
  const { replaceBreadcrumbs } = useBreadcrumbs();
  const { updateTask, tasks } = useTasks();
  const [focusedTask, setFocusedTask] = useState<Task>();
  const { users } = useUsers();

  useEffect(
    () =>
      replaceBreadcrumbs([
        {
          text: "Opgaver",
          link: `/dashboard/${companyId}/tasks`,
        },
        {
          text: "Tilføj",
        },
      ]),
    [replaceBreadcrumbs]
  );

  useEffect(() => {
    if (tasks) {
      const focused = tasks.find((t) => t.id === id);
      if (focused) {
        setFocusedTask(focused);
        setTitle(focused.title);
        setDescription(focused.description);
        setResponsibleId({
          label: focused.responsible.name,
          value: focused.responsible.id,
        });
        setDeadline(new Date(focused.deadline));
        if (focused.interval) {
          setInterval({
            label: intervalToString(focused.interval),
            value: focused.interval as string,
          });
        }
        setNotificationPeriod({
          label: mapNotificationPeriodToString(focused.notificationPeriod),
          value: focused.notificationPeriod,
        });

        const focusedExistingFiles = focused.files
          ? Array.isArray(focused.files)
            ? focused.files
            : [focused.files]
          : null;
        setExistingFiles(focusedExistingFiles ?? []);
      }
    }
  }, [tasks, id]);

  if (!id || !focusedTask || !tasks || !users) return null;

  const handleSubmit = async () => {
    setLoading(true);
    const fileStrings = await uploadFiles(files);
    const rId = responsibleId ? (responsibleId.value as string) : "";
    const typedInterval =
      focusedTask.type === "AD_HOC"
        ? undefined
        : interval
        ? (interval.value as TaskInterval)
        : "DAILY";
    const nPeriod = notificationPeriod
      ? (notificationPeriod.value as number)
      : 0;

    const success = await updateTask({
      title,
      description,
      responsibleId: rId,
      deadline: endOfDay(deadline),
      files: Array.isArray(existingFiles)
        ? [...fileStrings, ...existingFiles]
        : fileStrings,
      interval: typedInterval,
      notificationPeriod: nPeriod,
      type: "CYCLICAL",
      id,
    });

    setLoading(false);

    if (success) navigate(`/dashboard/${companyId}/tasks/${id}`);
  };

  return (
    <div className="p-2 sm:p-8 bg-gray-50 min-h-screen">
      <DashboardPages.Form
        title={`Ret opgaven ${focusedTask?.title}`}
        className="mb-20 md:mb-40"
      >
        <Form onSubmit={() => {}}>
          <FormHeading>
            <ZapIcon className="w-8 h-8 mr-2" /> Ret opgaven{" "}
            {focusedTask?.title}
          </FormHeading>

          <FormSection
            heading="Om opgaven"
            info={
              <>
                <p className="mb-2">
                  Skriv en titel, og en passende beskrivelse til opgaven.
                </p>
                <p className="mb-2">
                  En god beskrivelse øger chancerne for at opgaven bliver udført
                  rigtigt.
                </p>
                <p>
                  Upload en pdf-fil eller et billede hvis en tekstbeskrivelse
                  ikke er dækkende.
                </p>
              </>
            }
          >
            <TextFieldset
              id={"title"}
              required
              valid={title.length > 0}
              label="Titel"
              placeholder="Titel"
              value={title}
              onChange={setTitle}
              autoFocus
            />

            <TextFieldset
              id={"description"}
              required
              valid={description.length > 0}
              label="Beskrivelse"
              placeholder="Beskrivelse"
              rows={4}
              value={description}
              onChange={setDescription}
            />

            <Upload
              label="Filer (valgfrit)"
              files={files}
              setFiles={setFiles}
            />

            {Array.isArray(existingFiles) && existingFiles.length > 0 && (
              <div className="space-y-1.5">
                <FieldLabel htmlFor={"existing files"}>
                  Eksisterende filer
                </FieldLabel>
                {existingFiles.map((ef, i) => (
                  <File
                    fileId={ef}
                    deleteFunc={() =>
                      setExistingFiles(removeAtIndex(existingFiles, i))
                    }
                  />
                ))}
              </div>
            )}
          </FormSection>

          <FormSection
            heading="Ansvarlig"
            info="Hvem er ansvarlig for at opgaven bliver udført, og kvitteret for?"
          >
            <SelectFieldset
              id="responsibleId"
              required
              valid={responsibleId !== null}
              selected={responsibleId}
              label="Vælg ansvarlig"
              onChange={setResponsibleId}
              options={users.map((r) => ({
                label: r.name,
                value: r.id,
              }))}
            />
          </FormSection>

          <FormSection
            heading={"Udførsel"}
            info={"Vælg hvor ofte og hvornår opgaven skal udføres."}
          >
            {focusedTask.type === "CYCLICAL" && (
              <SelectFieldset
                id="interval"
                required
                valid={interval !== null}
                help="Hvor ofte skal opgaven udføres?"
                selected={interval}
                label="Vælg interval"
                onChange={setInterval}
                options={intervals.map((i) => ({
                  label: intervalToString(i),
                  value: i,
                }))}
              />
            )}

            <DatePickerFieldset
              id="deadline"
              valid={true}
              label={
                focusedTask.type === "CYCLICAL" ? "Næste deadline" : "Deadline"
              }
              help={
                focusedTask.type === "CYCLICAL"
                  ? "Hvornår skal opgaven udføres næste gang?"
                  : "Hvornår skal opgaven udføres?"
              }
              value={deadline}
              onChange={setDeadline}
              options={{ onlyFuture: true }}
            />
            <SelectFieldset
              id="notificationPeriod"
              required
              valid={notificationPeriod !== null}
              selected={notificationPeriod}
              label="Påmindelses periode"
              help="Hvor længe inden deadline skal påmindelser sendes?"
              onChange={setNotificationPeriod}
              options={mapIntervalToNotificationPeriods(interval?.value)}
            />
          </FormSection>

          <div
            className={`flex flex-row justify-end items-center border-t 
        max-w-full md:max-w-none bg-gray-50 md:px-8 py-6 md:-mx-8 md:-mb-12 rounded-b-lg`}
          >
            <Button
              className="mr-4"
              onClick={() => navigate(`/dashboard/${companyId}/tasks/${id}`)}
            >
              Fortryd
            </Button>
            <Button
              disabled={
                title.length === 0 ||
                description.length === 0 ||
                responsibleId === null ||
                (focusedTask.type === "CYCLICAL" && interval === null) ||
                notificationPeriod === null
              }
              type="primary"
              htmlType="submit"
              onClick={() => handleSubmit()}
              loading={loading}
            >
              Gem rettelser
            </Button>
          </div>
        </Form>
      </DashboardPages.Form>
    </div>
  );
};

function mapIntervalToNotificationPeriods(interval: any): SelectOption[] {
  const onDay = { label: "På dagen for deadline", value: 0 };
  const oneDay = { label: "Dagen før deadline", value: 1 };
  const twoDays = { label: "2 dage før deadline", value: 2 };
  const oneWeek = { label: "En uge før deadline", value: 7 };
  const twoWeeks = { label: "To uger før deadline", value: 14 };
  const oneMonth = {
    label: "En måned før deadline",
    value: 30,
  };

  if (interval === null) return [onDay];

  switch (interval) {
    case "DAILY":
      return [onDay];
    case "DAILY_WEEKDAYS":
      return [onDay];
    case "WEEKLY":
      return [onDay, oneDay, twoDays];
    case "BI_WEEKLY":
      return [onDay, oneDay, twoDays, oneWeek];
    case "MONTHLY":
      return [onDay, oneDay, twoDays, oneWeek];
    case "BI_MONTHLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "QUARTERLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "TRIANNUAL":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "HALF_YEARLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "YEARLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "SEMI_YEARLY":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "EVERY_3_YEARS":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "EVERY_4_YEARS":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "EVERY_5_YEARS":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    case "EVERY_8_YEARS":
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
    default:
      return [onDay, oneDay, twoDays, oneWeek, twoWeeks, oneMonth];
  }
}

function mapNotificationPeriodToString(np: number): string {
  switch (np) {
    case 0:
      return "På dagen for deadline";
    case 1:
      return "Dagen før deadline";
    case 2:
      return "2 dage før deadline";
    case 7:
      return "En uge før deadline";
    case 14:
      return "To uger før deadline";
    case 30:
      return "En måned før deadline";
    default:
      return "På dagen for deadline";
  }
}

function removeAtIndex(arr: any[], removeIdx: number) {
  return arr.filter((_f, i) => i !== removeIdx);
}
