import { FunctionComponent, useEffect } from "react";
import useEquipment, { Equipment } from "../../../../../data/useEquipment";
import { useNavigate, useParams } from "react-router-dom";
import {
  EquipmentIcon,
  Input,
  SearchIcon,
  Popover,
  FilterIcon,
  Page,
} from "@onlion/components";
import { useBreadcrumbs } from "../../../../../state/useBreadcrumbs";
import { LoadingComponent } from "../../../../../components/loading/loadingComponent";
import { Empty } from "../../../../../components/empty/empty";
import { useTable } from "../../../../../components/useTable";
import { NoData } from "../../../../components/noData";
import { highlightMatched } from "../../../../../components/highlightMatched";
import { Filters } from "./components/filters";
import { Table } from "../../../../../components/table";
import useUser from "../../../../../data/useUser";

type TableEquipment = Equipment & { noOfTasks: number; noOfNotes: number };

const EquipmentTablePage: FunctionComponent = () => {
  const { replaceBreadcrumbs } = useBreadcrumbs();
  useEffect(() => replaceBreadcrumbs([{ text: "Udstyr" }]), []);
  const { user } = useUser();
  const { companyId } = useParams();
  const type = user && companyId ? user.roles[companyId]?.type : null;
  const isAdmin = type && ["ADMIN", "SUPER_ADMIN"].includes(type);

  const navigate = useNavigate();

  const { equipment, loading } = useEquipment();

  const {
    data,
    query,
    setQuery,
    closestQueryMatch,
    sorting,
    sort,
    activeFilterCount,
    getFilterValue,
    setFilterValue,
    clearFilters,
  } = useTable<Equipment, TableEquipment>(equipment, "equipment", {
    searchKeys: ["make", "model", "equipmentId"],
    addKV: (e) => ({
      ...e,
      noOfTasks: e.tasks.length,
      noOfNotes: e.notes.length,
    }),
    filters: {
      location: {
        func: (eq, v) => eq.location === v || v === null,
      },
      category: {
        func: (eq, v) => eq.category === v || v === null,
      },
      make: {
        func: (eq, v) => eq.make === v || v === null,
      },
    },
  });

  if (loading) return <LoadingComponent />;

  if (!data) return null;

  if (equipment.length === 0)
    return (
      <Empty
        title={isAdmin ? "Tilføj et udstyr" : "Endnu intet udstyr"}
        description={
          isAdmin
            ? "Du kan tilføje op til 10 stykker udstyr gratis"
            : "Kom tilbage til denne side senere"
        }
        action={
          isAdmin
            ? { text: "Tilføj et udstyr", link: "equipment/create" }
            : undefined
        }
        icon={(className: string) => <EquipmentIcon className={className} />}
      />
    );

  return (
    <Page
      headingProps={{
        title: "Udstyr",
        renderActions: (
          <div className="flex mt-4 md:mt-0 items-center space-x-2 md:space-x-4">
            <Popover
              buttonProps={{
                count: activeFilterCount,
              }}
              panelZIndex={10}
              offsetY={6}
              className="space-y-4"
              panelChildren={
                <Filters
                  equipment={equipment}
                  get={getFilterValue}
                  set={setFilterValue}
                  clear={clearFilters}
                />
              }
            >
              <FilterIcon className="w-4 h-4 mr-2" /> Filtre
            </Popover>
            <Input
              value={query !== "undefined" ? query : ""}
              autoFocus={true}
              onChange={setQuery}
              id="equipment/search"
              placeholder="Søg i udstyr"
              autoComplete="off"
              short
              clearable
              Icon={SearchIcon}
            />
          </div>
        ),
      }}
    >
      <Table
        className="mx-4"
        head={[
          { content: "Udstyr", key: "make", onClick: () => sort("make") },
          { content: "Lokation" },
          { content: "Kategori", hideMobile: false, right: true },
          {
            content: "Antal opgaver",
            right: true,
            key: "noOfTasks",
            onClick: () => sort("noOfTasks"),
          },
        ]}
        row={{
          onClick: ({ id }) => navigate(id),
          fields: [
            {
              renderData: ({ name, equipmentId }) => (
                <>
                  {highlightMatched({ str: name, toMatch: query })}
                  <p className="text-xs font-normal text-gray-500">
                    {highlightMatched({ str: equipmentId, toMatch: query })}
                  </p>
                </>
              ),
            },
            { renderData: ({ location }) => location },
            {
              renderData: ({ category }) => category,
              hideMobile: false,
              right: true,
            },
            { renderData: ({ noOfTasks }) => noOfTasks, right: true },
          ],
        }}
        sorting={sorting ?? { key: "", ascending: true }}
        data={data}
      />

      <NoData show={data.length === 0} className="text-lg">
        <EquipmentIcon className="w-4 h-4 mr-2" /> Vi kunne desværre ikke finde
        noget udstyr som matcher{" "}
        {activeFilterCount && activeFilterCount > 0 && "dine filtre"}{" "}
        {query.length > 0 && activeFilterCount && "eller"}{" "}
        {query.length > 0 && (
          <>
            {" "}
            '{query}'.{" "}
            {closestQueryMatch ? (
              <p className="ml-2">
                Mente du{" "}
                <span
                  className="underline cursor-pointer"
                  onClick={() => setQuery(closestQueryMatch)}
                >
                  '{closestQueryMatch}'
                </span>
                ?
              </p>
            ) : null}
          </>
        )}
      </NoData>
    </Page>
  );
};
export default EquipmentTablePage;
