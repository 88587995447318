import { useEffect } from "react";
import useUser, { logOut } from "../../../data/useUser";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Button } from "../../../components/button";
import { LogOutIcon } from "../../../components/icons/logOut";
import { NavLink } from "react-router-dom";
import { CompaniesSection } from "./companiesSection";
import { InvitationSection } from "./invitationSection";
import { useInvitations } from "../../../data/useInvitations";
import { SettingsSection } from "./settingsSection";
import Notifications from "../../../dashboard/components/notifications/notifications";

export const ProfilePage = () => {
  const { invitations } = useInvitations({});
  const navigate = useNavigate();
  const { user, loading, loggedOut } = useUser();

  useEffect(() => {
    if (loggedOut) {
      navigate("/auth");
    }
  }, [loggedOut, navigate]);

  if (loading) return <div>Henter bruger...</div>;

  if (!user) return null;

  return (
    <>
      <Notifications />

      <div className="px-1 py-2 shadow border-b">
        <div className="flex flex-shrink-0 items-center px-2.5 font-semibold text-gray-500">
          <div className="w-3 mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.43 216.93">
              <path
                d="M111.43 116.99v-16.47a8.5 8.5 0 0 0-8.5-8.5h-5.31a4.24 4.24 0 0 1-3.68-2.13l-2.69-4.65c-.76-1.32-.76-2.94 0-4.25l2.82-4.89c2.35-4.07.95-9.27-3.11-11.62L76.7 56.24c-4.07-2.35-9.27-.95-11.62 3.11l-2.66 4.6a4.24 4.24 0 0 1-3.68 2.13h-5.37a4.24 4.24 0 0 1-3.68-2.13l-2.82-4.89c-2.35-4.07-7.55-5.46-11.62-3.11l-14.26 8.24c-4.07 2.35-5.46 7.55-3.11 11.62l2.66 4.6c.76 1.32.76 2.94 0 4.25l-2.69 4.65a4.24 4.24 0 0 1-3.68 2.13H8.53C3.81 91.44 0 95.25 0 99.95v16.47a8.5 8.5 0 0 0 8.5 8.5h5.31a4.24 4.24 0 0 1 3.68 2.13l2.69 4.65c.76 1.32.76 2.94 0 4.25l-2.82 4.89c-2.35 4.07-.95 9.27 3.11 11.62l14.26 8.24c4.07 2.35 9.27.95 11.62-3.11l2.66-4.6a4.24 4.24 0 0 1 3.68-2.13h5.37a4.24 4.24 0 0 1 3.68 2.13l2.82 4.89c2.35 4.07 7.55 5.46 11.62 3.11l14.26-8.24c4.07-2.35 5.46-7.55 3.11-11.62l-2.66-4.6c-.76-1.32-.76-2.94 0-4.25l2.69-4.65a4.24 4.24 0 0 1 3.68-2.13h5.64a8.5 8.5 0 0 0 8.5-8.5zm-53.64 16.96c-15.75 1.24-28.8-11.8-27.56-27.56.98-12.39 11.02-22.43 23.41-23.41 15.75-1.24 28.8 11.8 27.56 27.56-.98 12.39-11.02 22.43-23.41 23.41z"
                fill="#1e3a8a"
              />
              <path
                d="M91.54 30.23l16.28-21.67c3.55-4.72-2.09-10.84-7.09-7.7L67.74 21.65l-12.26 7.72c-3.04 1.92-2.95 6.38.16 8.17l40.33 23.29c2.86 1.65 6.51.67 8.16-2.19l3.93-6.81c1.65-2.86.67-6.51-2.19-8.16l-13.15-7.59a4 4 0 0 1-1.2-5.85zM19.89 186.7L3.61 208.37c-3.55 4.72 2.09 10.84 7.09 7.7l32.99-20.79 12.26-7.72c3.04-1.92 2.95-6.38-.16-8.17L15.46 156.1c-2.86-1.65-6.51-.67-8.16 2.19l-3.93 6.81c-1.65 2.86-.67 6.51 2.19 8.16l13.15 7.59a4 4 0 0 1 1.2 5.85z"
                fill="#feae01"
              />
            </svg>
          </div>
          <span className="font-semibold text-blue-900">
            Tela <span className="font-normal">Vedligehold</span>
          </span>
        </div>
      </div>

      <div className="bg-blue-50 w-full min-h-screen p-4">
        <div className="bg-white rounded-md shadow-md m-auto p-6 max-w-full sm:max-w-6xl">
          <div className="flex flex-col sm:flex-row gap-2 justify-between align-start">
            <div>
              <h1 className="text-2xl font-bold text-blue-900">{user.name}</h1>
              <div>{user.emailAddress}</div>
            </div>

            <div>
              <Button onClick={logOut} icon={{ trailing: LogOutIcon }}>
                Log ud
              </Button>
            </div>
          </div>

          <div className="flex gap-3 border-b-2">
            {/* TABS */}
            <NavLink
              className={({ isActive }) =>
                `mt-4 px-3 py-1 border-b-2 border-transparent text-md ${
                  isActive
                    ? "border-blue-700 font-medium"
                    : "cursor-pointer hover:border-blue-400"
                }`
              }
              to=""
              end
            >
              Tilknyttede Konti
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `mt-4 px-3 py-1 border-b-2 border-transparent text-md ${
                  isActive
                    ? "border-blue-700 font-medium"
                    : "cursor-pointer hover:border-blue-400"
                }`
              }
              to="invitations"
            >
              <>
                <span>Invitationer</span>
                {invitations.length > 0 && (
                  <span className="ml-1.5 px-2 font-medium text-xs py-0.5 bg-red-600 rounded-full text-white">
                    {invitations.length}
                  </span>
                )}
              </>
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                `mt-4 px-3 py-1 border-b-2 border-transparent text-md ${
                  isActive
                    ? "border-blue-700 font-medium"
                    : "cursor-pointer hover:border-blue-400"
                }`
              }
              to="settings"
            >
              Indstillinger
            </NavLink>
          </div>

          <Routes>
            <Route path="" element={<CompaniesSection />} />
            <Route path="invitations" element={<InvitationSection />} />
            <Route path="settings" element={<SettingsSection />} />
          </Routes>
        </div>
      </div>
    </>
  );
};
