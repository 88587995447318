import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Checkbox,
  CheckCircleIcon,
  DashboardPages,
  Form,
  FormHeading,
  FormSection,
  ShieldIcon,
  TextFieldset,
} from "@onlion/components";

import { is_email } from "@onlion/utils";
import signUp from "./signUp.action";
import { FormActionBar } from "../../../components/formActionBar";
import { notifyAuth } from "../../../dashboard/components/notifications/newNotification"; //

const SignUpPage = () => {
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [mfa, setMFA] = useState(false);
  const [tos, setTos] = useState(true);
  const [loading, set_loading] = useState(false);

  const handleSubmit = async () => {
    set_loading(true);
    const success = await signUp({
      companyName,
      name,
      emailAddress,
      password,
      mfa,
    });
    set_loading(false);

    if (!success) {
      notifyAuth({
        heading: "Emailadressen er allerede i brug.",
        text: "Prøv en anden emailadresse, nulstil password, eller log ind",
        duration: 10000,
      });
    }
  };
  return (
    <DashboardPages.Form title="Opret ny Tela-vedligehold konto">
      <Form onSubmit={() => {}}>
        <FormHeading>
          <ShieldIcon className="w-8 h-8 mr-2" /> Opret ny gratis Tela
          Vedligehold konto
        </FormHeading>

        <FormSection
          heading="Virksomheden"
          info="Skriv din virksomheds navn. Dette kan ændres senere."
        >
          <TextFieldset
            id={"companyName"}
            label="Virksomhedens navn"
            placeholder="Virksomhedens navn"
            value={companyName}
            onChange={setCompanyName}
            required
            valid={companyName.length > 1}
            autoFocus
          />
        </FormSection>

        <FormSection
          heading="Dig som administrator"
          info={
            <>
              <p className="mb-4">Skriv dit navn, og arbejds-email</p>
            </>
          }
        >
          <TextFieldset
            id={"name"}
            label="Dit navn"
            placeholder="Navn"
            value={name}
            onChange={setName}
            required
            valid={name.length > 1}
          />
          <TextFieldset
            id={"emailAddress"}
            label="Din emailadresse"
            placeholder="Emailadresse"
            autoComplete="email"
            value={emailAddress}
            valid={is_email(emailAddress)}
            required
            onChange={(v) => setEmailAddress(v.toLowerCase())}
          />
        </FormSection>

        <FormSection
          heading="Sikkerhed"
          info={
            <>
              <p>
                Vælg et godt password, som er mindst 10 tegn langt og svært at
                gætte
              </p>
              <p>
                Hvis du aktiverer to-trins log ind, skal du klikke på et link,
                som du får tilsendt per email, når du logger ind. Dette øger
                sikkerheden på din konto
              </p>
            </>
          }
        >
          <TextFieldset
            id={"password"}
            label="Password"
            placeholder="Password"
            value={password}
            valid={password.length >= 10}
            onChange={setPassword}
            autoComplete="new-password"
            required
            type="password"
            help="Skal være mindst 10 tegn langt. Bemærk at der skelnes mellem store og små bogstaver."
          />
          <Checkbox
            label="Aktiver to-trins log-ind (MFA/2-FA)"
            value={mfa}
            onChange={setMFA}
          />
        </FormSection>

        <FormSection
          heading="Betingelser"
          info={
            <p>
              For at tilmelde dig skal du acceptere vores{" "}
              <a
                className="text-blue-500"
                target="_blank"
                href="https://s3.eu-central-1.amazonaws.com/onlion-internal-docs/Tela_Vedligehold_Betingelser.pdf"
              >
                betingelser
              </a>
              .
            </p>
          }
        >
          <Checkbox
            label="Jeg accepterer Tela Vedligeholds betingelser"
            value={tos}
            onChange={setTos}
          />
        </FormSection>

        <FormActionBar>
          <Button className="mr-4" onClick={() => navigate("/auth")}>
            Fortryd
          </Button>
          <Button
            disabled={
              password.length < 10 ||
              companyName.length === 0 ||
              name.length === 0 ||
              !is_email(emailAddress) ||
              !tos
            }
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            <CheckCircleIcon className="w-4 h-4 mr-2" /> Opret ny konto
          </Button>
        </FormActionBar>
      </Form>
    </DashboardPages.Form>
  );
};

export default SignUpPage;
