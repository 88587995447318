import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Badge, Page } from "@onlion/components";
import { Avatar } from "../../../../components/avatar";
import { useBreadcrumbs } from "../../../../state/useBreadcrumbs";
import useUsers from "../../../../data/users/useUsers";
import { getUserMeta } from "../utils/getUserMeta";
import { Table } from "../../../../components/table";
import { useCompanyIdParam } from "../../../../components/hooks";
import { Task } from "../../../../data/useTasks";
import { useTaskQuery } from "../../../../data/useTaskQuery";
import { Button } from "../../../../components/button";
import Modal from "../../../../components/modal";
import { ModalContent } from "../../../../components/modalContent";
import { useInvitations } from "../../../../data/useInvitations";
import { Empty } from "../../../../components/empty/empty";
import { XIcon } from "../../../../components/icons/xIcon";
import Link from "../../../../components/Link/Link";
import { useDeleteInvitation } from "../../../../data/useDeleteInvitation";

export const UsersTablePage: FunctionComponent = () => {
  const { replaceBreadcrumbs } = useBreadcrumbs();
  const [showInvitationsModal, setShowInvitationsModal] =
    useState<boolean>(false);
  useEffect(
    () => replaceBreadcrumbs([{ text: "Administratorer" }]),
    [replaceBreadcrumbs]
  );

  const navigate = useNavigate();
  const { users, loading } = useUsers();
  const companyId = useCompanyIdParam();
  const { tasks } = useTaskQuery({});
  const { invitations, loading: invitationsLoading } = useInvitations({
    companyId,
  });

  const { deleteInvitation } = useDeleteInvitation();

  return (
    <Page
      className="h-screen min-h-screen"
      headingProps={{
        title: "Brugere",
        renderActions: (
          <>
            <Button
              count={invitations.length > 0 ? invitations.length : undefined}
              onClick={() => setShowInvitationsModal(!showInvitationsModal)}
              loading={invitationsLoading}
            >
              Vis invitationer
            </Button>
          </>
        ),
      }}
      loading={{
        title: "Henter brugere",
        text: "Dette bør kun tage få sekunder",
        isLoading: loading,
      }}
    >
      <Table
        className="mx-4"
        head={[
          { content: "Navn og emailadresse" },
          { content: "Antal opgaver", right: true },
          { content: "Rolle", right: true },
        ]}
        row={{
          onClick: ({ id }) => navigate(id),
          fields: [
            {
              renderData: ({ name, emailAddress }) => (
                <div className="flex items-center">
                  <Avatar name={name} className="mr-3" />
                  <div>
                    {name}
                    <p className="text-xs font-normal text-gray-500">
                      {emailAddress}
                    </p>
                  </div>
                </div>
              ),
            },
            {
              right: true,
              renderData: ({ id }) => countTasksByUserId(tasks, id),
            },
            {
              right: true,
              renderData: (user) => (
                <Badge
                  className="ml-auto"
                  type={getUserMeta(user, companyId).color}
                >
                  {getUserMeta(user, companyId).role}
                </Badge>
              ),
            },
          ],
        }}
        data={users ? users : []}
      />

      <Modal
        isOpen={showInvitationsModal}
        setIsOpen={setShowInvitationsModal}
        className="min-w-full"
      >
        <div>
          <h2 className="text-2xl text-blue-900 font-semibold">Invitationer</h2>
          <Link to="create" className="text-sm">
            Invitér ny bruger &rarr;
          </Link>
        </div>

        <Table
          errorMessage="Ingen udestående invitationer lige nu"
          className="mt-4"
          head={[
            { content: "Navn" },
            { content: "Emailadresse", hideMobile: true },
            { content: "Udløber", hideMobile: true },
            { content: "", right: true, hideMobile: false },
          ]}
          row={{
            fields: [
              { renderData: ({ name }) => name },
              {
                renderData: ({ emailAddress }) => emailAddress,
                hideMobile: true,
              },
              {
                renderData: ({ date }) => addDaysAndFormat(date, 14),
                hideMobile: true,
              },
              {
                renderData: ({ token }) => (
                  <Button onClick={() => deleteInvitation(token)}>Slet</Button>
                ),
                hideMobile: false,
              },
            ],
          }}
          data={invitations.map((invitation, index) => ({
            ...invitation,
            id: index.toString(),
          }))}
        />
      </Modal>
    </Page>
  );
};

const countTasksByUserId = (tasks: Task[], userId: string) =>
  tasks.filter((t) => t.responsible.id === userId).length;

const addDaysAndFormat = (date: Date, days: number): string => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);

  let options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };
  return result.toLocaleDateString("da-DK", options);
};
