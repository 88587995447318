import {
  Button,
  CheckCircleIcon,
  DashboardPages,
  Form,
  FormHeading,
  FormSection,
  SelectFieldset,
  SelectOption,
  TextFieldset,
  UserIcon,
  UserPlusIcon,
} from "@onlion/components";
import { is_email } from "@onlion/utils";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormActionBar } from "../../../../components/formActionBar";
import { useInviteUser } from "../../../../data/users/useInviteUser";
import { useBreadcrumbs } from "../../../../state/useBreadcrumbs";
import { UserType } from "../../../../data/users/types";

export const UsersCreatePage = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [type, setType] = useState<UserType>("USER");
  const { replaceBreadcrumbs } = useBreadcrumbs();
  useEffect(
    () =>
      replaceBreadcrumbs([
        {
          text: "Brugere",
          link: `/dashboard/${companyId}/users`,
        },
        {
          text: "Inviter",
        },
      ]),
    []
  );

  const { inviteUser, loading } = useInviteUser();

  const handleSubmit = async () => {
    const success = await inviteUser({ name, emailAddress, type: type });
    if (success) navigate(`/dashboard/${companyId}/users`);
  };

  const roles: SelectOption[] = [
    { label: "Almindelig bruger", value: "USER" },
    { label: "Administrator", value: "ADMIN" },
  ];

  return (
    <div className="p-2 sm:p-8 bg-gray-200 min-h-screen">
      <DashboardPages.Form title="Tilføj ny bruger">
        <Form onSubmit={() => {}}>
          <FormHeading>
            <UserPlusIcon className="w-8 h-8 mr-2" /> Inviter ny bruger
          </FormHeading>

          <FormSection
            heading="Bruger"
            info={
              <>
                <p>
                  Vi sender den nye bruger en email, med instruktioner til at
                  logge ind og vælge et password.
                </p>
                <p>
                  OBS: Brugeren skal acceptere din invitation, før du kan gøre
                  dem ansvarlige for opgaver, mm.
                </p>
                <details className="leading-6">
                  <summary className="underline text-blue-800">
                    Lær mere om fler-konti brugere
                  </summary>
                  En bruger kan være en del af flere konti, såfremt alle konti
                  brugeren er en del af, har ubegrænset abonnement.
                </details>
              </>
            }
          >
            <TextFieldset
              id="name"
              label="Navn"
              placeholder="Navn"
              value={name}
              onChange={setName}
              autoComplete="off"
              autoFocus
              required
            />
            <TextFieldset
              id="emailAddress"
              label="Emailadresse"
              placeholder="Emailadresse"
              value={emailAddress.toLowerCase()}
              onChange={setEmailAddress}
              required
            />
            <SelectFieldset
              required
              id="role"
              label="Rolle"
              help="Administratorer kan tilføje, rette og slette udstyr, opgaver mm."
              placeholder="Vælg rolle"
              onChange={(v) => setType(v.value as UserType)}
              options={roles}
              selected={{
                label: roleLabel(type),
                value: type,
              }}
            />
          </FormSection>

          <FormActionBar>
            <Button
              className="mr-4"
              onClick={() => navigate(`/dashboard/${companyId}/users`)}
            >
              Fortryd
            </Button>
            <Button
              type="primary"
              disabled={!is_email(emailAddress) || name.length === 0}
              onClick={handleSubmit}
              htmlType="submit"
              loading={loading}
            >
              <CheckCircleIcon className="w-4 h-4 mr-2" /> Inviter bruger
            </Button>
          </FormActionBar>
        </Form>
      </DashboardPages.Form>
    </div>
  );
};

const roleLabel = (role: UserType) =>
  role === "USER"
    ? "Almindelig bruger"
    : role === "ADMIN"
    ? "Administrator"
    : "Superadministrator";
