import {
  AlertTriangleIcon,
  Button,
  CheckCircleIcon,
  CheckIcon,
  ChevronRightIcon,
  Modal,
  RefreshcwIcon,
  VerifiedIcon,
} from "@onlion/components";
import { format_date } from "@onlion/utils";
import { useState } from "react";
import useCompany from "../../../../data/useCompany";
import { subYears } from "date-fns";

export const SubscriptionModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
}) => {
  const { company, update } = useCompany();
  const [loading, setLoading] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);

  const upgradeSubscription = async () => {
    setLoading(true);
    if (!company) return;
    const updated = await update({ id: company.id, subscription: "UNLIMITED" });
    setLoading(false);
    if (updated) {
      setShowReceipt(true);
    }
  };

  if (!company) return null;

  return (
    <Modal
      className="bg-gradient-to-tl from-blue-800 to-blue-900 text-white relative"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <div className="wave-pattern absolute w-60 h-20 -ml-20 -mt-14"></div>
      <div className="wave-pattern absolute w-60 h-20 bottom-0 right-0 -mr-10 -mb-10"></div>

      {/* UPGRADE  */}
      {company.subscription === "FREE" && (
        <div>
          <h2 className="text text-sky-200 mb-2 uppercase tracking-widest font-semibold">
            Opgradér abonnement
          </h2>
          <h1 className="text-2xl font-semibold flex flex-row items-center mb-4">
            <span className="font-normal mr-2">Tela Vedligehold</span>{" "}
            <span>Ubegrænset</span>
            <VerifiedIcon
              className="w-8 h-8 ml-4 text-sky-200"
              strokeWidth={2}
            />
          </h1>

          {/* BENEFITS */}
          <ul className="text-sm text-sky-200 mb-8">
            <li className="flex flex-row items-center mb-2">
              <CheckIcon className="w-4 h-4 mr-2" /> Ingen begrænsninger
            </li>
            <li className="flex flex-row items-center mb-2">
              <CheckIcon className="w-4 h-4 mr-2" /> 1 års abonnement
            </li>
            <li className="flex flex-row items-center mb-2">
              <CheckIcon className="w-4 h-4 mr-2" /> Ingen binding
            </li>
          </ul>

          {/* PRICING */}
          <div className="my-6">
            <p className="text-sky-100 text-opacity-75 uppercase text-xs font-semibold tracking-wide">
              Tela Vedligehold ubegrænset abonnement, 12 måneder
            </p>
            <p className="text-sky-100 font-semibold mb-4 flex flex-row items-center justify-between mt-2 border-b border-opacity-25 pb-1">
              <span>4.788 kr.</span>{" "}
              <span className="text-sm opacity-90">( 12 x 399 kr. )</span>
            </p>

            <p className="text-sky-100 text-opacity-75 uppercase text-xs font-semibold tracking-wide">
              Moms, 25%
            </p>
            <p className="text-sky-100 font-semibold mb-4 flex flex-row items-center justify-between mt-2 border-b border-opacity-25 pb-1">
              <span>1.197 kr.</span>{" "}
            </p>

            <p className="text-sky-100 text-opacity-75 uppercase text-xs font-semibold tracking-wide">
              Total
            </p>
            <p className="text-sky-100 font-semibold flex flex-row items-center justify-between mt-2 border-b border-opacity-25 pb-1">
              <span className={"border-b border-opacity-25 pb-1 block w-full"}>
                5.985 kr.
              </span>
            </p>
          </div>

          {/* TOS */}
          <p className="my-4 text-sm text-sky-200">
            Når du klikker opgrader, accepterer du prisen på abonnementet, og
            vil blive tilsendt en faktura
          </p>

          {/* CTA */}
          <div className="w-full flex flex-row mt-8">
            <Button
              className="min-w-full bg-blue-900 border border-opacity-20"
              type="primary"
              onClick={upgradeSubscription}
            >
              <CheckIcon className="w-4 h-4 mr-2" /> Opgrader abonnement
            </Button>
          </div>
        </div>
      )}

      {/* RECEIPT FOR UPGRADE  */}
      {company.subscription === "UNLIMITED" && showReceipt && (
        <div>
          <h2 className="text text-sky-200 mb-2 uppercase tracking-widest font-semibold">
            Dit nye abonnement
          </h2>
          <h1 className="text-2xl font-semibold flex flex-row items-center mb-4">
            <span className="font-normal mr-2">Tela Vedligehold</span>{" "}
            <span>Ubegrænset</span>
            <VerifiedIcon
              className="w-8 h-8 ml-4 text-sky-200"
              strokeWidth={2}
            />
          </h1>

          {/* BENEFITS */}
          <ul className="text-sm text-sky-200 mb-8">
            <li className="flex flex-row items-center mb-2">
              <CheckIcon className="w-4 h-4 mr-2" /> Ingen begrænsninger
            </li>
            <li className="flex flex-row items-center mb-2">
              <CheckIcon className="w-4 h-4 mr-2" /> 1 års abonnement
            </li>
            <li className="flex flex-row items-center mb-2">
              <CheckIcon className="w-4 h-4 mr-2" /> Ingen binding
            </li>
          </ul>

          {/* THANK YOU */}
          <h2 className="text-xl font-semibold mb-2">Tak for dit køb</h2>
          <p className="leading-7">
            Dit nye abonnement er aktiveret, og du kan nu bruge Tela Vedligehold
            uden begrænsninger.
          </p>

          {/* CTA */}
          <div className="w-full flex flex-row mt-8">
            <Button
              className="min-w-full bg-blue-900 border border-opacity-20"
              type="primary"
              loading={loading}
              onClick={() => {
                setShowReceipt(false);
              }}
            >
              Se abonnementsdetaljer{" "}
              <ChevronRightIcon className="w-4 h-4 ml-2" />
            </Button>
          </div>
        </div>
      )}

      {/* EDIT / REVIEW  */}
      {company.subscription !== "FREE" && !showReceipt && (
        <div>
          <h2 className="text text-sky-200 mb-2 uppercase tracking-widest font-semibold">
            Abonnement
          </h2>
          <h1 className="text-2xl font-semibold flex flex-row items-center mb-4">
            <span className="font-normal mr-2">Tela Vedligehold</span>{" "}
            <span>Ubegrænset</span>
            <VerifiedIcon
              className="w-8 h-8 ml-4 text-sky-200"
              strokeWidth={2}
            />
          </h1>

          {/* BENEFITS */}
          <ul className="text-sm text-sky-200 mb-8">
            <li className="flex flex-row items-center mb-2">
              <CheckIcon className="w-4 h-4 mr-2" /> Ingen begrænsninger
            </li>
          </ul>

          {/* TIMELINE */}
          <div>
            <p className="text-sky-100 text-opacity-75 uppercase text-xs font-semibold tracking-wide">
              Abonnementsperiode
            </p>
            <p className="text-sky-100 font-semibold mb-4 flex flex-row items-center justify-between mt-2">
              <span>
                {format_date(
                  subYears(new Date(company.subscriptionEndDate), 1).toString()
                )}
              </span>
              <span>til</span>
              <span>{format_date(company.subscriptionEndDate)}</span>
            </p>
          </div>

          {/* RENEWAL */}
          <div className="my-8">
            <p className="text-sky-100 text-opacity-75 uppercase text-xs font-semibold tracking-wide">
              Fornyelse
            </p>
            <div className="flex flex-col md:flex-row items-center justify-between mb-4 mt-2">
              {!company.subscriptionCancelled && (
                <p className="text-sky-100 font-semibold flex flex-row items-center mb-4 md:mb-0">
                  <CheckCircleIcon className="w-5 h-5 mr-2 text-white" />{" "}
                  Fornyes automatisk
                </p>
              )}

              {!company.subscriptionCancelled && (
                <Button
                  type="primary"
                  className="bg-blue-900"
                  onClick={() =>
                    update({ id: company.id, subscriptionCancelled: true })
                  }
                >
                  <AlertTriangleIcon className="w-4 h-4 mr-2 text-yellow-300" />{" "}
                  Forny ikke
                </Button>
              )}

              {company.subscriptionCancelled && (
                <p className="text-sky-100 font-semibold flex flex-row items-center mb-4 md:mb-0">
                  <AlertTriangleIcon className="w-5 h-5 mr-2 text-yellow-300" />{" "}
                  Fornyes ikke{" "}
                  <span className="md:hidden ml-1">automatisk</span>
                </p>
              )}

              {company.subscriptionCancelled && (
                <Button
                  className="bg-blue-900"
                  type="primary"
                  onClick={() =>
                    update({ id: company.id, subscriptionCancelled: false })
                  }
                >
                  <RefreshcwIcon className="w-4 h-4 mr-2 text-sky-300" /> Forny
                  automatisk
                </Button>
              )}
            </div>
          </div>

          {/* CTA */}
          <div className="w-full flex flex-row mt-12">
            <Button
              className="min-w-full bg-blue-900 border border-opacity-20"
              type="primary"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CheckIcon className="w-4 h-4 mr-2" /> Luk
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
