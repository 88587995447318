import { Badge, Button, SelectFieldset, XIcon } from "@onlion/components";
import React from "react";
import { getUnique } from "../../../../../../components/getUnique";
import { mapStatus } from "../../../../../../components/mapStatus";
import {
  GetFilterValueFunc,
  SetFilterValueFunc,
} from "../../../../../../components/useTable";
import {
  Task,
  TaskResponsible,
  TaskStatus,
  TaskEquipment,
} from "../../../../../../data/useTasks";

interface FilterProps {
  tasks: Task[];
  get: GetFilterValueFunc;
  set: SetFilterValueFunc;
  clear: () => void;
}

export const Filters = ({ tasks, get, set, clear }: FilterProps) => {
  const statuses = getUnique<Task, TaskStatus>(tasks, "status").map(
    (status) => ({
      label: (
        <Badge type={mapStatus(status).color}>{mapStatus(status).label}</Badge>
      ),
      value: status,
    })
  );
  const status = get("status");
  const statusPlaceholder = "Alle statusser";
  const statusComp =
    statuses.length > 1 ? (
      <SelectFieldset
        placeholder={statusPlaceholder}
        label="Status"
        id="status_filter"
        short
        onChange={(v) => {
          if (v) {
            set("status", v.value);
          } else {
            set("status", v);
          }
        }}
        options={statuses}
        selected={{
          label: status ? (
            <Badge type={mapStatus(status).color}>
              {mapStatus(status).label}
            </Badge>
          ) : (
            statusPlaceholder
          ),
          value: status,
        }}
      />
    ) : null;

  const responsibles = tasks
    .reduce((unique: TaskResponsible[], t) => {
      const alreadyIn =
        unique.find((u) => u.id === t.responsible.id) !== undefined;
      if (!alreadyIn) return [...unique, t.responsible];
      return unique;
    }, [])
    .map((r) => ({ label: r.name, value: r.id }));
  const responsible = get("responsible");
  const responsiblePlaceholder = "Alle ansvarlige";
  const responsibleComp =
    responsibles.length > 1 ? (
      <SelectFieldset
        placeholder={responsiblePlaceholder}
        label="Ansvarlig"
        id="responsible_filter"
        short
        onChange={(v) => {
          if (v) {
            set("responsible", v.value);
          } else {
            set("responsible", v);
          }
        }}
        options={responsibles}
        selected={{
          label:
            responsibles.find((r) => r.value === responsible)?.label ??
            responsiblePlaceholder,
          value: responsible,
        }}
      />
    ) : null;

  const allEquipment = tasks
    .reduce((unique: TaskEquipment[], t: Task) => {
      const alreadyIn =
        unique.find((u) => u.id === t.equipment.id) !== undefined;
      if (!alreadyIn) return [...unique, t.equipment];
      return unique;
    }, [])
    .map((r) => ({
      label: (
        <>
          {r.name}{" "}
          <span className="text-sm text-gray-400 block">{r.equipmentId}</span>
        </>
      ),
      value: r.id,
    }))
    .sort((a, b) => (a.label > b.label ? -1 : 1));
  const equipment = get("equipment");
  const equipmentPlaceholder = "Alt udstyr";
  const equipmentComp =
    allEquipment.length > 1 ? (
      <SelectFieldset
        placeholder={equipmentPlaceholder}
        label="Udstyr"
        id="equipment_filter"
        short
        onChange={(v) => {
          if (v) {
            set("equipment", v.value);
          } else {
            set("equipment", v);
          }
        }}
        options={allEquipment}
        selected={{
          label:
            allEquipment.find((r) => r.value === responsible)?.label ??
            equipmentPlaceholder,
          value: equipment,
        }}
      />
    ) : null;

  return (
    <>
      {statusComp}
      {responsibleComp}
      {equipmentComp}

      <Button icon={{ leading: XIcon }} onClick={clear}>
        Ryd filtre
      </Button>
    </>
  );
};
