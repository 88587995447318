import { TaskInterval } from "../data/useTasks";

export function intervalToString(
  interval?: TaskInterval,
  inline?: boolean
): string {
  if (!interval) return "";

  switch (interval) {
    case "DAILY":
      return inline ? "dagligt" : "Hver dag";
    case "DAILY_WEEKDAYS":
      return inline ? "på hverdage" : "Hver dag på hverdage";
    case "WEEKLY":
      return inline ? "ugenligt" : "Hver uge";
    case "BI_WEEKLY":
      return inline ? "hver anden uge" : "Hver 2. uge";
    case "MONTHLY":
      return inline ? "månedligt" : "Hver måned";
    case "BI_MONTHLY":
      return inline ? "hver anden måned" : "Hver 2. måned";
    case "QUARTERLY":
      return inline ? "kvartalvis" : "Hver 3. måned";
    case "TRIANNUAL":
      return inline ? "hver fjerde måned" : "Hver 4. måned";
    case "HALF_YEARLY":
      return inline ? "halvårligt" : "Hver halve år";
    case "YEARLY":
      return inline ? "årligt" : "Hvert år";
    case "SEMI_YEARLY":
      return inline ? "hver andet år" : "Hvert 2. år";
    case "EVERY_3_YEARS":
      return inline ? "hver tredje år" : "Hvert 3. år";
    case "EVERY_4_YEARS":
      return inline ? "hver fjerde år" : "Hvert 4. år";
    case "EVERY_5_YEARS":
      return inline ? "hver femte år" : "Hvert 5. år";
    case "EVERY_8_YEARS":
      return inline ? "hver ottene år" : "Hvert 8. år";
    default:
      return "interval mangler";
  }
}

export const intervals: TaskInterval[] = [
  "DAILY",
  "DAILY_WEEKDAYS",
  "WEEKLY",
  "BI_WEEKLY",
  "MONTHLY",
  "BI_MONTHLY",
  "QUARTERLY",
  "TRIANNUAL",
  "HALF_YEARLY",
  "YEARLY",
  "SEMI_YEARLY",
  "EVERY_3_YEARS",
  "EVERY_4_YEARS",
  "EVERY_5_YEARS",
  "EVERY_8_YEARS",
];
