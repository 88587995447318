import useSWR from "swr";
import { get } from "../../components/requests/requests";
import { OriginalNote } from "./types";
import { useCompanyIdParam } from "../../components/hooks";

export const useGetNotes = ({
  taskId,
  equipmentId,
}:
  | { taskId: string; equipmentId?: never }
  | { equipmentId: string; taskId?: never }): {
  isLoading: boolean;
  notes: OriginalNote[];
  mutate: any;
} => {
  const companyId = useCompanyIdParam();
  if (!companyId) throw new Error("no company id param set");

  const id = taskId ?? equipmentId;
  const {
    data: notes,
    error,
    mutate,
  } = useSWR(`notes/${id}`, () =>
    get("note", { taskId, equipmentId, companyId })
  );

  const isLoading = !notes && !error;

  return {
    isLoading,
    notes: notes ?? [],
    mutate,
  };
};
