import { ReactNode } from "react";

interface FieldLabelProps {
  htmlFor: string | null;
  className?: string;
  children: ReactNode;
}

export const FieldLabel = ({
  htmlFor,
  className,
  children,
}: FieldLabelProps) => {
  return (
    <label
      htmlFor={htmlFor ? htmlFor : ""}
      className={`block font-normal text-sm text-gray-700 ${className}`}
    >
      {children}
    </label>
  );
};
