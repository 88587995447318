import { useState } from "react";
import { mutate } from "swr";
import { patch } from "../../components/requests/requests";
import { notify } from "../../dashboard/components/notifications/newNotification";
import { key } from "./key";
import { UserChangeSet } from "./types";
import { useParams } from "react-router-dom";

export const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const { companyId } = useParams();

  const updateUser = async (user: UserChangeSet): Promise<boolean> => {
    setLoading(true);
    const success = await patch("user", user, { companyId });
    setLoading(false);
    if (success) {
      mutate(key);
      notify({
        heading: "Succes",
        text: "Brugeren blev rettet.",
      });

      return true;
    } else {
      notify({
        heading: "Fejl",
        text: "Noget gik galt. Ændringerne blev ikke gemt. Prøv igen.",
      });
      return false;
    }
  };

  return {
    updateUser,
    loading,
  };
};
