import { FileX2Icon } from "@onlion/components";
import { Image } from "./image";
import { File } from "../../file";
import { NoteResponse, OriginalNote } from "../../../data/notes/types";

export type FileListProps = {
  files: OriginalNote["files"] | NoteResponse["files"];
};

export const FileList = ({ files }: FileListProps) => {
  if (!files || Array.isArray(files) === false || files.length === 0)
    return (
      <span className="flex items-center mt-4 text-gray-500 text-sm">
        <FileX2Icon className="w-3 h-3 mr-1" /> Ingen filer vedlagt
      </span>
    );

  const images = files.filter(isImage(true));
  const nonImages = files.filter(isImage(false));

  return (
    <div>
      <div className="flex flex-row flex-wrap mt-8 space-x-3">
        {images.map((img) => {
          return <Image key={img} imageId={img} />;
        })}
      </div>

      {nonImages.length > 0 &&
        nonImages.map((f, i) => <File key={i} fileId={f} />)}
    </div>
  );
};

const isImage =
  (isImg: boolean = true) =>
  (fn: string): boolean => {
    const parts = fn.split(".");
    const extension = parts[parts.length - 1].toLowerCase();

    const imageExtensions = ["jpg", "png", "jpeg", "mp4", "svg"];
    const fileIsImage = imageExtensions.includes(extension);

    return isImg ? fileIsImage : !fileIsImage;
  };
