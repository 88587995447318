import format from "date-fns/format";
import da from "date-fns/locale/da";

export const formatDate = (
  date: string | Date | number,
  long: boolean = false
): string => {
  const date_to_format = new Date(date);

  if (!isValidDate(date_to_format)) return "-";

  const date_format = long ? "eeee' den' do MMMM',' y" : "do MMM',' y";
  const formatted = format(date_to_format, date_format, { locale: da });

  // capitalise first letter when long, otherwise return immediately
  return long ? formatted[0].toUpperCase() + formatted.slice(1) : formatted;
};

const isValidDate = (d: Date) => {
  return d instanceof Date && !isNaN(d.getTime());
};
