import { Page, RefreshcwIcon, SpinnerIcon } from "@onlion/components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Link from "../../../components/Link/Link";
import { loginWithAuthCode } from "./index.actions";

export const MFAStepTwoPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const { code } = useParams();

  useEffect(() => {
    if (!code) return;
    const login = async () => {
      const result = await loginWithAuthCode(code);
      setLoading(false);
      if (!result) {
        setError("Kunne ikke logge ind. Prøv igen.");
      }
    };
    login();
  }, [code]);

  return (
    <Page
      className="m-auto max-w-xl shadow-md rounded-sm"
      headingProps={{
        title: (
          <span className="flex items-center">
            <span>{loading ? "Logger ind" : "Fejl"}</span>{" "}
            {loading && <RefreshcwIcon className="w-6 h-6 ml-2 animate-spin" />}
          </span>
        ),
      }}
    >
      <div className="px-4 pb-4 md:px-8 md:pb-8 max-w-md">
        {error && <p className="text-red-500 font-medium">{error}</p>}
        {!loading && (
          <ul className="list-disc mt-6">
            <li className="mt-2">
              Dette skyldes sandsynligvis at linket er udløbet (linket holder i
              5 minutter). <br />
              Prøv igen ved at{" "}
              <Link to="/auth">gå tilbage til Log ind-siden</Link>
            </li>
            <li className="mt-2">
              Hvis du stadig har problemer, kan du{" "}
              <a
                className="text-blue-500 hover:text-blue-700 hover:underline"
                href="mailto:kontakt@tela-vedligehold.dk"
              >
                kontakte os på email kontakt@tela-vedligehold.dk
              </a>
            </li>
          </ul>
        )}
      </div>
    </Page>
  );
};
