import { useState } from "react";
import { mutate } from "swr";
import { patch } from "../../components/requests/requests";
import { notify } from "../../dashboard/components/notifications/newNotification";
import { NoteChangeSet } from "./types";
import { useParams } from "react-router-dom";

export const useUpdateNote = () => {
  const { companyId } = useParams();
  const [loading, setLoading] = useState(false);

  const updateNote = async (note: NoteChangeSet): Promise<boolean> => {
    setLoading(true);
    const success = await patch("equipment/note", note, { companyId });
    setLoading(false);
    if (success) {
      mutate("api/equipment");
      notify({
        heading: `Succes`,
        text: `Noten er nu ${note.archived ? "skjult" : "vist"}`,
      });

      return true;
    } else {
      notify({
        heading: `Fejl`,
        text: "Noget gik galt. Noten kunne ikke opdateres. Prøv igen.",
        type: "alert",
      });
      return false;
    }
  };

  return {
    updateNote,
    loading,
  };
};
