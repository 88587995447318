import React, { FunctionComponent } from "react";
import logo from "../../../components/images/logo-72.png";
import Notifications from "../notifications/notifications";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: FunctionComponent<AuthLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-200">
      <Notifications />
      <div className="w-full">
        <div className="py-8">{children}</div>
      </div>
      <div className="flex flex-row items-center text-blue-900 justify-center py-4">
        <img src={logo} alt="Tela Vedligehold's logo" className="w-8 h-8" />{" "}
        <strong className="mr-1">Tela Vedligehold</strong>
      </div>
    </div>
  );
};

export default AuthLayout;
