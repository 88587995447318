import { EyeOffIcon, EyeIcon } from "@onlion/components";
import { OriginalNote } from "../../data/notes/types";
import { useUpdateNote } from "../../data/notes/useUpdateNote";
import useUser from "../../data/useUser";
import { CommentPlusIcon } from "../icons/commentPlusIcon";
import { useState } from "react";
import { NoteOrNoteResponseForm } from "./noteOrNoteResponseForm";
import { Button } from "../button";
import { useCreateNoteResponse } from "../../data/notes/useCreateNoteResponse";
import { HeaderAndContent } from "./helpers/headerAndContent";
import { ResponseList } from "./helpers/responseList";
import useUsers from "../../data/users/useUsers";
import { useUserRoleType } from "../hooks";

export type NoteProps = {
  note: OriginalNote;
  query?: string;
  showTaskLink?: boolean;
};

export const Note = ({ note, query = "", showTaskLink = true }: NoteProps) => {
  const {
    id,
    content,
    datetime,
    author,
    core,
    archived,
    files,
    responses,
    task,
  } = note;

  const { users } = useUsers();
  const [showResponseForm, setShowResponseForm] = useState<boolean>(false);
  const { user } = useUser();
  const { isAdmin } = useUserRoleType(user);

  const { updateNote, loading } = useUpdateNote();
  const { createNoteResponse, loading: createNoteResponseLoading } =
    useCreateNoteResponse();

  const hasResponses = responses && responses?.length > 0;

  return (
    <div id={id}>
      <div className="bg-white overflow-hidden border border-gray-300 rounded">
        <HeaderAndContent
          data={{
            content,
            datetime,
            author,
            core,
            archived,
            files,
            task,
          }}
          query={query}
          showTaskLink={showTaskLink}
        />

        {/* Actions */}
        <div className="bg-gray-100 border-t border-gray-300 px-4 py-3 flex justify-end items-center space-x-4">
          {isAdmin && (
            <>
              {archived ? (
                <Button
                  loading={loading}
                  icon={{ leading: EyeIcon }}
                  onClick={() => updateNote({ archived: false, id })}
                >
                  Gendan
                </Button>
              ) : (
                <Button
                  loading={loading}
                  icon={{ leading: EyeOffIcon }}
                  onClick={() => updateNote({ archived: true, id })}
                >
                  Arkiver
                </Button>
              )}
            </>
          )}
          {!archived && (
            <Button
              loading={loading}
              icon={{ leading: CommentPlusIcon }}
              type="primary"
              onClick={() => setShowResponseForm(true)}
            >
              Tilføj svar
            </Button>
          )}
        </div>
      </div>
      {(showResponseForm || hasResponses) && (
        <div className="flex">
          <div className="py-6 w-full flex flex-col gap-6 md:ml-8">
            {showResponseForm && (
              <NoteOrNoteResponseForm
                cancelFunc={() => setShowResponseForm(false)}
                submitFunc={({ content, files, recipientIds }) =>
                  createNoteResponse({
                    content,
                    files,
                    noteId: id,
                    recipientIds,
                  })
                }
                loading={createNoteResponseLoading}
                users={users ?? []}
                usersId={user.id}
                copy={{
                  contentLabel: "Svar på noten",
                  recipientsLabel: "Modtagere (valgfrit)",
                  recipientsHelp:
                    "Valgte modtagere tilsendes en kopi af svaret. Andre deltagere i samtalen tilsendes automatisk en kopi.",
                  submitLabel: "Tilføj svar",
                }}
              />
            )}
            {responses && <ResponseList responses={responses} />}
          </div>
        </div>
      )}
    </div>
  );
};
