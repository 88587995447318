import { Loading, RefreshcwIcon, ZapIcon } from "@onlion/components";
import { formatDistance } from "date-fns";
import da from "date-fns/locale/da";
import { format_date } from "@onlion/utils";
import { useHistoricCompletions } from "../../../../../../data/useHistoricCompletions";
import { useTable } from "../../../../../../components/useTable";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Table } from "../../../../../../components/table";
import { wasLate } from "../../../../../../utils/wasLate";

export const EquipmentHistory = ({ isAdmin }: { isAdmin: boolean }) => {
  const { id, companyId } = useParams();
  const { historicCompletions, loading } = useHistoricCompletions();

  const { data } = useTable(historicCompletions, "historicCompletions");

  if (loading)
    return (
      <div className="h-screen">
        <Loading
          title="Henter udstyrets opgavehistorik"
          text="Dette bør kun tage få øjeblikke"
        />
      </div>
    );

  return (
    <div className="my-2">
      <Table
        head={[
          { content: "Udført opgave" },
          { content: "Dato" },
          { content: "Ansvarlig" },
          { content: "Type", hideMobile: false, right: true },
        ]}
        row={{
          fields: [
            {
              renderData: ({ task }) =>
                isAdmin ? (
                  <Link
                    to={`/dashboard/${companyId}/tasks/${task.id}`}
                    className="hover:text-blue-600 flex"
                  >
                    {task.title}
                  </Link>
                ) : (
                  task.title
                ),
            },
            {
              renderData: (hc) => (
                <>
                  {format_date(hc.finishedOn)}{" "}
                  <p className="text-xs">
                    {wasLate(hc)
                      ? `forsinket ${formatDistance(
                          new Date(hc.finishedOn),
                          new Date(hc.deadline),
                          { locale: da }
                        )}`
                      : "uden forsinkelse"}
                  </p>
                </>
              ),
            },
            {
              renderData: ({ responsible }) =>
                isAdmin ? (
                  responsible.name ? (
                    <Link
                      to={`/dashboard/${companyId}/users/${responsible.id}`}
                      className="hover:text-blue-600 flex"
                    >
                      {responsible.name}
                    </Link>
                  ) : (
                    "en nu slettet ansvarlig"
                  )
                ) : (
                  responsible.name ?? "en nu slettet ansvarlig"
                ),
            },
            {
              renderData: ({ task: { type } }) => getType(type),
              hideMobile: false,
              right: true,
            },
          ],
        }}
        data={data.filter((hc) => hc.equipment.id === id)}
      />
    </div>
  );
};

function getType(type: "AD_HOC" | "CYCLICAL") {
  switch (type) {
    case "CYCLICAL":
      return (
        <p className="flex items-center justify-end">
          <RefreshcwIcon className="w-3 h-3 mr-2" /> Rutine
        </p>
      );
    case "AD_HOC":
      return (
        <p className="flex items-center justify-end">
          <ZapIcon className="w-3 h-3 mr-2" /> Enkelt
        </p>
      );
  }
}
