import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CheckCircleIcon,
  DashboardPages,
  Form,
  FormHeading,
  FormSection,
  MailIcon,
  TextFieldset,
} from "@onlion/components";

import { FormActionBar } from "../../../components/formActionBar";
import { TrashIcon } from "../../../components/icons/trashIcon";
import { Button } from "../../../components/button";
import { useInvitation } from "../../../data/useInvitation";
import { LoadingComponent } from "../../../components/loading/loadingComponent";
import { useAcceptInvitation } from "../../../data/useAcceptInvitation";
import { useDeleteInvitation } from "../../../data/useDeleteInvitation";
import { notifyAuth } from "../../../dashboard/components/notifications/newNotification";

export const InvitationPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const { acceptInvitation } = useAcceptInvitation();

  const { invitation, error, loading: invitationLoading } = useInvitation();
  const { deleteInvitation, loading: deleteInvitationLoading } =
    useDeleteInvitation();

  useEffect(() => {
    if (invitation) {
      setName(invitation.name);
    }
  }, [invitation]);

  if (invitationLoading) {
    return (
      <div className="flex flex-col gap-4 text-center">
        <h1 className="text-3xl font-semibold text-blue-900">
          Henter invitation
        </h1>
        <h2 className="text-lg">Dette bør kun tage få sekunder</h2>
        <LoadingComponent />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col gap-4 max-w-md m-auto text-left p-8 bg-white rounded">
        <h1 className="text-3xl font-semibold text-blue-900">Fejl</h1>
        <h2 className="text-lg">Invitationen kunne ikke findes</h2>
        <p>Hvis du mener der er sket en fejl, kan du: </p>
        <ol className="flex flex-col gap-4">
          <li>
            1. Kontakte den som administrerer Tela Vedligehold i din virksomhed,
            og bede dem om at invitere dig igen,
          </li>
          <p className="italic">eller</p>
          <li>
            2. Hvis du tror der er sket en fejl i selve programmet, kan du
            kontakte os på{" "}
            <a
              className="text-blue-600 underline"
              href="mailto:kontakt@tela-vedligehold.dk"
            >
              kontakt@tela-vedligehold.dk
            </a>
          </li>
        </ol>
      </div>
    );
  }

  const handleJoin = async () => {
    if (!token || !invitation) return;

    setLoading(true);
    const success = await acceptInvitation({
      token,
      companyName: invitation.companyName,
      name,
      password,
    });
    setLoading(false);
    if (success) {
      navigate("/auth");
    }
  };

  const handleDelete = async () => {
    if (!token || !invitation) return;

    setLoading(true);
    const success = await deleteInvitation(token);
    setLoading(false);
    if (success) {
      notifyAuth({
        heading: "Invitation slettet",
        text: "Din invitation blev slettet",
      });
      navigate("/auth");
    }
  };

  return (
    <DashboardPages.Form title="Invitation">
      <Form onSubmit={() => {}}>
        <FormHeading>
          <MailIcon className="w-8 h-8 mr-2" /> Svar på Invitation
        </FormHeading>

        <hr />

        <p>
          Du er blevet inviteret til blive bruger i '{invitation?.companyName}',
          udfyld formularen nedenfor for at acceptere, eller klik på knappen
          'Afvis invitation' for at slette invitationen.
        </p>

        <hr />

        <Button
          type="danger"
          icon={{ trailing: () => <TrashIcon className="w-4 ml-1" /> }}
          onClick={handleDelete}
        >
          Afvis invitation
        </Button>

        <p className="italic p-4">eller udfyld nedestående for at acceptere</p>

        <FormSection
          heading="Opret bruger"
          info={
            <>
              <p>Bekræft dit navn, og vælg et password.</p>
              <p>
                Vælg et godt password, som er mindst 10 tegn langt og svært at
                gætte
              </p>
            </>
          }
        >
          <TextFieldset
            id="name"
            autoComplete="name"
            autoFocus
            label="Dit navn"
            value={name}
            onChange={(e) => setName(e.toLowerCase())}
            valid={name.length > 1}
            required
          />
          <TextFieldset
            id="password"
            autoComplete="new-password"
            placeholder="Password"
            label="Password"
            value={password}
            type="password"
            onChange={setPassword}
            valid={password.length > 10}
            help="Bemærk at der skelnes mellem store og små bogstaver."
            required
          />
        </FormSection>

        <FormActionBar>
          <Button
            htmlType="submit"
            type="primary"
            onClick={handleJoin}
            loading={loading}
          >
            <CheckCircleIcon className="w-4 h-4 mr-2" /> Acceptér invitation
          </Button>
        </FormActionBar>
      </Form>
    </DashboardPages.Form>
  );
};
