import { Button } from "../../../components/button";
import { Table } from "../../../components/table";
import { useInvitations } from "../../../data/useInvitations";
import { UserType } from "../../../data/users/types";
import { useDeleteInvitation } from "../../../data/useDeleteInvitation";
import { useAcceptInvitation } from "../../../data/useAcceptInvitation";

export const InvitationSection = () => {
  const { invitations } = useInvitations({});
  const { deleteInvitation, loading: deleteInvitationLoading } =
    useDeleteInvitation();

  const { acceptInvitation, loading: acceptInvitationLoading } =
    useAcceptInvitation();

  return (
    <div id="invitations">
      <Table
        className="mt-4"
        errorMessage="Du har ingen invitationer"
        head={[
          { content: "Kontonavn" },
          { content: "Rolle", hideMobile: true },
          { content: "", hideMobile: false },
        ]}
        row={{
          fields: [
            { renderData: ({ companyName }) => companyName },
            {
              renderData: ({ type }) => readableUserType[type],
              hideMobile: true,
            },
            {
              renderData: ({ token, companyName }) => (
                <div className="flex gap-1">
                  <Button
                    className="ml-auto"
                    type="primary"
                    loading={acceptInvitationLoading}
                    onClick={() => acceptInvitation({ token, companyName })}
                  >
                    Acceptér
                  </Button>
                  <Button
                    onClick={() => deleteInvitation(token)}
                    loading={deleteInvitationLoading}
                  >
                    Slet
                  </Button>
                </div>
              ),
              hideMobile: false,
            },
          ],
        }}
        data={invitations.map((i) => ({
          ...i,
          id: Math.random().toString(),
        }))}
      />
    </div>
  );
};

const readableUserType: Record<UserType, string> = {
  SUPER_ADMIN: "Superadministrator",
  ADMIN: "Administrator",
  USER: "Bruger",
};
