import React from "react";
import { NotificationCard } from "@onlion/components";
import { Notification } from "@onlion/components";
import useTransient from "../../../utils/transient";

const Notifications = React.memo(
  () => {
    const notifications = useTransient<Notification>("dashboard/notifications");

    return (
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications.map((n) => (
            <NotificationCard
              className="block z-50"
              heading={n.heading}
              duration={n.duration}
              text={n.text}
              type={n.type}
              key={n.id}
            />
          ))}
        </div>
      </div>
    );
  },
  () => {
    return true;
  }
);

export default Notifications;
