import { useState, useEffect, useMemo } from "react";
import useEquipment, { Equipment } from "../../../../../data/useEquipment";
import {
  Route,
  Routes,
  useParams,
  useNavigate,
  Link,
  NavLink,
} from "react-router-dom";
import {
  AlertTriangleIcon,
  Badge,
  Edit2Icon,
  Modal,
  Trash2Icon,
  PlusIcon,
  ChevronRightIcon,
} from "@onlion/components";
import Tasks from "./sections/tasks";
import { useBreadcrumbs } from "../../../../../state/useBreadcrumbs";
import { ModalContent } from "../../../../../components/modalContent";
import { mapCategoryToIcon } from "../../../../../components/mapCategoryToIcon";
import { EquipmentHistory } from "./sections/history";
import useUser from "../../../../../data/useUser";
import { Button } from "../../../../../components/button";
import { useUserRoleType } from "../../../../../components/hooks";
import { NotesSection } from "../../../../components/notesSection";

export const EquipmentFocusPage = () => {
  const { replaceBreadcrumbs } = useBreadcrumbs();
  const { id, companyId } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { user } = useUser();
  const { isAdmin } = useUserRoleType(user);

  const { equipment, deleteEquipment } = useEquipment();

  const focusedEquipment = useMemo(
    () => equipment.find((e) => e.id === id),
    [equipment, id]
  );

  // Breadcrumbs
  useEffect(() => {
    if (focusedEquipment) {
      const baseLink = `/dashboard/${companyId}/equipment`;
      replaceBreadcrumbs([
        {
          text: "Udstyr",
          link: baseLink,
        },
        { text: `${focusedEquipment.name} ${focusedEquipment.equipmentId}` },
      ]);
    }
  }, [focusedEquipment, replaceBreadcrumbs, companyId, id]);

  if (!focusedEquipment) return null;

  const noOfTasks = focusedEquipment.tasks.length;
  const noOfNotes = focusedEquipment.notes.length;

  const CategoryIcon = mapCategoryToIcon(focusedEquipment.category);

  return (
    <>
      <div className="w-full bg-white bg-gray-50 min-h-screen">
        {/* TOP BAR */}
        <div className="bg-white shadow-sm border-b border-gray-300 p-6 py-4">
          <div className="flex justify-between items-center flex-col gap-4 md:flex-row">
            <div className="flex flex-col text-sm gap-1 align-center">
              <div className="flex">
                <Link
                  to={`/dashboard/${companyId}/equipment`}
                  className="text-blue-800 hover:underline"
                >
                  Udstyr
                </Link>{" "}
                <ChevronRightIcon className="w-4 text-gray-400" />
              </div>
              <h1 className="text-3xl font-bold text-blue-900">
                {focusedEquipment.name}
              </h1>
            </div>

            <div>
              {isAdmin && (
                <div className={"flex gap-1"}>
                  <Button
                    icon={{ leading: PlusIcon }}
                    onClick={() => navigate(`new-task`)}
                    type="primary"
                  >
                    Tilføj opgave
                  </Button>
                  <Button
                    icon={{ leading: Edit2Icon }}
                    onClick={() =>
                      navigate(`/dashboard/${companyId}/equipment/update/${id}`)
                    }
                  >
                    Ret
                  </Button>
                  <Button
                    icon={{ leading: Trash2Icon }}
                    onClick={() => setShowDeleteModal(true)}
                  >
                    Slet
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <main className="md:grid grid-cols-12 w-full py-2 px-2 md:py-4 md:px-4 sm:gap-8">
          <aside className="col-span-3 mb-4">
            <h2 className="px-4 py-1 border-b-2 border-transparent inline-block font-medium text-gray-800">
              Info
            </h2>

            <section className="px-2 py-3 border-t border-gray-300">
              <ul className="flex flex-col gap-2">
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Status
                  </label>
                  <span className="block break-words text-sm">
                    <Badge type={getStatus(focusedEquipment).color}>
                      {getStatus(focusedEquipment).text}
                    </Badge>
                  </span>
                </li>
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Id-nummer
                  </label>
                  <span className="block break-words text-sm">
                    {focusedEquipment.equipmentId}
                  </span>
                </li>
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Lokation
                  </label>
                  <span className="block break-words text-sm">
                    {focusedEquipment.location}
                  </span>
                </li>
                <li>
                  <label htmlFor="status" className="text-xs text-gray-600">
                    Kategori
                  </label>
                  <span className="block break-words text-sm flex items-center gap-2">
                    {focusedEquipment.category}{" "}
                    <CategoryIcon className="w-4 text-gray-600" />
                  </span>
                </li>
              </ul>
            </section>
          </aside>

          <section className="col-span-9">
            <div className="flex" style={{ marginBottom: "-1px" }}>
              <NavLink className={tabClass} end to="">
                Noter{" "}
                <span className="bg-gray-100 leading-none py-0.5 px-1.5 rounded-lg text-xs no-underline">
                  {noOfNotes}
                </span>
              </NavLink>
              <NavLink className={tabClass} end to="tasks">
                Opgaver{" "}
                <span className="bg-gray-100 leading-none py-0.5 px-1.5 rounded-lg text-xs no-underline">
                  {noOfTasks}
                </span>
              </NavLink>
              <NavLink className={tabClass} to="history">
                Historik
              </NavLink>
            </div>
            <div className="px-2 py-3 border-t border-gray-300">
              <Routes>
                <Route
                  element={
                    <NotesSection equipmentId={id ?? ""} key={id ?? ""} />
                  }
                  path=""
                />
                <Route
                  element={
                    <Tasks
                      isAdmin={isAdmin}
                      id={id ?? ""}
                      tasks={focusedEquipment?.tasks}
                    />
                  }
                  path="tasks"
                />
                <Route
                  element={<EquipmentHistory isAdmin={isAdmin} />}
                  path="history"
                />
              </Routes>
            </div>
          </section>
        </main>
      </div>

      <Modal
        isOpen={showDeleteModal}
        setIsOpen={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalContent
          Icon={AlertTriangleIcon}
          iconColor="yellow"
          heading={`Slet ${focusedEquipment.name}?`}
          content={
            <>
              <p>Dette kan ikke fortrydes.</p>
              {(noOfNotes > 0 || noOfTasks > 0) && (
                <p>
                  Udstyrets{" "}
                  {noOfTasks > 0 &&
                    (noOfTasks === 1 ? "opgave" : `${noOfTasks} opgaver`)}{" "}
                  {noOfTasks > 0 && noOfNotes > 0 && "og"}{" "}
                  {noOfNotes === 1 ? "note" : `${noOfNotes} noter`} vil også
                  blive slettet permanent.
                </p>
              )}
            </>
          }
          buttons={
            <>
              <Button onClick={() => setShowDeleteModal(!showDeleteModal)}>
                Fortryd
              </Button>
              <Button
                type="danger"
                onClick={async () => {
                  const success = await deleteEquipment(focusedEquipment.id);
                  if (success) navigate(`/dashboard/${companyId}/equipment`);
                }}
              >
                <Trash2Icon className={"w-4 h-4 mr-2"} /> Slet permanent
              </Button>
            </>
          }
        />
      </Modal>
    </>
  );
};

function getStatus(e: Equipment): {
  text: string;
  color: "red" | "yellow" | "blue";
} {
  if (e.archived) return { text: "Slettet", color: "red" };
  if (e.standby) return { text: "Standby", color: "yellow" };
  return { text: "Aktiv", color: "blue" };
}

const tabClass = ({ isActive }: { isActive: boolean }) =>
  `px-4 py-1 border-b-2 border-transparent inline-block ${
    isActive
      ? "font-medium border-blue-500 text-gray-800"
      : "font-base text-gray-600"
  }`;
