import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  getDay,
  subDays,
} from "date-fns";
import { TaskInterval } from "../data/useTasks";

export function incrementDeadline(
  deadline: Date,
  interval: TaskInterval
): Date {
  let next_deadline = null;
  switch (interval) {
    case "DAILY":
      next_deadline = increment_daily(deadline);
      break;
    case "DAILY_WEEKDAYS":
      next_deadline = increment_daily_weekdays(deadline);
      break;
    case "WEEKLY":
      next_deadline = increment_weekly(deadline);
      break;
    case "BI_WEEKLY":
      next_deadline = increment_bi_weekly(deadline);
      break;
    case "MONTHLY":
      next_deadline = increment_monthly(deadline);
      break;
    case "BI_MONTHLY":
      next_deadline = increment_bi_monthly(deadline);
      break;
    case "QUARTERLY":
      next_deadline = increment_quarterly(deadline);
      break;
    case "TRIANNUAL":
      next_deadline = increment_triannually(deadline);
      break;
    case "HALF_YEARLY":
      next_deadline = increment_bi_annually(deadline);
      break;
    case "YEARLY":
      next_deadline = increment_years(deadline, 1);
      break;
    case "SEMI_YEARLY":
      next_deadline = increment_years(deadline, 2);
      break;
    case "EVERY_3_YEARS":
      next_deadline = increment_years(deadline, 3);
      break;
    case "EVERY_4_YEARS":
      next_deadline = increment_years(deadline, 4);
      break;
    case "EVERY_5_YEARS":
      next_deadline = increment_years(deadline, 5);
      break;
    case "EVERY_8_YEARS":
      next_deadline = increment_years(deadline, 8);
      break;
  }

  // Set time to 23:00:00
  next_deadline.setHours(23);
  next_deadline.setMinutes(0);
  next_deadline.setSeconds(0);
  next_deadline.setMilliseconds(0);

  return next_deadline;
}

function increment_daily(deadline: Date): Date {
  // tomorrow - daily tasks can always be incremented from today
  const nextDay = addDays(deadline, 1);
  return nextDay;
}

function increment_daily_weekdays(deadline: Date): Date {
  // tomorrow (day after deadline), unless today/deadline is friday
  // (in which case in three days (monday)),
  // or saturday (in which case in two days (monday))
  const day_of_the_week = getDay(deadline);

  // 5 = friday
  if (day_of_the_week === 5) {
    const monday = addDays(deadline, 3);
    return monday;
  }

  // 6 = saturday
  if (day_of_the_week === 6) {
    const monday = addDays(deadline, 2);
    return monday;
  }

  const nextDay = addDays(deadline, 1);
  return nextDay;
}

function increment_weekly(deadline: Date): Date {
  // in 1 week - unless 1 week from deadline is today

  let next_deadline = deadline;

  const today = new Date();
  today.setHours(23);

  do {
    next_deadline = addWeeks(next_deadline, 1);

    // the next deadline can at the earliest be tomorrow
  } while (next_deadline < today);

  return next_deadline;
}

function increment_bi_weekly(deadline: Date): Date {
  // in 2 weeks - unless 2 weeks from deadline is today
  let next_deadline = deadline;

  const today = new Date();
  today.setHours(23);

  do {
    next_deadline = addWeeks(next_deadline, 2);

    // the next deadline can at the earliest be tomorrow
  } while (next_deadline < today);

  return next_deadline;
}

function increment_monthly(deadline: Date): Date {
  // in one month - unless one month after deadline is today.
  let next_deadline = deadline;

  const today = new Date();
  today.setHours(23);

  do {
    next_deadline = addMonths(next_deadline, 1);

    // the next deadline can at the earliest be tomorrow
  } while (next_deadline < today);

  // Avoid weekends by decrementing. Sat and Sun are set to Fri
  // Fri is chosen over Mon to avoid notifying on Sat/Sun for a task
  // to be completed on a Mon.
  next_deadline = get_non_weekend_deadline(next_deadline);

  return next_deadline;
}

function increment_bi_monthly(deadline: Date): Date {
  // in two months - unless two months from deadline is today
  let next_deadline = deadline;

  const today = new Date();
  today.setHours(23);

  do {
    next_deadline = addMonths(next_deadline, 2);

    // the next deadline can at the earliest be tomorrow
  } while (next_deadline < today);

  // Avoid weekends by decrementing. Sat and Sun are set to Fri
  // Fri is chosen over Mon to avoid notifying on Sat/Sun for a task
  // to be completed on a Mon.
  next_deadline = get_non_weekend_deadline(next_deadline);

  return next_deadline;
}

function increment_quarterly(deadline: Date): Date {
  // in three months - unless three months from deadline is today
  let next_deadline = deadline;

  const today = new Date();
  today.setHours(23);

  do {
    next_deadline = addMonths(next_deadline, 3);

    // the next deadline can at the earliest be tomorrow
  } while (next_deadline < today);

  // Avoid weekends by decrementing. Sat and Sun are set to Fri
  // Fri is chosen over Mon to avoid notifying on Sat/Sun for a task
  // to be completed on a Mon.
  next_deadline = get_non_weekend_deadline(next_deadline);

  return next_deadline;
}

function increment_triannually(deadline: Date): Date {
  // in four months - unless four months after deadline is today
  let next_deadline = deadline;

  const today = new Date();
  today.setHours(23);

  do {
    next_deadline = addMonths(next_deadline, 4);

    // the next deadline can at the earliest be tomorrow
  } while (next_deadline < today);

  // Avoid weekends by decrementing. Sat and Sun are set to Fri
  // Fri is chosen over Mon to avoid notifying on Sat/Sun for a task
  // to be completed on a Mon.
  next_deadline = get_non_weekend_deadline(next_deadline);

  return next_deadline;
}

function increment_bi_annually(deadline: Date): Date {
  // in 6 months - unless 6 months after deadline is today
  let next_deadline = deadline;

  const today = new Date();
  today.setHours(23);

  do {
    next_deadline = addMonths(next_deadline, 6);

    // the next deadline can at the earliest be tomorrow
  } while (next_deadline < today);

  // Avoid weekends by decrementing. Sat and Sun are set to Fri
  // Fri is chosen over Mon to avoid notifying on Sat/Sun for a task
  // to be completed on a Mon.
  next_deadline = get_non_weekend_deadline(next_deadline);

  return next_deadline;
}

function increment_years(deadline: Date, years = 1): Date {
  // in `years` - unless `years` number of years from deadline is today
  let next_deadline = deadline;

  const today = new Date();
  today.setHours(23);

  do {
    next_deadline = addYears(next_deadline, years);

    // the next deadline can at the earliest be tomorrow
  } while (next_deadline < today);

  // Avoid weekends by decrementing. Sat and Sun are set to Fri.
  // Fri is chosen over Mon to avoid notifying on Sat/Sun for a task
  // to be completed on a Mon.
  next_deadline = get_non_weekend_deadline(next_deadline);

  return next_deadline;
}

// Helpers
function get_non_weekend_deadline(deadline: Date): Date {
  // Avoid weekends by decrementing. Sat and Sun are set to Fri.
  // Fri is chosen over Mon to avoid notifying on Sat/Sun for a task
  // to be completed on a Mon.

  // if next_deadline weekday is saturday (6), decrement by 1 (Fri)
  if (getDay(deadline) === 6) {
    const friday = subDays(deadline, 1);
    return friday;
  }
  // if deadline weekday is sunday (0), decrement by 2 (Fri)
  if (getDay(deadline) === 0) {
    const friday = subDays(deadline, 2);
    return friday;
  }

  return deadline;
}
