import { Button, Form, FormSection } from "@onlion/components";
import { useState } from "react";
import useUser from "../../../data/useUser";
import { FormActionBar } from "../../../components/formActionBar";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "../../../components/icons/checkCircleIcon";
import { TextFieldset } from "../../../components/form/text";

export const SettingsSection = () => {
  const navigate = useNavigate();
  const { user, updateUserName } = useUser();

  const [name, setName] = useState(user.name);

  const handleSubmit = () => updateUserName(name);

  return (
    <Form onSubmit={() => {}}>
      <FormSection
        heading="Din information"
        info={
          <>
            <p>Ret din information</p>
            <p>Dit navn og email addresse kan ses i de konti du er bruger i.</p>
          </>
        }
      >
        <TextFieldset onChange={setName} value={name} label="Navn" id="name" />
      </FormSection>

      <FormActionBar>
        <Button className="mr-4" onClick={() => navigate(`/profile`)}>
          Fortryd
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          onClick={handleSubmit}
          icon={{ leading: CheckCircleIcon }}
        >
          Gem rettelser
        </Button>
      </FormActionBar>
    </Form>
  );
};
