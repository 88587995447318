import React, {
  Component,
  Fragment,
  FunctionComponent,
  ReactElement,
  ReactNode,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { XIcon } from "../../../components/icons/xIcon";
import classNames from "classnames";
import { Avatar } from "../../../components/avatar";
import useCompany from "../../../data/useCompany";

export interface NavigationItem {
  name: string;
  href: string;
  icon?: typeof Component | FunctionComponent;
  end?: boolean;
}

export interface NavProps {
  primary: { title?: ReactNode; items: NavigationItem[] }[];
  secondary: NavigationItem[];
  user: { name: string; href: string };
  sidebar: { sidebarOpen: boolean; setSidebarOpen: (next: boolean) => void };
}

export const Nav = ({
  primary,
  secondary,
  user,
  sidebar: { sidebarOpen, setSidebarOpen },
}: NavProps): ReactElement => {
  const { company } = useCompany();

  return (
    <Fragment>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white focus:outline-none">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute right-0 top-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Luk menu</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                <div className="flex flex-shrink-0 items-center px-4 font-semibold text-gray-500">
                  <div className="w-3 mr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 111.43 216.93"
                    >
                      <path
                        d="M111.43 116.99v-16.47a8.5 8.5 0 0 0-8.5-8.5h-5.31a4.24 4.24 0 0 1-3.68-2.13l-2.69-4.65c-.76-1.32-.76-2.94 0-4.25l2.82-4.89c2.35-4.07.95-9.27-3.11-11.62L76.7 56.24c-4.07-2.35-9.27-.95-11.62 3.11l-2.66 4.6a4.24 4.24 0 0 1-3.68 2.13h-5.37a4.24 4.24 0 0 1-3.68-2.13l-2.82-4.89c-2.35-4.07-7.55-5.46-11.62-3.11l-14.26 8.24c-4.07 2.35-5.46 7.55-3.11 11.62l2.66 4.6c.76 1.32.76 2.94 0 4.25l-2.69 4.65a4.24 4.24 0 0 1-3.68 2.13H8.53C3.81 91.44 0 95.25 0 99.95v16.47a8.5 8.5 0 0 0 8.5 8.5h5.31a4.24 4.24 0 0 1 3.68 2.13l2.69 4.65c.76 1.32.76 2.94 0 4.25l-2.82 4.89c-2.35 4.07-.95 9.27 3.11 11.62l14.26 8.24c4.07 2.35 9.27.95 11.62-3.11l2.66-4.6a4.24 4.24 0 0 1 3.68-2.13h5.37a4.24 4.24 0 0 1 3.68 2.13l2.82 4.89c2.35 4.07 7.55 5.46 11.62 3.11l14.26-8.24c4.07-2.35 5.46-7.55 3.11-11.62l-2.66-4.6c-.76-1.32-.76-2.94 0-4.25l2.69-4.65a4.24 4.24 0 0 1 3.68-2.13h5.64a8.5 8.5 0 0 0 8.5-8.5zm-53.64 16.96c-15.75 1.24-28.8-11.8-27.56-27.56.98-12.39 11.02-22.43 23.41-23.41 15.75-1.24 28.8 11.8 27.56 27.56-.98 12.39-11.02 22.43-23.41 23.41z"
                        fill="#1e3a8a"
                      />
                      <path
                        d="M91.54 30.23l16.28-21.67c3.55-4.72-2.09-10.84-7.09-7.7L67.74 21.65l-12.26 7.72c-3.04 1.92-2.95 6.38.16 8.17l40.33 23.29c2.86 1.65 6.51.67 8.16-2.19l3.93-6.81c1.65-2.86.67-6.51-2.19-8.16l-13.15-7.59a4 4 0 0 1-1.2-5.85zM19.89 186.7L3.61 208.37c-3.55 4.72 2.09 10.84 7.09 7.7l32.99-20.79 12.26-7.72c3.04-1.92 2.95-6.38-.16-8.17L15.46 156.1c-2.86-1.65-6.51-.67-8.16 2.19l-3.93 6.81c-1.65 2.86-.67 6.51 2.19 8.16l13.15 7.59a4 4 0 0 1 1.2 5.85z"
                        fill="#feae01"
                      />
                    </svg>
                  </div>
                  <span className="font-bold text-blue-900">
                    Tela <span className="font-medium">Vedligehold</span>
                  </span>
                </div>
                <nav aria-label="Sidebar" className="mt-5">
                  {primary.map(({ title, items }, idx) => (
                    <Fragment key={idx}>
                      <div className="space-y-1 px-2">
                        {title && (
                          <h5 className="px-2 py-1.5 text-xs font-medium uppercase text-gray-400">
                            {title}
                          </h5>
                        )}

                        {items.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            end={item.end}
                            className={({ isActive }) =>
                              classNames(
                                isActive
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                "group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                              )
                            }
                          >
                            {({ isActive }) => (
                              <Fragment>
                                {item.icon && (
                                  <item.icon
                                    strokeWidth={isActive ? 2 : 1.5}
                                    className={classNames(
                                      isActive
                                        ? "text-gray-500"
                                        : "text-gray-400 group-hover:text-gray-500",
                                      "mr-3 h-4 w-4 flex-shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                )}
                                {item.name}
                              </Fragment>
                            )}
                          </NavLink>
                        ))}
                      </div>
                      <hr
                        className="my-2 border-t border-gray-200"
                        aria-hidden="true"
                      />
                    </Fragment>
                  ))}
                  <div className="space-y-1 px-2">
                    {secondary.map((item: NavigationItem) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center rounded-md px-2 py-2 text-xs font-medium"
                          )
                        }
                      >
                        {({ isActive }) => (
                          <Fragment>
                            {item.icon && (
                              <item.icon
                                strokeWidth={isActive ? 2 : 1.5}
                                className={classNames(
                                  isActive
                                    ? "text-gray-500"
                                    : "text-gray-400 group-hover:text-gray-500",
                                  "mr-3 h-4 w-4 flex-shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            )}
                            {item.name}
                          </Fragment>
                        )}
                      </NavLink>
                    ))}
                  </div>
                </nav>
              </div>
              <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                <NavLink
                  to={user.href}
                  className="group block w-full flex-shrink-0"
                >
                  <div className="flex items-center">
                    <div>
                      {user.name
                        .split(" ")
                        .reduce(
                          (
                            initials: string,
                            name: string,
                            idx: number,
                            names: string[]
                          ) => {
                            if (idx === 0 || idx === names.length - 1)
                              return initials + name[0];
                            return initials;
                          },
                          ""
                        )}
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                        {user.name}
                      </p>
                      <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                        Se profil
                      </p>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:flex-shrink-0 shadow-lg">
        <div className="flex w-48 flex-col">
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-300 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
              <div className="flex flex-shrink-0 items-center px-2.5 font-semibold text-gray-500">
                <div className="w-3 mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 111.43 216.93"
                  >
                    <path
                      d="M111.43 116.99v-16.47a8.5 8.5 0 0 0-8.5-8.5h-5.31a4.24 4.24 0 0 1-3.68-2.13l-2.69-4.65c-.76-1.32-.76-2.94 0-4.25l2.82-4.89c2.35-4.07.95-9.27-3.11-11.62L76.7 56.24c-4.07-2.35-9.27-.95-11.62 3.11l-2.66 4.6a4.24 4.24 0 0 1-3.68 2.13h-5.37a4.24 4.24 0 0 1-3.68-2.13l-2.82-4.89c-2.35-4.07-7.55-5.46-11.62-3.11l-14.26 8.24c-4.07 2.35-5.46 7.55-3.11 11.62l2.66 4.6c.76 1.32.76 2.94 0 4.25l-2.69 4.65a4.24 4.24 0 0 1-3.68 2.13H8.53C3.81 91.44 0 95.25 0 99.95v16.47a8.5 8.5 0 0 0 8.5 8.5h5.31a4.24 4.24 0 0 1 3.68 2.13l2.69 4.65c.76 1.32.76 2.94 0 4.25l-2.82 4.89c-2.35 4.07-.95 9.27 3.11 11.62l14.26 8.24c4.07 2.35 9.27.95 11.62-3.11l2.66-4.6a4.24 4.24 0 0 1 3.68-2.13h5.37a4.24 4.24 0 0 1 3.68 2.13l2.82 4.89c2.35 4.07 7.55 5.46 11.62 3.11l14.26-8.24c4.07-2.35 5.46-7.55 3.11-11.62l-2.66-4.6c-.76-1.32-.76-2.94 0-4.25l2.69-4.65a4.24 4.24 0 0 1 3.68-2.13h5.64a8.5 8.5 0 0 0 8.5-8.5zm-53.64 16.96c-15.75 1.24-28.8-11.8-27.56-27.56.98-12.39 11.02-22.43 23.41-23.41 15.75-1.24 28.8 11.8 27.56 27.56-.98 12.39-11.02 22.43-23.41 23.41z"
                      fill="#1e3a8a"
                    />
                    <path
                      d="M91.54 30.23l16.28-21.67c3.55-4.72-2.09-10.84-7.09-7.7L67.74 21.65l-12.26 7.72c-3.04 1.92-2.95 6.38.16 8.17l40.33 23.29c2.86 1.65 6.51.67 8.16-2.19l3.93-6.81c1.65-2.86.67-6.51-2.19-8.16l-13.15-7.59a4 4 0 0 1-1.2-5.85zM19.89 186.7L3.61 208.37c-3.55 4.72 2.09 10.84 7.09 7.7l32.99-20.79 12.26-7.72c3.04-1.92 2.95-6.38-.16-8.17L15.46 156.1c-2.86-1.65-6.51-.67-8.16 2.19l-3.93 6.81c-1.65 2.86-.67 6.51 2.19 8.16l13.15 7.59a4 4 0 0 1 1.2 5.85z"
                      fill="#feae01"
                    />
                  </svg>
                </div>
                <span className="font-semibold text-blue-900">
                  Tela <span className="font-normal">Vedligehold</span>
                </span>
              </div>

              {company && (
                <p className="mt-2 uppercase text-xs font-medium px-2.5 break-all text-gray-600">
                  {company.name}
                </p>
              )}

              <nav className="mt-6 flex-1" aria-label="Sidebar">
                {primary.map(({ title, items }, idx) => (
                  <Fragment key={idx}>
                    <div className="space-y-1 px-2">
                      {title && (
                        <h5 className="px-2 py-1.5 text-xs font-medium uppercase text-gray-400">
                          {title}
                        </h5>
                      )}

                      {items.map((item: NavigationItem) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          end={item.end}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "text-blue-900 font-semibold bg-gray-50"
                                : "text-gray-500 hover:bg-gray-50 hover:text-gray-900",
                              "group flex items-center rounded px-2 py-1.5 text-sm font-medium"
                            )
                          }
                        >
                          {({ isActive }) => (
                            <Fragment>
                              {item.icon && (
                                <item.icon
                                  strokeWidth={isActive ? 1.5 : 1}
                                  className={classNames(
                                    isActive
                                      ? "text-blue-800"
                                      : "text-gray-600 group-hover:text-gray-500",
                                    "mr-3 h-4 w-4 flex-shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              )}
                              {item.name}
                            </Fragment>
                          )}
                        </NavLink>
                      ))}
                    </div>
                    <hr
                      className="my-2 border-t border-gray-200"
                      aria-hidden="true"
                    />
                  </Fragment>
                ))}

                <div className="flex-1 space-y-1 px-2">
                  {secondary.map((item: NavigationItem) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? "bg-white text-blue-900 font-semibold"
                            : "text-gray-500 hover:bg-white hover:text-gray-900",
                          "group flex items-center rounded-md px-2 py-2 text-xs font-medium"
                        )
                      }
                    >
                      {({ isActive }) => (
                        <Fragment>
                          {item.icon && (
                            <item.icon
                              strokeWidth={isActive ? 1.5 : 1}
                              className={classNames(
                                isActive
                                  ? "text-gray-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "mr-3 h-4 w-4 flex-shrink-0"
                              )}
                              aria-hidden="true"
                            />
                          )}
                          {item.name}
                        </Fragment>
                      )}
                    </NavLink>
                  ))}
                </div>
              </nav>
            </div>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              <NavLink
                to={user.href}
                className="group block w-full flex-shrink-0"
              >
                <div className="flex items-center">
                  <Avatar name={user.name} />
                  <div className="ml-">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      {user.name}
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      Se profil
                    </p>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
