import {
  AlertCircleIcon,
  Button,
  CheckCircleIcon,
  DashboardPages,
  FieldLabel,
  Form,
  FormHeading,
  FormSection,
  PlusIcon,
  TextFieldset,
  VerifiedIcon,
} from "@onlion/components";
import { is_email } from "@onlion/utils";
import React, { useEffect, useState } from "react";
import useCompany, { Company } from "../../../data/useCompany";
import useEquipment from "../../../data/useEquipment";
import useUser from "../../../data/useUser";
import { useBreadcrumbs } from "../../../state/useBreadcrumbs";
import { SubscriptionModal } from "./sections/subscriptionModal";
import { useUserRoleType } from "../../../components/hooks";

export const SettingsPage = () => {
  const { replaceBreadcrumbs } = useBreadcrumbs();
  useEffect(
    () => replaceBreadcrumbs([{ text: "Indstillinger" }]),
    [replaceBreadcrumbs]
  );
  const { loading, company, update } = useCompany();
  const { user } = useUser();
  const { isSuperAdmin } = useUserRoleType(user);
  const { equipment } = useEquipment();
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

  const [companyName, setCompanyName] = useState("");
  const [vatNumber, setVatNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [companyInfoHasChanged, setCompanyInfoHasChanged] = useState(false);
  const [companyInfoIsValid, setCompanyInfoIsValid] = useState(false);
  const [companyLoading, setCompanyLoading] = useState(false);

  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [userInfoIsValid, setUserInfoIsValid] = useState(false);

  useEffect(() => {
    if (company) {
      setCompanyName(company.name);
      setVatNumber(company.vatNumber ?? "");
      setAddress(company.address ?? "");
      setCity(company.city ?? "");
    }

    if (user) {
      setName(user.name);
      setEmailAddress(user.emailAddress);
    }
  }, [company]);

  useEffect(() => {
    const companyNameIsValid = companyName.length > 0;
    const vatNumberIsValid = vatNumber.length === 8;
    const addressIsValid = address.length > 0;
    const cityIsValid = city.length > 0;
    const allCompanyInfoIsValid =
      companyNameIsValid && vatNumberIsValid && addressIsValid && cityIsValid;

    setCompanyInfoIsValid(allCompanyInfoIsValid);
  }, [companyName, vatNumber, address, city]);

  useEffect(() => {
    const nameIsValid = name.length > 0;
    const emailAddressIsValid = is_email(emailAddress);
    const allUserInfoIsValid = nameIsValid && emailAddressIsValid;

    setUserInfoIsValid(allUserInfoIsValid);
  }, [name, emailAddress]);

  if (loading || !user || !company || !equipment) return null;

  return (
    <div>
      <div className="p-2 sm:p-8 bg-gray-200 min-h-screen">
        <DashboardPages.Form title="Abonnement" className="mb-20 md:mb-40">
          <Form onSubmit={() => {}}>
            <FormHeading>Abonnement</FormHeading>
            <FormSection
              heading="Information"
              info={
                <>
                  <p className="mb-2">Ret informationen om din virksomhed.</p>
                  <p>
                    Hvis du har et abonnement, bruger vi CVR-nummer og adresse
                    til fakturering.
                  </p>
                </>
              }
            >
              <TextFieldset
                id="companyName"
                valid={companyName.length > 0}
                label="Virksomhedens navn"
                value={companyName}
                onChange={(v) => {
                  setCompanyName(v);
                  setCompanyInfoHasChanged(true);
                }}
                disabled={!isSuperAdmin}
              />
              <TextFieldset
                id="vatNumber"
                valid={vatNumber.length === 8}
                label="CVR-nummer"
                value={vatNumber}
                onChange={(v) => {
                  setVatNumber(v);
                  setCompanyInfoHasChanged(true);
                }}
                disabled={!isSuperAdmin}
              />
              <TextFieldset
                id="address"
                valid={address.length > 0}
                label="Adresse"
                value={address}
                onChange={(v) => {
                  setAddress(v);
                  setCompanyInfoHasChanged(true);
                }}
                autoComplete="street-address"
                disabled={!isSuperAdmin}
              />
              <TextFieldset
                id="city"
                valid={city.length > 0}
                label="By"
                value={city}
                onChange={(v) => {
                  setCity(v);
                  setCompanyInfoHasChanged(true);
                }}
                autoComplete="street-address"
                disabled={!isSuperAdmin}
              />

              <div className="flex flex-row justify-end">
                <Button
                  disabled={!companyInfoHasChanged || !companyInfoIsValid}
                  type={
                    companyInfoHasChanged && companyInfoIsValid
                      ? "primary"
                      : undefined
                  }
                  htmlType="submit"
                  loading={companyLoading}
                  onClick={async () => {
                    setCompanyLoading(true);
                    await update({
                      id: company.id,
                      name: companyName,
                      vatNumber,
                      address,
                      city,
                    });
                    setCompanyLoading(false);
                    setCompanyInfoHasChanged(false);
                  }}
                >
                  Gem rettelser
                </Button>
              </div>
            </FormSection>

            <FormSection
              heading="Abonnement"
              info={
                "Support er gratis via telefon og e-mail, uanset abonnement."
              }
            >
              <div>
                {!canUpgrade(company) && (
                  <div
                    className={`w-full md:w-96 mb-4 bg-yellow-50 rounded-lg px-6 py-4 text-yellow-700 
              border border-yellow-200 font-semibold text-sm flex flex-row items-center`}
                  >
                    <div>
                      <AlertCircleIcon className="w-6 h-6 mr-4 inline-block" />
                    </div>
                    <span>
                      Du skal udfylde information om din virksomhed før du kan
                      opgradere dit abonnement
                    </span>
                  </div>
                )}

                <FieldLabel htmlFor="" className="mb-2">
                  Dit abonnement
                </FieldLabel>

                {company.subscription === "FREE" && (
                  <>
                    <div
                      className={`md:w-96 px-6 py-4 bg-gray-50 rounded-lg border border-gray-200 `}
                    >
                      <div className="flex flex-row items-center justify-between mb-2">
                        <h3
                          className={`uppercase tracking-widest text-xs font-semibold text-gray-600 
                  flex flex-row items-center`}
                        >
                          Gratis{" "}
                          <CheckCircleIcon className="w-4 h-4 ml-2 text-blue-800" />
                        </h3>
                        <span className="uppercase text-xs">
                          0 kr. per måned
                        </span>
                      </div>
                      <p className={`font-semibold text-lg`}>
                        10 stykker udstyr
                      </p>
                    </div>
                    <p className="my-4 text-xs ml-4">opgrader til</p>
                  </>
                )}

                <div
                  onClick={() => {
                    if (canUpgrade(company) && isSuperAdmin) {
                      setShowUpgradeModal(true);
                    }
                  }}
                  className={`w-full md:w-96 px-6 py-4 bg-gradient-to-tl from-blue-800 to-blue-900 
              rounded-lg border border-gray-100 relative ${
                canUpgrade(company) && isSuperAdmin && "cursor-ponter"
              }`}
                >
                  <div className="absolute wave-pattern w-full h-full -ml-6 -mt-4"></div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <h3
                      className={`uppercase tracking-widest text-xs font-semibold text-sky-300 
                  flex flex-row items-center`}
                    >
                      Ubegrænset{" "}
                      <VerifiedIcon className="w-5 h-5 ml-2 text-sky-300" />
                    </h3>
                    <span className="uppercase text-xs text-sky-200">
                      399 kr. per måned
                    </span>
                  </div>
                  <p className={`font-semibold text-lg text-white`}>
                    Alt dit udstyr
                  </p>
                </div>
              </div>

              {company.subscription === "FREE" && isSuperAdmin && (
                <>
                  <div className=" flex flex-row justify-end">
                    <Button
                      disabled={!canUpgrade(company)}
                      type="primary"
                      onClick={() => setShowUpgradeModal(true)}
                    >
                      <span>Opgrader til ubegrænset</span>
                      <PlusIcon className="w-4 h-4 ml-2" />
                    </Button>
                  </div>
                </>
              )}
              {company.subscription !== "FREE" && (
                <div className="w-full flex flex-row justify-end">
                  <Button onClick={() => setShowUpgradeModal(true)}>
                    Ret abonnement
                  </Button>
                </div>
              )}
            </FormSection>

            <SubscriptionModal
              isOpen={showUpgradeModal}
              setIsOpen={setShowUpgradeModal}
            />
          </Form>
        </DashboardPages.Form>
      </div>
    </div>
  );
};

const canUpgrade = (company: Company): boolean => {
  const companyNameIsValid = company.name.length > 0;
  const vatNumberIsValid = company.vatNumber?.length === 8;
  const addressIsValid = company.address ? company.address.length > 0 : false;
  const cityIsValid = company.city ? company.city.length > 0 : false;
  return (
    companyNameIsValid && vatNumberIsValid && addressIsValid && cityIsValid
  );
};
