import { useEffect, useState } from "react";
import { get } from "../components/requests/requests";
import { Task } from "./useTasks";
import { useParams } from "react-router-dom";

export const useTaskQuery = (params: { userId?: string }) => {
  const { companyId } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [hasFailed, setHasFailed] = useState<boolean>(false);

  const { userId } = params;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (!companyId) {
          setHasFailed(true);
          return;
        }

        const response = await get("task-query", { companyId, userId });
        if (response) {
          setTasks(response.tasks);
        } else {
          setHasFailed(true);
        }
      } catch (err) {
        console.error(err);
        setHasFailed(true);
      }
    };

    fetchData();
  }, [companyId, userId]);

  return { tasks, isLoading, hasFailed };
};
