import { FunctionComponent, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useBreadcrumbs } from "../../../state/useBreadcrumbs";
import TasksTablePage from "./pages/table/table";
import { TaskFocusPage } from "./pages/focus";
import { TaskCreatePage } from "./pages/create";
import { TaskUpdatePage } from "./pages/update";
import { TaskHistoryPage } from "./pages/history";

const TasksPage: FunctionComponent = () => {
  const { replaceBreadcrumbs } = useBreadcrumbs();
  useEffect(
    () => replaceBreadcrumbs([{ text: "Opgaver" }]),
    [replaceBreadcrumbs]
  );

  return (
    <div>
      <Routes>
        <Route index element={<TasksTablePage />} />
        <Route path="list" element={<TasksTablePage />} />
        {[":id/*", "list/:id/*"].map((p) => (
          <Route key={p} path={p} element={<TaskFocusPage />} />
        ))}
        {["update/:id", "list/update/:id"].map((p) => (
          <Route path={p} element={<TaskUpdatePage />} />
        ))}
        <Route path="create" element={<TaskCreatePage />} />
        <Route path="history" element={<TaskHistoryPage />} />
      </Routes>
    </div>
  );
};

export default TasksPage;
