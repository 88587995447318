import { ReactNode } from "react";

export const NoData = ({
  show,
  children,
  className = "",
  color = "blue",
}: {
  children: ReactNode;
  className?: string;
  show: boolean;
  color?: "blue" | "green";
}) => {
  const bgColor = color === "blue" ? "from-blue-50" : "from-green-50";
  const textColor = color === "blue" ? "text-blue-900" : "text-green-600";
  return (
    <div
      className={`flex flex-row p-8 mt-2 rounded-lg  
items-center justify-center font-semibold ${textColor} 
bg-gradient-to-b ${bgColor} ${show ? "flex" : "hidden"} ${className}`}
    >
      {children}
    </div>
  );
};
