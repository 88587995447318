import { NoteResponse } from "../../../data/notes/types";
import { HeaderAndContent } from "./headerAndContent";

type ResponseProps = {
  response: NoteResponse;
};

export const Response = ({
  response: { id, content, datetime, author, files },
}: ResponseProps) => (
  <div
    className="bg-white overflow-hidden border border-gray-300 rounded"
    id={id}
  >
    <HeaderAndContent
      data={{
        content,
        datetime,
        author,
        files,
      }}
    />
  </div>
);
