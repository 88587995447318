import React from "react";
import {
  AlertCircleIcon,
  AlertTriangleIcon,
  ArrowLeftRightIcon,
  Button,
  Modal,
} from "@onlion/components";
import { useNavigate, useParams } from "react-router-dom";
import { formatName } from "../../../../../../components/formatName";
import { ModalContent } from "../../../../../../components/modalContent";
import { User } from "../../../../../../data/users/types";
import { useDeleteUser } from "../../../../../../data/users/useDeleteUser";
import { useTaskQuery } from "../../../../../../data/useTaskQuery";

interface DeleteModalProps {
  user: User;
  isOpen: boolean;
  setIsOpen: () => void;
}

export const DeleteModal = ({ isOpen, setIsOpen, user }: DeleteModalProps) => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { deleteUser, loading } = useDeleteUser();
  const { tasks } = useTaskQuery({ userId: user.id });
  const noOfTasks = tasks?.length ?? 0;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      {noOfTasks === 0 && (
        <ModalContent
          Icon={AlertTriangleIcon}
          iconColor={"yellow"}
          heading={`Slet ${user.name}?`}
          content={"Dette er permanent og kan ikke fortrydes."}
          buttons={
            <>
              <Button className="mr-4" onClick={setIsOpen}>
                Fortryd
              </Button>
              <Button
                type="danger"
                loading={loading}
                onClick={() => {
                  navigate(`/dashboard/${companyId}/users`);
                  deleteUser(user.id);
                }}
              >
                <AlertCircleIcon className={"w-4 h-4 mr-2"} /> Slet permanent
              </Button>
            </>
          }
        />
      )}

      {noOfTasks > 0 && (
        <ModalContent
          Icon={ArrowLeftRightIcon}
          iconColor={"blue"}
          heading={`${formatName(user.name, {
            lastName: true,
          })} kan ikke slettes`}
          content={`${formatName(user.name)} er ansvarlig for en eller flere
          opgaver. Overfør først ansvaret for ${formatName(
            user.name
          )}s opgaver.`}
          buttons={
            <>
              <Button className="mr-4" onClick={setIsOpen}>
                Fortryd
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  navigate(`/dashboard/${companyId}/users/transfer/${user.id}`);
                }}
              >
                <ArrowLeftRightIcon className={"w-4 h-4 mr-2"} /> Overfør ansvar
              </Button>
            </>
          }
        />
      )}
    </Modal>
  );
};
