export const getInitials = (name: string): string => {
  if (!name) return name;
  return name
    .trim()
    .split(" ")
    .reduce(
      (initials, name, idx, names) =>
        idx === 0 || idx === names.length - 1 ? initials + name[0] : initials,
      ""
    );
};
