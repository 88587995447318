import useSWR, { mutate } from "swr";
import axios from "axios";
import { AUTH_TOKEN_KEY, BASE_URL } from "../constants";
import { patch } from "../components/requests/requests";
import { notify } from "../dashboard/components/notifications/newNotification";
import { User, UserChangeSet } from "./users/types";
import { setAuthorizationHeader } from "../auth/utils/setAuthorizationHeader";
import { useParams } from "react-router-dom";

const userFetcher = async () => {
  try {
    const user_result = await axios.get(`${BASE_URL}/user`);

    if (user_result.status !== 200) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      localStorage.removeItem(USE_USER_KEY);
      delete axios.defaults.headers.common["authorization"];
      mutate(USE_USER_KEY, null, true);
    }

    const data: any = user_result.data;

    // axios.defaults.headers.common["authorization"] = data.token;
    setAuthorizationHeader(data.token);

    return data.user;
  } catch (err) {
    let error = new Error("Not authorized!") as any;
    error.status = 403;
    throw error;
  }
};

const key = "api/user";
export const USE_USER_KEY = key;

// hydrate from localstorage
if (typeof window !== "undefined") {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  if (token) setAuthorizationHeader(token); // axios.defaults.headers.common["authorization"] = token;
}

export default function useUser() {
  const { companyId } = useParams();
  const { data, mutate, error } = useSWR(key, userFetcher);

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  const updateUser = async (user: UserChangeSet): Promise<boolean> => {
    const success = await patch("user", user, { companyId });
    if (success) {
      mutate({ ...data, ...user });
      notify({
        heading: "Din bruger blev rettet",
        text: "Ændringen kan ses med det samme.",
      });
      return true;
    } else {
      notify({
        heading: "Din bruger kunne ikke rettes",
        text: "Prøv igen. Eller kontakt os.",
        type: "alert",
      });
      return false;
    }
  };

  const updateUserName = async (name: string): Promise<boolean> => {
    const success = await patch("user/name", { name }, { companyId });
    if (success) {
      mutate(key);
      notify({
        heading: "Din navn blev rettet",
        text: "Ændringen kan ses med det samme.",
      });
      return true;
    } else {
      notify({
        heading: "Dit navn kunne ikke rettes",
        text: "Prøv igen. Eller kontakt os.",
        type: "alert",
      });
      return false;
    }
  };

  return {
    loading,
    loggedOut,
    user: data as User,
    updateUser,
    updateUserName,
    mutate,
  };
}

export const logOut = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(USE_USER_KEY);
  delete axios.defaults.headers.common["authorization"];
  mutate(USE_USER_KEY, null, true);
};
