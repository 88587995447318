import { useEffect, useMemo, useState } from "react";
import { TextFieldset } from "../form/text";
import { Upload } from "../form/upload";
import { Button } from "../button";
import SelectFieldset from "../form/select";
import { SelectOption } from "../form/select/input";
import { User } from "../../data/users/types";
import { Radio } from "../form/radio";

type NoteOrNoteResponseFormProps = {
  cancelFunc: () => void;
  submitFunc: (formData: {
    content: string;
    files: File[];
    recipientIds: string[];
    core?: boolean;
  }) => Promise<boolean>;
  loading: boolean;
  users: User[];
  usersId: string;
  copy: {
    contentLabel: string;
    recipientsLabel: string;
    recipientsHelp: string;
    submitLabel: string;
  };
  askForCore?: boolean;
};

export const NoteOrNoteResponseForm = ({
  cancelFunc,
  submitFunc,
  loading,
  users,
  usersId,
  copy,
  askForCore = false,
}: NoteOrNoteResponseFormProps) => {
  const [valid, setValid] = useState(false);
  const [content, setContent] = useState<string>("");
  const [files, setFiles] = useState<File[]>([]);
  const [recipients, setRecipients] = useState<SelectOption[]>([]);
  const [core, setCore] = useState<"YES" | "NO">("NO");

  useEffect(() => setValid(content.length > 1), [setValid, content]);

  const handleSubmit = async () => {
    const success = await submitFunc({
      content,
      files,
      recipientIds: recipients.map((r) => r.value.toString()),
      core: core === "NO" ? false : true,
    });
    if (success) cancelFunc();
  };

  const selectableRecipients = useMemo(
    () =>
      users
        .map((u) => ({
          label: u.name,
          value: u.id,
        }))
        .filter(
          (u) =>
            u.value !== usersId &&
            !recipients.map((r) => r.value).includes(u.value)
        ),
    [users, recipients]
  );

  const canChooseRecipient = users.length > 1;

  return (
    <div className="bg-white overflow-hidden border border-gray-300 rounded min-w-full w-full px-4 py-3">
      <div className="mx-auto flex flex-col gap-6">
        <TextFieldset
          id="content"
          label={copy.contentLabel}
          placeholder=""
          value={content}
          valid={content.length > 0}
          required
          rows={3}
          autoFocus
          autoComplete="off"
          onChange={setContent}
        />

        <hr />

        {canChooseRecipient && (
          <div className="flex flex-col gap-2">
            <SelectFieldset
              id="recipientIds"
              label={copy.recipientsLabel}
              selected={null}
              help={copy.recipientsHelp}
              onChange={(s) => setRecipients([...recipients, s])}
              options={selectableRecipients}
              required={false}
              disabled={loading}
            />
            {/* CHOSEN RECIPIENTS */}
            {recipients.length > 0 && (
              <div className="w-full space-y-1.5 md:w-96 text-gray-800">
                <p className="font-medium">Valgte modtagere</p>
                <ul className="flex flex-col gap-2">
                  {recipients.map((r) => (
                    <li className="flex justify-between border-b">
                      <span>{r.label}</span>
                      <span
                        className="underline cursor-pointer hover:text-gray-900"
                        onClick={() =>
                          setRecipients([
                            ...recipients.filter((cr) => cr.value !== r.value),
                          ])
                        }
                      >
                        Fjern
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}

        {askForCore && (
          <>
            <hr />
            <Radio
              options={[
                { label: "Ja, dette er en stamnote", value: "YES" },
                { label: "Nej, dette er en normal note", value: "NO" },
              ]}
              label="Er dette en stamnote?"
              value={core}
              onChange={setCore}
              help="Stamnoter er permanent information om udstyr, såsom indkøbspris, eller større reperationer."
            />
          </>
        )}

        <hr />

        <Upload
          files={files}
          setFiles={setFiles}
          label="Vedhæft fil (valgfrit)"
        >
          Vælg fil
        </Upload>

        <hr />

        <div className="flex justify-end gap-4">
          <Button onClick={cancelFunc}>Fortryd</Button>
          <Button
            disabled={!valid}
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            {copy.submitLabel}
          </Button>
        </div>
      </div>
    </div>
  );
};
