import { Task } from "../../../../../data/useTasks";
import format from "date-fns/format";
import isSameMonth from "date-fns/isSameMonth";
import { Day } from "@onlion/components/dist/components/timeline/multiEventTimeline";
import { ChevronRightIcon, ZapIcon, ZapOffIcon } from "@onlion/components";
import { Link } from "react-router-dom";
import { incrementDeadline } from "../../../../../components/incrementDeadline";

export type PerType = {
  overdue: Day[];
  due: Day[];
  done: Day[];
};

export function splitTasksPerType(tasks: Task[], companyId: string): PerType {
  return {
    overdue: getOverdue(tasks, companyId),
    due: getDue(tasks, companyId),
    done: [],
  };
}

function getOverdue(tasks: Task[], companyId: string): Day[] {
  const overdueMap = tasks.reduce((all: { [key: string]: Task[] }, task) => {
    if (task.status == "OVERDUE") {
      const d = format(new Date(task.deadline), "yyyy-MM-dd");
      if (all[d]) {
        return { ...all, [d]: [...all[d], task] };
      } else {
        return { ...all, [d]: [task] };
      }
    } else {
      return all;
    }
  }, {});

  return Object.entries(overdueMap).map(([date, tasks]) => ({
    date: new Date(date),
    icon: ZapOffIcon,
    color: "bg-yellow-500",
    events: tasks.map((t) => (
      <Link to={`/dashboard/${companyId}/tasks/${t.id}`}>
        <span className="text-gray-800 font-medium block text-sm">
          {t.title}
        </span>
        <div className="space-x-2 text-xs text-gray-600">
          <span>{t.equipment.name}</span> <span>&middot;</span>
          <span>{t.responsible.name}</span>
        </div>
      </Link>
    )),
  }));
}

function getDue(tasks: Task[], companyId: string): Day[] {
  const includeFutureTasks = tasks
    .reduce((nextTasks: Task[], t) => {
      const task = { ...t, deadline: new Date(t.deadline) };
      const now = new Date();
      if (!isSameMonth(now, task.deadline)) return nextTasks;
      if (!task.interval) return [...nextTasks, task];

      let futures = [task];
      let next = {
        ...task,
        deadline: incrementDeadline(task.deadline, task.interval),
      };

      while (isSameMonth(now, next.deadline)) {
        futures.push(next);
        next = {
          ...next,
          deadline: incrementDeadline(next.deadline, task.interval),
        };
      }

      return [...nextTasks, ...futures];
    }, [])
    .sort((a, b) => (a.deadline > b.deadline ? -1 : 1));

  const dueMap = includeFutureTasks.reduce(
    (all: { [key: string]: Task[] }, task) => {
      if (task.status == "ACTIVE" || task.status === "CURRENT") {
        const d = format(new Date(task.deadline), "yyyy-MM-dd");
        if (all[d]) {
          return { ...all, [d]: [...all[d], task] };
        } else {
          return { ...all, [d]: [task] };
        }
      } else {
        return all;
      }
    },
    {}
  );

  return Object.entries(dueMap).map(([date, tasks]) => ({
    date: new Date(date),
    icon: ZapIcon,
    color: "bg-blue-500",
    events: tasks.map((t) => (
      <Link to={`/dashboard/${companyId}/tasks/${t.id}`}>
        <span className="text-gray-800 font-medium text-sm flex items-center">
          {t.title} <ChevronRightIcon className="w-3 ml-0.5" />
        </span>
        <div className="space-x-2 text-xs text-gray-600">
          <span>{t.equipment.name}</span> <span>&middot;</span>
          <span>{t.responsible.name}</span>
        </div>
      </Link>
    )),
  }));
}
