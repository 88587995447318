import React, { Component, FunctionComponent, ReactNode } from "react";

export interface ElemWithChildren {
  children: ReactNode;
}

export const Box = ({
  children,
  className,
}: { className?: string } & ElemWithChildren) => (
  <div className={`bg-white shadow rounded-lg p-4 ${className}`}>
    {children}
  </div>
);

export const BoxHeading = ({ children }: ElemWithChildren) => (
  <h3 className="font-medium text-gray-800 flex items-center">{children}</h3>
);

type IconColor = "red" | "blue" | "green" | "yellow" | "gray";

interface BoxHeaderProps {
  heading: string;
  count?: number;
  text: string;
  Icon: typeof Component | FunctionComponent;
  iconColor?: IconColor;
}

export const BoxHeader = ({
  heading,
  count,
  text,
  Icon,
  iconColor = "gray",
}: BoxHeaderProps) => (
  <div className="flex text-gray-800 items-center">
    <div>
      <div className={`p-2.5 mr-4 rounded-lg ${getIconBgColor(iconColor)}`}>
        <Icon className={`w-6 ${getIconColor(iconColor)}`} />
      </div>
    </div>
    <div>
      <h3 className="text-gray-800 font-semibold flex items-center">
        {heading}{" "}
        {count !== undefined && count > 0 && (
          <span className="bg-gray-100 flex items-center justify-center font-medium ml-2 text-xs rounded px-2 py-px">
            {count}
          </span>
        )}
      </h3>
      <p className="text-xs text-gray-600">{text}</p>
    </div>
  </div>
);

export function getIconBgColor(ic: IconColor): string {
  switch (ic) {
    case "blue":
      return "bg-blue-50";
    case "green":
      return "bg-green-50";
    case "red":
      return "bg-red-50";
    case "yellow":
      return "bg-yellow-50";
    case "gray":
      return "bg-gray-50";
  }
}

export function getIconColor(ic: IconColor): string {
  switch (ic) {
    case "blue":
      return "text-blue-500";
    case "green":
      return "text-green-500";
    case "red":
      return "text-red-500";
    case "yellow":
      return "text-yellow-500";
    case "gray":
      return "text-gray-500";
  }
}
