export interface FormatNameOptions {
  lastName: boolean;
}

export function formatName(
  name: string,
  { lastName }: FormatNameOptions = { lastName: false }
): string {
  if (!name) return "";
  const nameArr = name.split(" ");
  const fName = nameArr[0];
  if (lastName === false || nameArr.length === 1) return fName;

  const lName = nameArr[nameArr.length - 1];
  const lNameLetter = lName ? lName[0].toUpperCase() : "";
  return `${fName} ${lNameLetter}.`;
}
