import React from "react";

export const highlightMatched = ({
  str,
  toMatch,
}: {
  str: string;
  toMatch?: string;
}) => {
  if (!toMatch || toMatch.length === 0) return str;

  const bigrams = makeNGrams(2, toMatch);
  const elements = highlight(bigrams, toMatch, str);

  return <>{elements}</>;
};

function highlight(bigrams: string[], toMatch: string, str: string) {
  return str.split("").map((bg, i) => {
    const prevBigram =
      i == 0 ? null : str.substring(i - 1, i + 1).toLowerCase();
    const nextBigram =
      i == str.length - 1 ? null : str.substring(i, i + 2).toLowerCase();
    const matches =
      (prevBigram && bigrams.includes(prevBigram)) ||
      (nextBigram && bigrams.includes(nextBigram));

    return matches ? (
      <span key={bg + "" + i} className="text-blue-800 font-semibold">
        {bg}
      </span>
    ) : (
      bg
    );
  });
}

function makeNGrams(n: number, string: string) {
  const grams = [];
  for (let i = 0; i <= string.length - n; i++) {
    const gram = string.substr(i, n);
    grams.push(gram);
  }

  return grams;
}
