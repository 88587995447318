import { FunctionComponent } from "react";
import { Button, DashboardPages, LogInIcon } from "@onlion/components";
import { useNavigate } from "react-router-dom";

const LoggedOutPage: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <DashboardPages.Standard
      title="Logget ud"
      className="mx-auto md:w-8/12 lg:w-1/2 xl:w-1/3 md:mr-auto shadow-xl"
    >
      <div
        className={`py-8 px-12 mb-8 bg-blue-700 rounded-lg text-white text-center`}
      >
        <h2 className="font-semibold text-blue-200 text-sm uppercase tracking-wide mb-2">
          På gensyn
        </h2>
        <h1 className="font-bold text-xl text-center mx-auto flex items-center">
          <span>Du er nu logget ud fra Tela Vedligehold på denne computer</span>
        </h1>
      </div>

      <div className="flex flex-col md:flex-row items-center justify-center">
        <p>Vil du logge ind igen?</p>
        <Button type="primary" onClick={() => navigate("/auth")}>
          Log ind <LogInIcon className="w-4 h-4 ml-2" />
        </Button>
      </div>
    </DashboardPages.Standard>
  );
};

export default LoggedOutPage;
