import React from "react";
import {
  Badge,
  ZapIcon,
  RefreshcwIcon,
  ZapOffIcon,
  SearchIcon,
  FieldLabel,
} from "@onlion/components";
import { TaskType, TaskStub } from "../../../../../../data/useEquipment";
import { useNavigate, useParams } from "react-router-dom";
import { intervalToString } from "../../../../../../components/intervalToString";
import { mapStatus } from "../../../../../../components/mapStatus";
import { NoData } from "../../../../../components/noData";
import { useTable } from "../../../../../../components/useTable";
import { highlightMatched } from "../../../../../../components/highlightMatched";
import Input from "../../../../../../components/form/text/input";
import { Table } from "../../../../../../components/table";

const Tasks = ({
  tasks,
  id,
  isAdmin,
}: {
  tasks: TaskStub[];
  id: string;
  isAdmin: boolean;
}) => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const { data, sort, sorting, query, setQuery } = useTable(
    tasks,
    `equipment/tasks/${id}`
  );

  if (!tasks || tasks.length === 0)
    return (
      <NoData show>
        <ZapOffIcon className="w-4 h-4 mr-2" />{" "}
        <p className="text-lg">Udstyret har ingen opgaver endnu</p>
      </NoData>
    );

  return (
    <>
      <div className="flex flex-col mt-2 items-end space-y-4 sm:space-x-8 sm:space-y-0 sm:flex-row">
        <div>
          <FieldLabel htmlFor={id}>Søg i opgaver</FieldLabel>
          <Input
            value={query !== "undefined" ? query : ""}
            onChange={setQuery}
            id={id}
            placeholder={"Søg i opgaver"}
            clearable
            Icon={SearchIcon}
            short
          />
        </div>
      </div>
      <Table
        className="mt-4 pb-2"
        sorting={sorting ?? { key: "", ascending: false }}
        head={[
          { content: "Opgave", key: "title", onClick: () => sort("title") },
          { content: "Ansvarlig" },
          { content: "Type" },
          {
            content: "Status",
            hideMobile: false,
            right: true,
          },
        ]}
        row={{
          onClick: isAdmin
            ? ({ id }) => navigate(`/dashboard/${companyId}/tasks/${id}`)
            : undefined,
          fields: [
            {
              renderData: ({ title }) =>
                highlightMatched({ str: title, toMatch: query }),
            },
            {
              renderData: ({ responsibleName }) => responsibleName,
            },
            {
              renderData: ({ type, interval }) => (
                <>
                  {getType(type)}
                  {interval && (
                    <p className="text-xs">
                      Udføres {intervalToString(interval)}
                    </p>
                  )}
                </>
              ),
            },
            {
              renderData: ({ status }) => (
                <Badge className="ml-auto" type={mapStatus(status).color}>
                  {mapStatus(status).label}
                </Badge>
              ),
              hideMobile: false,
              right: true,
            },
          ],
        }}
        data={data}
      />
    </>
  );
};

function getType(type: TaskType) {
  switch (type) {
    case "CYCLICAL":
      return (
        <p className="flex items-center">
          <RefreshcwIcon className="w-3 h-3 mr-2" /> Rutine
        </p>
      );
    case "AD_HOC":
      return (
        <p className="flex items-center">
          <ZapIcon className="w-3 h-3 mr-2" /> Enkelt
        </p>
      );
  }
}

export default Tasks;
