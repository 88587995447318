import React, { FunctionComponent, useState } from "react";
import "./layout.css";

import useUser from "../../../data/useUser";
import { getNavItems } from "./navItems";
import { Nav } from "./nav";
import { MenuIcon } from "../../../components/icons/menu";
import useEquipment from "../../../data/useEquipment";
import { useUserRoleType } from "../../../components/hooks";

type DashboardLayoutProps = {
  children: React.ReactNode;
};

const DashboardLayout: FunctionComponent<DashboardLayoutProps> = ({
  children,
}) => {
  const { equipment } = useEquipment();
  const { user } = useUser();
  const { type } = useUserRoleType(user);
  const { primary, secondary } = getNavItems({
    user,
    canAddTask: equipment && equipment.length > 0,
    userRoleType: type,
  });
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  return (
    <div className="flex h-full max-h-screen min-h-screen overflow-y-auto">
      <Nav
        sidebar={{ sidebarOpen, setSidebarOpen }}
        primary={primary}
        secondary={secondary}
        user={{ name: user.name, href: "/profile" }}
      />
      {/* MOBILE MENU BAR + CONTENT */}
      <div className="flex min-w-0 flex-1 flex-col overflow-y-auto overflow-x-hidden">
        <div className="lg:hidden">
          <div className="flex items-center justify-between border-b border-gray-200 bg-gray-50 px-4 py-1.5">
            <div className="w-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 111.43 216.93"
              >
                <path
                  d="M111.43 116.99v-16.47a8.5 8.5 0 0 0-8.5-8.5h-5.31a4.24 4.24 0 0 1-3.68-2.13l-2.69-4.65c-.76-1.32-.76-2.94 0-4.25l2.82-4.89c2.35-4.07.95-9.27-3.11-11.62L76.7 56.24c-4.07-2.35-9.27-.95-11.62 3.11l-2.66 4.6a4.24 4.24 0 0 1-3.68 2.13h-5.37a4.24 4.24 0 0 1-3.68-2.13l-2.82-4.89c-2.35-4.07-7.55-5.46-11.62-3.11l-14.26 8.24c-4.07 2.35-5.46 7.55-3.11 11.62l2.66 4.6c.76 1.32.76 2.94 0 4.25l-2.69 4.65a4.24 4.24 0 0 1-3.68 2.13H8.53C3.81 91.44 0 95.25 0 99.95v16.47a8.5 8.5 0 0 0 8.5 8.5h5.31a4.24 4.24 0 0 1 3.68 2.13l2.69 4.65c.76 1.32.76 2.94 0 4.25l-2.82 4.89c-2.35 4.07-.95 9.27 3.11 11.62l14.26 8.24c4.07 2.35 9.27.95 11.62-3.11l2.66-4.6a4.24 4.24 0 0 1 3.68-2.13h5.37a4.24 4.24 0 0 1 3.68 2.13l2.82 4.89c2.35 4.07 7.55 5.46 11.62 3.11l14.26-8.24c4.07-2.35 5.46-7.55 3.11-11.62l-2.66-4.6c-.76-1.32-.76-2.94 0-4.25l2.69-4.65a4.24 4.24 0 0 1 3.68-2.13h5.64a8.5 8.5 0 0 0 8.5-8.5zm-53.64 16.96c-15.75 1.24-28.8-11.8-27.56-27.56.98-12.39 11.02-22.43 23.41-23.41 15.75-1.24 28.8 11.8 27.56 27.56-.98 12.39-11.02 22.43-23.41 23.41z"
                  fill="#1e3a8a"
                />
                <path
                  d="M91.54 30.23l16.28-21.67c3.55-4.72-2.09-10.84-7.09-7.7L67.74 21.65l-12.26 7.72c-3.04 1.92-2.95 6.38.16 8.17l40.33 23.29c2.86 1.65 6.51.67 8.16-2.19l3.93-6.81c1.65-2.86.67-6.51-2.19-8.16l-13.15-7.59a4 4 0 0 1-1.2-5.85zM19.89 186.7L3.61 208.37c-3.55 4.72 2.09 10.84 7.09 7.7l32.99-20.79 12.26-7.72c3.04-1.92 2.95-6.38-.16-8.17L15.46 156.1c-2.86-1.65-6.51-.67-8.16 2.19l-3.93 6.81c-1.65 2.86-.67 6.51 2.19 8.16l13.15 7.59a4 4 0 0 1 1.2 5.85z"
                  fill="#feae01"
                />
              </svg>
            </div>
            <div>
              <button
                type="button"
                className="focus:outline-none -mr-3 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-blue-600"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Åben menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>

        {/* CONTENT */}
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
