import { ExpandIcon, Modal } from "@onlion/components";
import { useState } from "react";
import { extractFileName } from "../../../utils/extractFileName";

export const UPLOAD_BASE_URL =
  "https://s3.eu-central-1.amazonaws.com/onlion-upload/";

export const Image = ({ imageId }: { imageId: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`group overflow-hidden border-gray-300`}>
      <div className="relative overflow-hidden rounded-xl shadow-sm">
        <div className="overflow-hidden rounded-xl">
          <img
            className={`rounded-xl object-cover w-20 border border-opacity-50 sm:w-32 sm:h-16 h-16`}
            src={`${UPLOAD_BASE_URL}${imageId}`}
            alt={imageId}
          />
        </div>

        <div
          onClick={() => setIsOpen(true)}
          className={`absolute inset-0 flex h-full transform 
      cursor-pointer items-center justify-center opacity-0 transition 
      duration-300 hover:opacity-100 rounded-xl backdrop-filter backdrop-blur-sm backdrop-brightness-95`}
        >
          <ExpandIcon className="h-8 w-8 text-white" />
        </div>
      </div>

      <p className="mt-2 block text-xs sm:text-sm font-medium text-gray-900 truncate w-20 sm:w-32 pointer-events-none">
        {extractFileName(imageId)}
      </p>

      {/* Modal */}
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} className="max-w-screen-md">
        <img
          onClick={() => setIsOpen(false)}
          className={`m-auto block w-full cursor-pointer rounded-lg border`}
          src={`${UPLOAD_BASE_URL}${imageId}`}
          alt={imageId}
        />
        <figcaption className="truncate py-2 text-xs font-medium uppercase tracking-widest text-gray-600">
          {extractFileName(imageId)} - klik på billedet for at lukke
        </figcaption>
      </Modal>
    </div>
  );
};
