import { useEffect, useState } from "react";
import {
  ArrowLeftRightIcon,
  Button,
  CheckCircleIcon,
  DashboardPages,
  Form,
  FormHeading,
  FormSection,
  Radio,
  SelectFieldset,
  SelectOption,
} from "@onlion/components";
import { useNavigate, useParams } from "react-router-dom";
import useUsers from "../../../../data/users/useUsers";
import { ResponsibilityTransfer, User } from "../../../../data/users/types";
import { FormActionBar } from "../../../../components/formActionBar";
import { useTransferResponsibility } from "../../../../data/users/useTransferResponsibility";
import { useTaskQuery } from "../../../../data/useTaskQuery";
import { Task } from "../../../../data/useTasks";

export const TransferResponsibilityPage = () => {
  const { id, companyId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { users } = useUsers();
  const user = users.find((u) => u.id === id);

  const [method, setMethod] = useState<"ALL" | "TASK" | "EQUIPMENT">("ALL");
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [perEquipment, setPerEquiment] = useState<TransferPerEquipmentSetObj>(
    {}
  );
  const [perTask, setPerTask] = useState<TransferPerTaskSetObj>({});
  const [newForAll, setNewForAll] = useState<SelectOption | null>(null);

  const [transfers, setTransfers] = useState<ResponsibilityTransfer>({});
  const transferResponsibility = useTransferResponsibility();

  const { tasks } = useTaskQuery({ userId: id });

  useEffect(() => {
    if (users) {
      setOptions(users.map((r) => ({ label: r.name, value: r.id })));
    }
  }, [users]);

  useEffect(() => {
    if (user) {
      setPerEquiment(getUniqueEquipmentFields(user, tasks));
      setPerTask(getUniqueTaskFields(user, tasks));
      setNewForAll({ label: user.name, value: user.id });
    }
  }, [user, tasks]);

  // Convert and set 'method transfers' to 'transfer'
  useEffect(() => {
    if (id && user) {
      const nextTransfers =
        method === "EQUIPMENT"
          ? perEquipmentTransfers(perEquipment, user, tasks)
          : method === "TASK"
          ? perTaskTransfers(perTask, user, tasks)
          : newForAllTransfers(newForAll, user, tasks);

      setTransfers(nextTransfers);
    }
  }, [user, method, perEquipment, perTask, newForAll, id, tasks]);

  if (!user) return null;

  const handleSubmit = async () => {
    setLoading(true);
    const success = await transferResponsibility(transfers);
    setLoading(false);
    if (success) {
      navigate(`/dashboard/${companyId}/users/${id}`);
    }
  };

  const fName = user.name.split(" ")[0];

  return (
    <div className="p-2 sm:p-8 bg-gray-50 min-h-screen">
      <DashboardPages.Form title={`Overdraft ansvar fra ${user.name}`}>
        <Form onSubmit={() => {}}>
          <FormHeading>
            <ArrowLeftRightIcon className="w-5 h-5 mr-2" /> Overdrag ansvar fra{" "}
            {user.name}
          </FormHeading>
          <p>
            Overdrag ansvaret for alle eller nogle af {fName}s rutine opgaver
          </p>

          <FormSection
            heading="Metode"
            info="Vælg hvordan du vil overdrage ansvar"
          >
            <Radio
              className="w-full md:w-96"
              onChange={setMethod}
              label="Metode til overdragelse af ansvar"
              value={method}
              autoFocus
              options={[
                {
                  label: "Alle opgaver til en anden ansvarlig",
                  value: "ALL",
                },
                {
                  label: "Ny ansvarlig per opgave",
                  value: "TASK",
                },
                {
                  label: "Ny ansvarlig per udstyr",
                  value: "EQUIPMENT",
                },
              ]}
            />
          </FormSection>

          {/* ALL */}
          {method === "ALL" && (
            <FormSection
              heading="Overdrag alle opgaver"
              info={
                <div className="space-y-2">
                  <p>
                    Vælg en ny ansvarlig som skal overtage alle {fName}s{" "}
                    {tasks.length === 1 ? "opgave" : "opgaver"}.
                  </p>
                  <p>
                    Hvis du ikke vælger en ny vil {fName} forsat være ansvarlig.
                  </p>
                </div>
              }
            >
              <SelectFieldset
                label={`Ny ansvarlig`}
                id={"newResponsibleForAll"}
                onChange={setNewForAll}
                selected={newForAll}
                options={options}
                required
              />
            </FormSection>
          )}

          {/* PER TASK */}
          {method === "TASK" && (
            <FormSection
              heading="Overdrag ansvar per opgave"
              info={
                <div className="space-y-2">
                  <p>Vælg en ny ansvarlig per opgave.</p>
                  <p>
                    Hvis du ikke vælger en ny vil {fName} forsat være ansvarlig.
                  </p>
                </div>
              }
            >
              {Object.values(perTask).map((t) => {
                return (
                  <SelectFieldset
                    key={t.id}
                    label={`Ny ansvarlig for opgaven '${t.title}'`}
                    help={`på udstyret ${t.equipment}`}
                    id={t.id}
                    onChange={(v) =>
                      setPerTask({
                        ...perTask,
                        [t.id]: { ...perTask[t.id], responsible: v },
                      })
                    }
                    selected={perTask[t.id].responsible}
                    options={options}
                    required
                  />
                );
              })}
            </FormSection>
          )}

          {/* PER EQUIPMENT */}
          {method === "EQUIPMENT" && (
            <FormSection
              heading="Overdrag ansvar per udstyr"
              info={
                <div className="space-y-2">
                  <p>
                    Hvis du ikke vælger en ny vil {fName} forsat være ansvarlig.
                  </p>
                  <p>
                    Hvis et udstyr har andre opgaver, som {fName} ikke er
                    ansvarlig for, vil disse ikke få en ny ansvarlig.
                  </p>
                </div>
              }
            >
              {Object.values(perEquipment).map((eq) => {
                return (
                  <SelectFieldset
                    key={eq.id}
                    label={`Ny ansvarlig for ${eq.name} ${eq.equipmentId}`}
                    help={`Den du vælger vil overtage ansvaret for ${
                      eq.count
                    } ${eq.count === 1 ? "opgave" : "opgaver"}`}
                    id={eq.name}
                    onChange={(v) =>
                      setPerEquiment({
                        ...perEquipment,
                        [eq.id]: { ...perEquipment[eq.id], responsible: v },
                      })
                    }
                    selected={perEquipment[eq.id].responsible}
                    options={options}
                    required
                  />
                );
              })}
            </FormSection>
          )}

          <FormActionBar>
            <Button
              className="mr-4"
              onClick={() => navigate(`/dashboard/${companyId}/users/${id}`)}
            >
              Gå tilbage uden at gemme
            </Button>
            <Button
              type="primary"
              disabled={Object.keys(transfers).length === 0}
              onClick={handleSubmit}
              htmlType="submit"
              loading={loading}
            >
              <CheckCircleIcon className="w-4 h-4 mr-2" /> Overdrag ansvar
            </Button>
          </FormActionBar>
        </Form>
      </DashboardPages.Form>
    </div>
  );
};

// ALL
function newForAllTransfers(
  newForAll: SelectOption | null,
  user: User,
  tasks: Task[]
) {
  if (!newForAll || !user) return {};

  if (user.id === newForAll.value) return {};

  let nextTransfers = {};
  tasks.forEach((t) => {
    nextTransfers = { ...nextTransfers, [t.id]: newForAll.value };
  });
  return nextTransfers;
}

// TASK
interface TransferPerTaskSet {
  title: string;
  id: string;
  equipment: string;
  responsible: SelectOption;
}

type TransferPerTaskSetObj = {
  [key: string]: TransferPerTaskSet;
};

function getUniqueTaskFields(user: User, tasks: Task[]) {
  return tasks.reduce((allTasks: TransferPerTaskSetObj, t) => {
    return {
      ...allTasks,
      [t.id]: {
        title: t.title,
        id: t.id,
        equipment: `${t.equipment.name} ${t.equipment.equipmentId}`,
        responsible: { label: user.name, value: user.id },
      },
    };
  }, {});
}

function perTaskTransfers(
  transfers: TransferPerTaskSetObj,
  user: User,
  tasks: Task[]
) {
  if (!transfers || !user) return {};

  let nextTransfers = {};
  tasks.forEach((t) => {
    const nextResponsibleId = transfers[t.id]?.responsible.value;
    if (nextResponsibleId && nextResponsibleId !== user.id) {
      nextTransfers = { ...nextTransfers, [t.id]: nextResponsibleId };
    }
  });
  return nextTransfers;
}

// EQUIPMENT
interface TransferPerEquipmentSet {
  name: string;
  equipmentId: string;
  id: string;
  count: number;
  responsible: SelectOption;
}

type TransferPerEquipmentSetObj = {
  [key: string]: TransferPerEquipmentSet;
};

function getUniqueEquipmentFields(user: User, tasks: Task[]) {
  return tasks.reduce((allEquipment: TransferPerEquipmentSetObj, t) => {
    const existing = allEquipment[t.equipment.id];
    if (existing) {
      return {
        ...allEquipment,
        [t.equipment.id]: { ...existing, count: existing.count + 1 },
      };
    } else {
      return {
        ...allEquipment,
        [t.equipment.id]: {
          ...t.equipment,
          count: 1,
          responsible: { label: user.name, value: user.id },
        },
      };
    }
  }, {});
}

function perEquipmentTransfers(
  transfers: TransferPerEquipmentSetObj,
  user: User,
  tasks: Task[]
) {
  if (!transfers || !user) return {};
  let nextTransfers = {};
  tasks.forEach((t) => {
    const nextResponsibleId = transfers[t.equipment.id]?.responsible.value;
    if (nextResponsibleId && nextResponsibleId !== user.id) {
      nextTransfers = { ...nextTransfers, [t.id]: nextResponsibleId };
    }
  });
  return nextTransfers;
}
