import axios from "axios";
import { AUTH_TOKEN_KEY, BASE_URL } from "../../../constants";
import { mutate } from "swr";

interface SignUpResponse {
  token: string;
  user: { name: string; companyName: string };
}

const signUp = async ({
  companyName,
  name,
  emailAddress,
  password,
  mfa,
}: {
  companyName: string;
  name: string;
  emailAddress: string;
  password: string;
  mfa: boolean;
}) => {
  try {
    const signUpResult = await axios.post<SignUpResponse>(
      `${BASE_URL}/company`,
      {
        user: { name, emailAddress, password },
        company: { name: companyName, mfa: { active: mfa, method: "email" } },
      }
    );

    if (signUpResult.status === 200) {
      const { user, token } = signUpResult.data;

      axios.defaults.headers.common["authorization"] = token;

      localStorage.setItem(AUTH_TOKEN_KEY, token);

      mutate("api/user", user, true);

      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export default signUp;
