import React from "react";
import { Button, SelectFieldset, XIcon } from "@onlion/components";
import { getUnique } from "../../../../../../components/getUnique";
import {
  GetFilterValueFunc,
  SetFilterValueFunc,
} from "../../../../../../components/useTable";
import { Equipment } from "../../../../../../data/useEquipment";

interface FilterProps {
  equipment: Equipment[];
  get: GetFilterValueFunc;
  set: SetFilterValueFunc;
  clear: () => void;
}

export const Filters = ({ equipment, get, set, clear }: FilterProps) => {
  const locations = getUnique<Equipment, Equipment["location"]>(
    equipment,
    "location"
  )
    .map((location) => ({
      label: location,
      value: location,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  const location = get("location");
  const locationPlaceholder = "Alle lokationer";
  const locationComp =
    locations.length > 1 ? (
      <SelectFieldset
        placeholder={locationPlaceholder}
        label="Lokation"
        id="location_filter"
        short
        onChange={(v) => {
          if (v) {
            set("location", v.value);
          } else {
            set("location", v);
          }
        }}
        options={locations}
        selected={{
          label: location ?? locationPlaceholder,
          value: location ?? null,
        }}
      />
    ) : null;

  const categories = getUnique<Equipment, Equipment["category"]>(
    equipment,
    "category"
  )
    .map((category) => ({
      label: category,
      value: category,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  const category = get("category");
  const categoryPlaceholder = "Alle kategorier";
  const categoryComp =
    categories.length > 1 ? (
      <SelectFieldset
        placeholder={categoryPlaceholder}
        label="Kategori"
        id="category_filter"
        short
        onChange={(v) => {
          set("category", v?.value ?? null);
        }}
        options={categories}
        selected={{
          label: category ?? categoryPlaceholder,
          value: category ?? null,
        }}
      />
    ) : null;

  const makes = getUnique<Equipment, Equipment["make"]>(equipment, "make")
    .map((make) => ({
      label: make,
      value: make,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  const make = get("make");
  const makePlaceholder = "Alle fabrikater";
  const makeComp =
    makes.length > 1 ? (
      <SelectFieldset
        placeholder={makePlaceholder}
        label="Fabrikat"
        id="make_filter"
        short
        onChange={(v) => {
          if (v) {
            set("make", v.value);
          } else {
            set("make", v);
          }
        }}
        options={makes}
        selected={{
          label: make ?? makePlaceholder,
          value: make ?? null,
        }}
      />
    ) : null;

  const filtersShown = categoryComp || locationComp || makeComp;

  return (
    <>
      {categoryComp}
      {makeComp}
      {locationComp}

      {!filtersShown && (
        <p className="text-sm w-60 flex items-center">
          <XIcon className="w-3 h-3 mr-2" /> Ingen filtre at vise lige nu
        </p>
      )}

      {filtersShown && (
        <Button onClick={clear}>
          <XIcon className="w-3 h-3 mr-2" /> Ryd filtre
        </Button>
      )}
    </>
  );
};
