import { useEffect } from "react";
import useCompany from "../../../data/useCompany";
import { useBreadcrumbs } from "../../../state/useBreadcrumbs";
import { OnboardingSection } from "./sections/onboarding";
import { OverviewSection } from "./sections/overview";
import useUser from "../../../data/useUser";
import { UserOverview } from "./sections/userOverview";
import { useUserRoleType } from "../../../components/hooks";

const OverviewPage = () => {
  const { company, loading } = useCompany();
  const { replaceBreadcrumbs } = useBreadcrumbs();
  const { user } = useUser();
  const { isAdmin } = useUserRoleType(user);

  useEffect(() => {
    if (company && company.onboarding) {
      replaceBreadcrumbs([{ text: "Oversigt" }]);
    } else if (isAdmin) {
      replaceBreadcrumbs([{ text: "Din Oversigt" }]);
    } else {
      replaceBreadcrumbs([]);
    }
  }, []);

  if (loading || !company || !user) return null;

  return (
    <div className={`${company.onboarding ? "bg-gray-300" : ""} min-h-full`}>
      {isAdmin && company.onboarding && <OnboardingSection />}
      {isAdmin && !company.onboarding && (
        <OverviewSection stats={company.stats} />
      )}
      {!isAdmin && <UserOverview />}
    </div>
  );
};

export default OverviewPage;
