import {
  Button,
  Checkbox,
  DashboardPages,
  Form,
  FormHeading,
  FormSection,
} from "@onlion/components";
import { useEffect, useState } from "react";
import { FormActionBar } from "../../../components/formActionBar";
import useCompany from "../../../data/useCompany";
import useUser from "../../../data/useUser";
import { useUserRoleType } from "../../../components/hooks";

export const SecurityPage = () => {
  const { company, update } = useCompany();
  const { user } = useUser();
  const { isSuperAdmin } = useUserRoleType(user);
  const [mfa, setMFA] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (company) setMFA(Boolean(company.mfa?.active));
  }, [company]);

  const submit = async () => {
    setLoading(true);
    if (!company) return;
    await update({ mfa: { active: mfa, method: "email" }, id: company.id });
    setLoading(false);
  };

  return (
    <div>
      <div className="p-2 sm:p-8 bg-gray-200 min-h-screen">
        <DashboardPages.Form title="Sikkerhed" className="mb-20 md:mb-40">
          <Form onSubmit={() => {}}>
            <FormHeading>Sikkerhed</FormHeading>
            <FormSection
              heading="To-trins log-ind"
              info={
                <>
                  <p>
                    Hvis du aktiverer to-trins log ind, skal du klikke på et
                    link, som du får tilsendt per email, når du logger ind
                  </p>
                  <p>Dette gælder alle brugere for denne virksomhed</p>
                  <p>To-trins log-ind øger sikkerheden på din konto</p>
                </>
              }
            >
              <Checkbox
                className="w-full md:w-96"
                label={"Aktiver to-trins log-ind"}
                value={mfa}
                onChange={setMFA}
              />
            </FormSection>

            <FormActionBar>
              <Button
                type="primary"
                onClick={submit}
                loading={loading}
                disabled={!isSuperAdmin}
              >
                Gem sikkerhedsindstillinger
              </Button>
            </FormActionBar>
          </Form>
        </DashboardPages.Form>
      </div>
    </div>
  );
};
