import {
  Page,
  RefreshcwIcon,
  SelectFieldset,
  SelectOption,
  ZapIcon,
} from "@onlion/components";
import { useEffect, useState } from "react";
import { formatName } from "../../../../../components/formatName";
import { Link, useParams } from "react-router-dom";
import { useBreadcrumbs } from "../../../../../state/useBreadcrumbs";
import { useHistoricCompletions } from "../../../../../data/useHistoricCompletions";
import getYear from "date-fns/getYear";
import formatDistance from "date-fns/formatDistance";
import format from "date-fns/format";
import da from "date-fns/locale/da";
import { useTable } from "../../../../../components/useTable";
import { format_date } from "@onlion/utils";
import { Table } from "../../../../../components/table";
import { wasLate } from "../../../../../utils/wasLate";

const currentYear = getYear(new Date());

export const TaskHistoryPage = () => {
  const { companyId } = useParams();
  const { replaceBreadcrumbs } = useBreadcrumbs();

  useEffect(
    () =>
      replaceBreadcrumbs([
        { text: "Opgaver", link: `/dashboard/${companyId}/tasks` },
        { text: "Historik" },
      ]),
    [companyId, replaceBreadcrumbs]
  );

  const [year, setYear] = useState<SelectOption>({
    label: currentYear,
    value: currentYear,
  });
  const { historicCompletions, loading, lastUpdated } = useHistoricCompletions(
    year ? Number(year.value) : currentYear
  );

  const { data } = useTable(historicCompletions, "historicCompletions");

  return (
    <Page
      loading={{
        isLoading: loading,
        title: "Henter opgavehistorik",
        text: "Dette bør kun tage få sekunder",
      }}
      headingProps={{
        title: "Opgavehistorik",
        subtitle: (
          <>
            Udførte opgaver i år {year.value} ({data.length})
            <p className="text-xs text-gray-500">
              Sidst opdateret kl. {lastUpdated && format(lastUpdated, "H:mm")}
            </p>
          </>
        ),
        renderActions: (
          <SelectFieldset
            id="year"
            label="Vælg år"
            onChange={(v) => setYear(v)}
            options={getYearOptions(2018, currentYear)}
            selected={year}
            required
          />
        ),
      }}
      className="h-screen"
    >
      <Table
        className="mx-4"
        head={[
          { content: "Opgave" },
          { content: "Udstyr" },
          { content: "Dato" },
          { content: "Ansvarlig" },
          { content: "Type", hideMobile: false, right: true },
        ]}
        row={{
          fields: [
            {
              renderData: ({ task }) => (
                <Link
                  to={`/dashboard/${companyId}/tasks/${task.id}`}
                  className="hover:text-blue-600 flex"
                >
                  {task.title}
                </Link>
              ),
            },
            {
              renderData: ({ equipment }) => (
                <Link
                  to={`/dashboard/${companyId}/equipment/${equipment.id}`}
                  className="hover:text-blue-600"
                >
                  {equipment.name}{" "}
                  <p className="text-xs">{equipment.equipmentId}</p>
                </Link>
              ),
            },
            {
              renderData: (hc) => (
                <>
                  {format_date(hc.finishedOn)}{" "}
                  <p className="text-xs">
                    {wasLate(hc)
                      ? `forsinket ${formatDistance(
                          new Date(hc.finishedOn),
                          new Date(hc.deadline),
                          { locale: da }
                        )}`
                      : "uden forsinkelse"}
                  </p>
                </>
              ),
            },
            {
              renderData: ({ responsible }) =>
                responsible.name ? (
                  <Link
                    to={`/dashboard/${companyId}/users/${responsible.id}`}
                    className="hover:text-blue-600 flex"
                  >
                    {formatName(responsible.name)}
                  </Link>
                ) : (
                  "en nu slettet bruger"
                ),
            },
            {
              renderData: ({ task: { type } }) => getType(type),
              hideMobile: false,
              right: true,
            },
          ],
        }}
        data={data}
      />
    </Page>
  );
};

function getType(type: "AD_HOC" | "CYCLICAL") {
  switch (type) {
    case "CYCLICAL":
      return (
        <p className="flex items-center justify-end">
          <RefreshcwIcon className="w-3 h-3 mr-2" /> Rutine
        </p>
      );
    case "AD_HOC":
      return (
        <p className="flex items-center justify-end">
          <ZapIcon className="w-3 h-3 mr-2" /> Enkelt
        </p>
      );
  }
}

const getYearOptions = (start: number, stop: number): SelectOption[] => {
  const numberOfOptions = stop - start;
  const options = [];
  for (let i = 0; i < numberOfOptions + 1; i++) {
    const year = start + i;
    options.push({ label: year, value: year });
  }

  return options.reverse();
};
