import { useEffect } from "react";
import { Route, useNavigate, Routes, useSearchParams } from "react-router-dom";
import useUser from "../data/useUser";

import Layout from "./components/layout/layout";
import LoginPage from "./pages/login/login";
import NewPasswordRequestPage from "./pages/newPasswordRequest/newPasswordRequest";
import NewPasswordRequestReceiptPage from "./pages/newPasswordRequestReceipt/newPasswordRequestReceipt";
import NewPasswordPage from "./pages/newPassword/newPassword";
import LoggedOutPage from "./pages/loggedOut/loggedOut";
import NewPasswordReceiptPage from "./pages/newPasswordReceipt/newPasswordReceipt";
import SignUpPage from "./pages/signUp/signUp";
import { MFAStepOnePage } from "./pages/mfa/step_one";
import { MFAStepTwoPage } from "./pages/mfa/step_two";
import { InvitationPage } from "./pages/invitation";

const Auth = () => {
  const navigate = useNavigate();
  const { user, loggedOut } = useUser();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from")?.includes("profile")
    ? null
    : searchParams.get("from");

  useEffect(() => {
    if (user && user.roles && !loggedOut) {
      if (from) {
        navigate(from);
        return;
      }
      const partOfMultipleCompanies = Object.keys(user.roles).length > 1;

      if (partOfMultipleCompanies) {
        navigate(`/profile`);
      } else {
        const [companyId, role] = Object.entries(user.roles)[0];

        if (["SUPER_ADMIN", "ADMIN"].includes(role.type)) {
          navigate(`/dashboard/${companyId}`);
        } else {
          navigate(`/dashboard/${companyId}/user-dashboard`);
        }
      }

      // TODO navigate to profile instead
      // navigate(`/profile`);
    }
  }, [user, loggedOut, navigate, from]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route index element={<LoginPage />} />
        <Route path="sign-up" element={<SignUpPage />} />
        <Route
          path="new-password-request"
          element={<NewPasswordRequestPage />}
        />
        <Route
          path="new-password-request-receipt"
          element={<NewPasswordRequestReceiptPage />}
        />
        <Route
          path="new-password/:token/:expiry"
          element={<NewPasswordPage />}
        />
        <Route
          path="join-company/:token/:expiry"
          element={<NewPasswordPage />}
        />
        <Route
          path="new-password-receipt"
          element={<NewPasswordReceiptPage />}
        />
        <Route path="logged-out" element={<LoggedOutPage />} />
        <Route path="mfa/step_one/:emailAddress" element={<MFAStepOnePage />} />
        <Route path="mfa/step_two/:code" element={<MFAStepTwoPage />} />

        <Route path="invitation/:token" element={<InvitationPage />} />
      </Routes>
    </Layout>
  );
};

export default Auth;
