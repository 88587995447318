import { Loading, RefreshcwIcon, ZapIcon } from "@onlion/components";
import formatDistance from "date-fns/formatDistance";
import da from "date-fns/locale/da";
import { format_date } from "@onlion/utils";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistoricCompletions } from "../../../../../data/useHistoricCompletions";
import { useTable } from "../../../../../components/useTable";
import { Table } from "../../../../../components/table";
import { wasLate } from "../../../../../utils/wasLate";

export const SingleTaskHistory = () => {
  const { id, companyId } = useParams();
  const { historicCompletions, loading } = useHistoricCompletions();

  const { data } = useTable(historicCompletions, "historicCompletions");

  if (loading)
    return (
      <div className="h-screen">
        <Loading
          title="Henter opgavens historik"
          text="Dette bør kun tage få øjeblikke"
        />
      </div>
    );

  return (
    <Table
      className="my-4"
      head={[{ content: "Dato" }, { content: "Ansvarlig" }]}
      row={{
        fields: [
          {
            renderData: (hc) => (
              <>
                {format_date(hc.finishedOn)}{" "}
                <p className="text-xs">
                  {wasLate(hc)
                    ? `forsinket ${formatDistance(
                        new Date(hc.finishedOn),
                        new Date(hc.deadline),
                        { locale: da }
                      )}`
                    : "uden forsinkelse"}
                </p>
              </>
            ),
          },
          {
            renderData: ({ responsible }) =>
              responsible.name ? (
                <Link
                  to={`/dashboard/${companyId}/responsibles/${responsible.id}`}
                  className="hover:text-blue-600 flex justify-end"
                >
                  {responsible.name}
                </Link>
              ) : (
                "en nu slettet ansvarlig"
              ),
            right: true,
          },
        ],
      }}
      data={data.filter((hc) => hc.task.id === id)}
    />
  );
};
