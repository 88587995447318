import React from "react";
import {
  BuildingIcon,
  HammerIcon,
  HardHatIcon,
  InfoIcon,
  PlugZapIcon,
  RulerIcon,
  SettingsIcon,
  TruckIcon,
} from "@onlion/components";

type StandardCategory =
  | "køretøj"
  | "maskine"
  | "håndværktøj"
  | "strukturel"
  | "elektronik"
  | string;

export const mapCategoryToIcon = (category: StandardCategory) => {
  const cat = category.toLowerCase();

  switch (cat) {
    case "køretøj":
      return TruckIcon;
    case "maskine":
      return SettingsIcon;
    case "håndværktøj":
      return HammerIcon;
    case "strukturel":
      return BuildingIcon;
    case "elektronik":
      return PlugZapIcon;
  }

  if (cat.includes("måle")) return RulerIcon;
  if (cat.includes("sikkerhed")) return HardHatIcon;

  return InfoIcon;
};
