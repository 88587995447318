import { mutate as globalMutate } from "swr";
import { patch } from "../../components/requests/requests";
import { ResponsibilityTransfer } from "./types";
import { notify } from "../../dashboard/components/notifications/newNotification";
import { useParams } from "react-router-dom";

export const useTransferResponsibility = (): ((
  transfers: ResponsibilityTransfer
) => Promise<boolean>) => {
  const { companyId } = useParams();

  return async (transfers: ResponsibilityTransfer) => {
    const nextTasks = await patch("task/responsibility", transfers, {
      companyId,
    });

    if (nextTasks) {
      globalMutate("api/tasks", nextTasks);
      globalMutate("api/responsibles");
      const tasksTransferred = Object.keys(transfers).length;
      notify({
        heading: "Ændringerne blev gemt",
        text: `Ansvaret for ${tasksTransferred} ${
          tasksTransferred === 1 ? "opgave" : "opgaver"
        } blev overdraget.`,
      });
      return true;
    } else {
      notify({
        heading: "Ændringerne blev ikke gemt",
        text: "Ansvaret kunne ikke overføres.",
        type: "alert",
      });
      return false;
    }
  };
};
