import React from "react";
import cl from "classnames";
import { SortAscIcon } from "../icons/sortAsc";
import { SortDescIcon } from "../icons/sortDesc";
import { Sorting, TableDataEntity, TableHeading, TableRow } from "./types";

export type TableProps<T> = {
  head: TableHeading<T>[];
  row: TableRow<T>;
  data: T[];
  sorting?: Sorting;
  stickyHead?: boolean;
  className?: string;
  rounded?: boolean;
  errorMessage?: string;
};

export const Table = <T extends TableDataEntity>({
  head,
  row,
  data,
  sorting,
  stickyHead = true,
  className = "",
  rounded = false,
  errorMessage = "Ingen data endnu",
}: TableProps<T>) => {
  if (!head || !row || !data) return null;

  const isEmpty = data.length === 0;

  return (
    <div className={className}>
      <div className="flex flex-col">
        <div className="inline-block min-w-full align-middle rounded-lg border border-gray-300 overflow-x-auto overflow-hidden">
          <div className="table min-w-full divide-y bg-gray-200 border-collapse">
            <div className="bg-gray-100 table-header-group">
              <div className="table-row">
                {head.map((h, idx, all) =>
                  renderHead(h, idx, all, stickyHead, sorting)
                )}
              </div>
            </div>
            {!isEmpty && (
              <div className="table-row-group divide-y divide-gray-200 bg-white">
                {data.map((d, idx) =>
                  renderRow(row, d, idx, idx === data.length - 1)
                )}
              </div>
            )}
          </div>
          {isEmpty && (
            <div className="w-full text-center py-6 bg-yellow-50 text-medium text-yellow-900">
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const renderHead = <T extends TableDataEntity>(
  { content, hide, right, key, onClick, hideMobile }: TableHeading<T>,
  idx: number,
  all: TableHeading<T>[],
  sticky: boolean,
  sorting?: Sorting
) => {
  const isFirst = idx === 0;
  const isLast = idx === all.length - 1;
  const rightAlign = right || (right !== false && isLast);
  const classNames = cl(
    `table-cell text-xs uppercase font-normal select-none border border-gray-200 border-b-gray-300 border-t-0 text-gray-700 py-2 px-3 text-left`,
    {
      "pl-3 text-left sm:pl-4 border-l-0": isFirst,
      "relative pl-3 pr-3 sm:pr-4 border-r-0": isLast,
      "text-right": rightAlign,
      "hover:text-gray-500 cursor-pointer": onClick !== undefined,
      "hidden sm:table-cell":
        hideMobile || (!isFirst && hideMobile === undefined),
      sticky: sticky,
    }
  );

  const canBeSorted = key !== undefined && sorting !== undefined;
  const isSorted = canBeSorted && key === sorting?.key;

  const sortIconClassNames = `h-4 w-4 ml-1.5 ${
    isSorted ? "text-blue-600" : "text-gray-500"
  }`;
  const sortIcon =
    canBeSorted &&
    (sorting && sorting.ascending ? (
      <SortAscIcon className={sortIconClassNames} />
    ) : (
      <SortDescIcon className={sortIconClassNames} />
    ));

  return (
    <div key={idx} className={classNames} onClick={onClick}>
      <div
        className={`flex items-center ${rightAlign && "justify-end"} ${
          hide && "text-transparent"
        }`}
      >
        {content}
        {sortIcon}
      </div>
    </div>
  );
};

const renderRow = <T extends TableDataEntity>(
  row: TableRow<T>,
  data: T,
  idx: number,
  isLastRow: boolean
) => {
  const noOfFields = row.fields.length;
  return (
    <div
      key={data.id}
      className={`table-row group ${idx % 2 === 0 ? undefined : "bg-gray-50"}`}
      onClick={() => row.onClick?.(data)}
    >
      {row.fields.map(({ renderData, hide, right, hideMobile }, idx, all) => {
        const isFirst = idx === 0;
        const isLast = idx === all.length - 1;
        const classNames = cl({
          "table-cell align-middle text-sm text-gray-800 whitespace-nowrap py-2.5 px-3 border":
            true,
          "group-hover:bg-blue-50 cursor-pointer": row.onClick ?? false,
          "pl-3 text-left sm:pl-4 border-l-0": isFirst,
          "pr-3 sm:pr-4 border-r-0": isLast,
          "text-right": right || (right !== false && isLast && noOfFields > 1),
          "text-transparent": hide,
          "border-b-0": isLastRow,
          "hidden sm:table-cell":
            hideMobile || (!isFirst && hideMobile === undefined),
        });
        return (
          <div key={idx} className={classNames}>
            {renderData(data)}
          </div>
        );
      })}
    </div>
  );
};
