import {
  Button,
  CheckCircleIcon,
  DashboardPages,
  Form,
  FormHeading,
  FormSection,
  SelectFieldset,
  SelectOption,
  UserIcon,
} from "@onlion/components";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FormActionBar } from "../../../../components/formActionBar";
import { User, UserType } from "../../../../data/users/types";
import { useUpdateUser } from "../../../../data/users/useUpdateUser";
import useUsers from "../../../../data/users/useUsers";
import { useBreadcrumbs } from "../../../../state/useBreadcrumbs";
import { useUserRoleType } from "../../../../components/hooks";

export const UsersUpdatePage = () => {
  const navigate = useNavigate();
  // const [name, setName] = useState<string>("");
  // const [emailAddress, setEmailAddress] = useState<string>("");
  const [type, setType] = useState<UserType>("USER");
  const { id, companyId } = useParams();
  const { replaceBreadcrumbs } = useBreadcrumbs();
  const { users } = useUsers();
  const { updateUser } = useUpdateUser();

  const focusedUser = useMemo(
    () => (users ? users.find((u) => u.id === id) : null),
    [users, id]
  );

  if (!focusedUser) {
    navigate(`/dashboard/${companyId}/users`);
  }

  const { type: currentUserRoleType } = useUserRoleType(focusedUser as User);

  useEffect(() => {
    if (focusedUser) {
      // setName(focusedUser.name);
      // setEmailAddress(focusedUser.emailAddress);
      setType(currentUserRoleType);
    }
  }, [focusedUser, navigate, id, currentUserRoleType]);

  // Breadcrumbs
  useEffect(() => {
    if (focusedUser) {
      const baseLink = `/dashboard/${companyId}/users`;
      replaceBreadcrumbs([
        {
          text: "users",
          link: baseLink,
        },
        {
          text: focusedUser.name,
        },
        {
          text: "Ret",
        },
      ]);
    }
  }, [focusedUser, replaceBreadcrumbs, companyId]);

  if (!focusedUser || !id) return null;

  const handleSubmit = async () => {
    const success = await updateUser({
      // name,
      // emailAddress,
      type,
      id,
    });
    if (success) navigate(`/dashboard/${companyId}/users/${id}`);
  };

  const roles: SelectOption[] = [
    { label: "Almindelig bruger", value: "USER" },
    { label: "Administrator", value: "ADMIN" },
    { label: "Superadministrator", value: "SUPER_ADMIN" },
  ];

  return (
    <div className="p-2 sm:p-8 bg-gray-50 min-h-screen">
      <DashboardPages.Form title={`Ret ${focusedUser.name}`}>
        <Form onSubmit={() => {}}>
          <FormHeading>
            <UserIcon className="w-8 h-8 mr-2" /> Ret {focusedUser.name}
          </FormHeading>

          {/* <FormSection heading="Bruger">
            <TextFieldset
              id="name"
              label="Navn"
              placeholder="Navn"
              value={name}
              onChange={setName}
              autoComplete="off"
              autoFocus
            />
            <TextFieldset
              id="emailAddress"
              label="Emailadresse"
              placeholder="Emailadresse"
              value={emailAddress.toLowerCase()}
              onChange={setEmailAddress}
            />
          </FormSection> */}

          <FormSection
            heading="Rolle"
            info={
              <>
                <p>Der kan kun være en superadministrator per virksomhed.</p>
                <p>
                  OBS: Du bliver normal administrator hvis du vælger
                  'Superadministrator' i dette felt.
                </p>
              </>
            }
          >
            <SelectFieldset
              required
              id="role"
              label="Rolle"
              help="Administratorer kan tilføje, rette og slette udstyr, opgaver mm."
              placeholder="Vælg rolle"
              onChange={(v) => setType(v.value as UserType)}
              options={roles}
              selected={{
                label: roleLabel(type),
                value: type,
              }}
            />
          </FormSection>

          <FormActionBar>
            <Button
              className="mr-4"
              onClick={() => navigate(`/dashboard/${companyId}/users`)}
            >
              Fortryd
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              // disabled={!is_email(emailAddress) || name.length === 0}
              onClick={handleSubmit}
              icon={{ leading: CheckCircleIcon }}
            >
              Gem rettelser
            </Button>
          </FormActionBar>
        </Form>
      </DashboardPages.Form>
    </div>
  );
};

const roleLabel = (role: UserType) =>
  role === "USER"
    ? "Almindelig bruger"
    : role === "ADMIN"
    ? "Administrator"
    : "Superadministrator";
