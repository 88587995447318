import { useState } from "react";
import { NoteResponse } from "../../../data/notes/types";
import { Response } from "./response";
import { Button } from "../../button";

const SHOW_BY_DEFAULT_COUNT = 2;

type ResponseListProps = {
  responses: NoteResponse[];
};

export const ResponseList = ({ responses }: ResponseListProps) => {
  const [showAll, setShowAll] = useState(false);

  const sortedResponses = [...responses].sort(compareDateTime);

  const displayResponses = showAll
    ? sortedResponses
    : [...sortedResponses].slice(0, SHOW_BY_DEFAULT_COUNT);

  const showDisplayButtons = responses.length > SHOW_BY_DEFAULT_COUNT;

  return (
    <div className="flex flex-col gap-6">
      {displayResponses.map((r) => (
        <Response response={r} />
      ))}
      {showDisplayButtons && (
        <>
          {showAll && (
            <div>
              <Button onClick={() => setShowAll(false)}>Vis færre svar</Button>
            </div>
          )}
          {!showAll && (
            <div>
              <Button onClick={() => setShowAll(true)}>Vis flere svar</Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const compareDateTime = (a: NoteResponse, b: NoteResponse) =>
  a.datetime > b.datetime ? -1 : 1;
