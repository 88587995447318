import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useBreadcrumbs } from "../../../state/useBreadcrumbs";
import { UsersCreatePage } from "./pages/create";
import { UsersTablePage } from "./pages/table";
import { UsersUpdatePage } from "./pages/update";
import { UserFocusPage } from "./pages/focus/focus";
import { TransferResponsibilityPage } from "./pages/transferResponsibility";

export const UsersPage = () => {
  const { replaceBreadcrumbs } = useBreadcrumbs();
  useEffect(
    () => replaceBreadcrumbs([{ text: "Brugere" }]),
    [replaceBreadcrumbs]
  );

  return (
    <div>
      <Routes>
        <Route index element={<UsersTablePage />} />

        {[":id/*", "list/:id/*", "users/:id"].map((p) => (
          <Route path={p} key={p} element={<UserFocusPage />} />
        ))}

        {["transfer/:id", "list/transfer/:id"].map((p) => (
          <Route path={p} key={p} element={<TransferResponsibilityPage />} />
        ))}

        <Route path="create" element={<UsersCreatePage />} />
        <Route path="update/:id" element={<UsersUpdatePage />} />
      </Routes>
    </div>
  );
};
