type Color = "red" | "yellow" | "blue" | "green" | "gray";

export function mapStatus(status: string): { label: string; color: Color } {
  switch (status) {
    case "CURRENT":
      return { label: "Udføres", color: "green" };
    case "OVERDUE":
      return { label: "Forsinket", color: "yellow" };
    case "ACTIVE":
      return { label: "Afventer", color: "blue" };
    case "STANDBY":
      return { label: "Standby", color: "blue" };
    case "COMPLETED":
      return { label: "Udført", color: "gray" };
    default:
      return { label: "MANGLER", color: "gray" };
  }
}
