import useUser from "../../../data/useUser";
import { ExternalLinkIcon, Page, ZapIcon } from "@onlion/components";
import { mapStatus } from "../../../components/mapStatus";
import { COMPLETE_BASE_URL } from "../../../constants";
import { format_date } from "@onlion/utils";
import { formatDistanceToNow, endOfDay } from "date-fns";
import da from "date-fns/locale/da";
import { Empty } from "../../../components/empty/empty";
import { Table } from "../../../components/table";
import Badge from "../../../components/badge";
import { useTaskQuery } from "../../../data/useTaskQuery";

export const UserDashboardPage = () => {
  const { user, loading } = useUser();

  const { tasks } = useTaskQuery({
    userId: user.id,
  });

  const tasksToShow = tasks.filter((t) =>
    ["CURRENT", "OVERDUE", "ACTIVE"].includes(t.status)
  );

  if (!user) return null;

  const noTasks = !tasksToShow || tasksToShow.length === 0;

  if (noTasks)
    return (
      <Empty
        title="Din oversigt"
        description="Du er ikke ansvarlig for nogen opgaver, så denne side er tom."
        icon={(className: string) => <ZapIcon className={className} />}
      />
    );

  return (
    <Page
      loading={{
        isLoading: loading,
        title: "Henter din oversigt",
        text: "Dette bør kun tage få sekunder",
      }}
      className="bg-white h-screen"
      headingProps={{
        title: "Din oversigt",
        subtitle: "Et overblik over de opgaver du er ansvarlig for at udføre",
      }}
    >
      <Table
        className="mx-4"
        head={[
          { content: "Opgave" },
          { content: "Deadline" },
          { content: "Status" },
          { content: "", right: true }, // Open column
        ]}
        row={{
          fields: [
            {
              renderData: ({ title }) => title,
            },
            {
              renderData: ({ deadline }) => (
                <>
                  {format_date(deadline)}
                  <p className="text-xs">
                    {formatDistanceToNow(endOfDay(new Date(deadline)), {
                      addSuffix: true,
                      locale: da,
                    })}
                  </p>
                </>
              ),
            },
            {
              renderData: ({ status }) => (
                <Badge className="mr-auto" type={mapStatus(status).color}>
                  {mapStatus(status).label}
                </Badge>
              ),
              hideMobile: false,
            },
            {
              renderData: ({ token, status }) =>
                ["CURRENT", "OVERDUE"].includes(status) ? (
                  <a
                    target="blank"
                    href={`${COMPLETE_BASE_URL}/${token}`}
                    className="flex justify-end gap-1 underline text-blue-800 hover:text-blue-900"
                  >
                    Åben <ExternalLinkIcon className="w-4" />
                  </a>
                ) : null,
              right: true,
            },
          ],
        }}
        data={tasksToShow}
      />
    </Page>
  );
};
