import { useEffect, useMemo, useState } from "react";
import { string_distance } from "@onlion/utils";
import useSessionstorageState from "./useSessionStorage";

export interface UseTableConfig<T> {
  searchKeys?: keyof T | (keyof T)[];
  addKV?: (elem: T) => T;
  filters?: {
    [key: string]: { func: (e: T, v: any) => boolean; initialValue?: any };
  };
}

export type GetFilterValueFunc = (key: string) => any;
export type SetFilterValueFunc = (key: string, value: any) => void;

export interface UseTableReturn<T, ReturnT = T> {
  data: ReturnT[];

  query: string;
  setQuery: (next: string) => void;
  closestQueryMatch: string | null;

  sorting: Sorting | null;
  sort: (key: string) => void;

  getFilterValue: (key: string) => any;
  setFilterValue: (key: string, value: any) => void;
  activeFilterCount?: number;
  clearFilters: () => void;
}

export const useTable = <T extends { [key: string]: any }, ReturnT = T>(
  data: T[],
  key: string,
  config?: UseTableConfig<T>
): UseTableReturn<T, ReturnT> => {
  const searchKeys = config?.searchKeys;
  const configFilters = config?.filters;

  const [processed, setProcessed] = useState<T[]>([]);

  const [query, setQuery] = useSessionstorageState(
    `${key}__admin__useTable__Query`,
    ""
  );
  const searchWords = useMemo(
    () => getSearchWords(data, searchKeys),
    [data, searchKeys]
  );
  const [closestQueryMatch, setClosestQueryMatch] = useState<string | null>(
    null
  );

  const [sorting, setSorting] = useSessionstorageState<Sorting | null>(
    `${key}__admin__useTable__Sorting`,
    null
  );

  const [filters, setFilters] = useSessionstorageState<Filters<T> | null>(
    `${key}__admin__useTable__Filters`,
    null
  );

  const setFilterValue = (key: string, nextValue: any) => {
    if (!filters) return;

    const old = filters[key];
    if (!old) return;

    setFilters({ ...filters, [key]: { ...old, value: nextValue } });
  };

  const getFilterValue = (key: string): any | null => {
    if (!filters) return null;

    const old = filters[key];
    if (!old) return null;

    return old.value;
  };

  useEffect(() => {
    if (data) {
      setProcessed([...data]);
    }

    if (configFilters) {
      setFilters(
        Object.entries(configFilters).reduce((finalFilters, [key, f]) => {
          const v =
            filters && filters[key]
              ? filters[key].value
              : f.initialValue ?? null;
          return {
            ...finalFilters,
            [key]: {
              func: f.func,
              value: v,
            },
          };
        }, {})
      );
    }
  }, [data]);

  useEffect(() => {
    if (!Array.isArray(data)) return;

    let next = [...data];

    // Add custom KVs
    if (config?.addKV) {
      next = next.map((e) => (config.addKV ? config.addKV(e) : e));
    }

    // Search filter
    if (searchKeys) {
      next = next.filter((elem) => {
        // Search
        if (query.length > 1) {
          const stringToSearch = Array.isArray(searchKeys)
            ? combineOnKeys(elem, searchKeys as string[]).toLowerCase()
            : elem[searchKeys].toLowerCase();

          const hasMatch = query
            .toLowerCase()
            .split(" ")
            .filter((queryBit) => queryBit !== "")
            .some((queryBit) =>
              stringToSearch
                .split(" ")
                .some(
                  (sts: string) =>
                    string_distance(sts, queryBit) < 0.8 ||
                    sts.includes(queryBit)
                )
            );
          if (!hasMatch) {
            return false;
          }
        }

        return true;
      });
    }

    // Closest query/search match
    if (next.length === 0 && query.length > 1 && searchWords) {
      setClosestQueryMatch(getClosestQueryMatch(query, searchWords));
    }

    // Other filters
    if (filters) {
      const currentActiveFilters = Object.values(filters);
      next = next.filter((v) =>
        currentActiveFilters.every((f) => f.func && f.func(v, f.value))
      );
    }

    // Sort
    if (sorting) {
      const { key, ascending } = sorting;
      next = [...next].sort((a, b) => {
        if (ascending) {
          return a[key] > b[key] ? 1 : -1;
        } else {
          return a[key] > b[key] ? -1 : 1;
        }
      });
    }

    setProcessed(next);
  }, [data, sorting, query, filters]);

  const sort = (key: string) => {
    if (sorting?.key === key) {
      if (sorting.ascending) {
        setSorting({ key, ascending: false });
      } else {
        setSorting(null);
      }
    } else {
      setSorting({ key, ascending: true });
    }
  };

  const [activeFilterCount, setActiveFilterCount] = useState<
    number | undefined
  >(undefined);

  useEffect(() => {
    if (filters) {
      const filterCount = Object.values(filters ?? {}).filter(
        (f) => f.value !== null
      ).length;
      const nextActiveFilterCount =
        filterCount === 0 || filterCount === undefined
          ? undefined
          : filterCount;
      setActiveFilterCount(nextActiveFilterCount);
    }
  }, [filters]);

  const clearFilters = () => {
    if (!filters) return;
    const nextFilters = Object.entries(filters).reduce(
      (next: Filters<T>, [key, value]) => {
        return { ...next, [key]: { ...value, value: null } };
      },
      {}
    );
    setFilters(nextFilters);
  };

  return {
    data: processed as unknown as ReturnT[],
    query,
    setQuery,
    closestQueryMatch,
    sorting,
    sort,

    setFilterValue,
    getFilterValue,
    activeFilterCount,
    clearFilters,
  };
};

export interface Sorting {
  key: string;
  ascending: boolean;
}

export type FilterFunc<T> = (elem: T, value: any) => boolean;
export type Filter<T> = { func: FilterFunc<T>; active: boolean; value: any };
export type Filters<T> = { [key: string]: Filter<T> };

function combineOnKeys<T extends {}>(elem: T, keys: string[]): string {
  return Object.entries(elem).reduce((str, [key, value]) => {
    if (keys.includes(key)) {
      return str + " " + value;
    } else {
      return str;
    }
  }, "");
}

function getClosestQueryMatch<T>(
  query: string,
  searchWords: string[]
): string | null {
  if (query.length === 0) return null;

  const queryWords = query.split(" ");

  const bestMatches = queryWords
    .map((qw) => getBestMatch(qw, searchWords))
    .filter((m) => m !== null);

  if (bestMatches.length === 0) {
    return null;
  }

  return bestMatches.join(" ");
}

function getBestMatch(query: string, searchWords: string[]): string | null {
  let bestDist = 1;
  let bestWord = "";

  for (let i = 0; i < searchWords.length; i++) {
    const word = searchWords[i];
    const dist = string_distance(query, word);

    if (dist === 0) {
      bestWord = word;
      break;
    }

    if (bestDist === 1) {
      bestWord = word;
      bestDist = dist;
      continue;
    }

    if (dist < bestDist) {
      bestDist = dist;
      bestWord = word;
      continue;
    }
  }

  if (bestDist === 1) {
    return null;
  }

  return bestWord;
}

function getSearchWords<T extends { [key: string]: any }>(
  data: T[],
  keys?: keyof T | (keyof T)[]
): string[] | null {
  if (!keys) return null;
  if (!data) return null;

  if (Array.isArray(keys)) {
    return data.reduce((words: string[], e) => {
      const values = keys.map((k) => e[k].toString().toLowerCase());
      return [...words, ...values];
    }, []);
  } else {
    return data.reduce(
      (words: string[], e) => [...words, e[keys].toString().toLowerCase()],
      []
    );
  }
}
