import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app";
import { BrowserRouter as Router } from "react-router-dom";
import { AUTH_TOKEN_KEY } from "./constants";
import axios from "axios";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

console.info("v0.06.05.2024");
reportTokenSize();

function reportTokenSize() {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  if (!token) return;

  const b: number = token.length * 2;
  const kb = b / 1024;
  console.info(`Token size: ${kb.toFixed(2)}KB`);
  if (kb > 6) {
    console.error(`Token too large, removing (${kb.toFixed(2)}kb)`);
    localStorage.removeItem(AUTH_TOKEN_KEY);
    delete axios.defaults.headers.common["authorization"];
  }
}
